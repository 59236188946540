import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Box } from "../../components/Layout"
import colors from '../../utils/colors';
import Form from 'devextreme-react/form';
import { Button } from 'devextreme-react';
import Link, { navigate } from 'gatsby-link';

class RecoverPassword extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {loadPanelVisible: false, resetSuccess: false};

    const qs = require('query-string');
    const parsed = qs.parse(window.location.search);      

    this.oobCode = parsed.oobCode;
    
    this.setForm = (ref) => {
        if (ref != null)
            this.passwordForm = ref.instance;
    };
    this.formItems = [
        {                    
          dataField: 'password',
          validationRules:[{type:"required", message:"It is required"}],
          editorOptions: {
            mode: "password"
  
          },
          label: {text: 'Password'},
        },
        {                    
          dataField: 'passwordReenter',
          validationRules:[{type:"required", message:"It is required"}, {type:"compare", comparisonTarget: () => {return this.passwordForm.option('formData').password;}, message: "Password and Confirm Password do not match"}],
          editorOptions: {
            mode: "password"
  
          },
          label: {text: 'Confirm Password'},
        }
      ];
    this.onResetClick = this.onResetClick.bind(this);
  }

  componentDidMount() {
    if (!this.oobCode) {
        navigate('/');
    }
   
    this.passwordForm.focus();
  }
  onResetClick() {
    if (!this.passwordForm.validate().isValid)
      return;

    this.setState({loadPanelVisible: true});      

    const newPassword = this.passwordForm.option('formData').password;

    let postApiUrl = `${process.env.GATSBY_USER_API_URL}/recoverPassword?oobCode=${this.oobCode}&newPassword=${encodeURI(newPassword)}`;    
    var request = new XMLHttpRequest();
    request.callObj = this;
    request.open('GET', postApiUrl,true);
    request.setRequestHeader('Content-Type','application/json; charset=utf-8');
    request.onreadystatechange = function (reposta) {
        
        if (this.readyState === 4) {
            this.callObj.setState({loadPanelVisible:false});
            if (this.responseText) {
                var ret = JSON.parse(this.responseText);
                if (ret.message) {
                    this.callObj.setState({errorMsg:ret.message});
                    return;
                }
            }
            this.callObj.setState({resetSuccess:true});
            
        }
    };
    request.send();
  }

  render() {
      return (
        <>
        <Box bg={colors.primary}>
        <Box
        width={[1, 1, 1 / 2]}
        m={['3.5rem 0 0 0', '3.5rem 0 0 0', '3.5rem auto 3rem auto']}
        px={[3, 3, 0]}
        color={colors.secondary}
        >
        {this.state.resetSuccess?
        <>
            <h2>Your password is reset successfully!</h2>
            <p>Please <Link style={{color: 'blue', textDecoration: 'underline'}} to={'/app/login'}>login</Link> again by your new password.</p>
        </>
        :<>
            <h2>Plesae enter a new password to reset</h2>
            <Box width={[1,'400px','400px']}>
            <Form
                colCount={1}
                items={this.formItems}
                ref={this.setForm}>
            </Form>  
            </Box>
            <Box m={'30px 0 30px 0'}>
            <Button
            width={100}
            height={50}
            text="Reset"
            type="default"
            stylingMode="contained"
            onClick={this.onResetClick}
            />
            </Box>
            <p>{this.state.errorMsg}</p>
        </>}
        <LoadPanel visible={this.state.loadPanelVisible}/>
        </Box>
        </Box>
        </>
      );

  };
};

export default RecoverPassword;