import React from 'react';
import DataGrid, { Column, ColumnChooser, ColumnFixing, Pager, Paging } from 'devextreme-react/data-grid';
import { getFirebaseIdToken } from "../../../../utils/auth";
import { Box, Flex } from '../../../Layout';
import { 
  fleetDataSource
  } from '../commonDataSource'
import FleetParticularForm from './FleetParticularForm';

const vesselParticularLabel = 'Vessel Particular';

class FleetGrid extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {selectedRowsData: null, visibleDetail: null};
      this.setGrid = (ref) => {
        if (ref != null) {
            this.resultGrid = ref.instance;
            this.resultGrid.ParentElt = this;
        }
      };

      //make sure the grid do not get any data when the page is open
      //shipPortHistoryDataSource.filter(['vessel','=', 'xxx']);
      //fleetSalesDataSource.filter(['vesselName','=','1']);
      //fleetFixturesDataSource.filter(['vesselName','=',1]);

      this.getDetail = this.getDetail.bind(this);
      this.onSelectionChanged = this.onSelectionChanged.bind(this);
      this.showParticulars = this.showParticulars.bind(this);
      this.backFromSubScreen = this.backFromSubScreen.bind(this);
    }

    backFromSubScreen() {
      this.props.enquiryBase.setCriteriaVisible(true); 
      this.resultGrid.clearSelection();
      this.setState({visibleDetail: null});
    }

    resetResult() {
      getFirebaseIdToken(this.props.firebase,() => {
        fleetDataSource.filter('vesselId','=', 0);
        this.resultGrid.refresh();

      });
    }
  
    load(filter) {      
      if (!filter)
        filter = [];
      
      const shipStatusFilter = [['shipStatus','=','A'],'or',['shipStatus','=','NS']];

      const shipStatusIndex = filter.findIndex((criteria) => {return criteria[0] === 'shipStatus'});
      if (shipStatusIndex >= 0) {
        //checkbox is checked, add the condition
        shipStatusFilter.push('or')
        shipStatusFilter.push(['shipStatus','=','O'])
        if (filter.length === 1)
          filter = [];
        else if (shipStatusIndex === 0) 
          filter.splice(0,2);
        else
          filter.splice(shipStatusIndex - 1, 2);
      //} else {
      //  if (filter.length > 0)
      //    filter.push('and')
      //  filter.push(['shipStatus','<>','O'])
      }

      const deletionStatusIndex = filter.findIndex((criteria) => {return criteria[0] === 'deletionStatus'});
      if (deletionStatusIndex >= 0) {
        //checkbox is checked, set the condition
        shipStatusFilter.push('or')
        shipStatusFilter.push(['shipStatus','=','X'])
        if (filter.length === 1)
          filter = [];
        else if (deletionStatusIndex === 0) 
          filter.splice(0,2);
        else
          filter.splice(deletionStatusIndex - 1, 2);
        //  filter[deletionStatusIndex] = ['shipStatus','=', 'X'];
      // } else {
      //  if (filter.length > 0)
      //    filter.push('and')
      //  filter.push(['deletionStatus','<>','Y'])
      } 

      if (filter.length > 0){
        filter.push('and');
        filter.push(shipStatusFilter);  
      } else {
        filter.push(shipStatusFilter);  
      }

      getFirebaseIdToken(this.props.firebase,() => {
        fleetDataSource.filter(filter);
        this.resultGrid.refresh();
      });
      this.resultGrid.clearSelection();
    }

    vesselNameTemplate(props) {  
      const link = <a style={{color:'blue'}} href={`javascript: void(0)`} onClick={(e) => {props.component.ParentElt.showParticulars(props.data.imoNo, props.data.lsCode)}}>{props.data.vesselName}</a>  
      return link  
    }   

    showParticulars(imoNo, lsCode) {
      this.initialFilter = ['imoNo','=',imoNo];      
      if(lsCode){
        this.svcFilter = ['lsCode','=',lsCode];
      } else {
        this.svcFilter = ['lsCode','=','0'];
      };
      this.props.enquiryBase.setCriteriaVisible(false);
      this.setState({visibleDetail: vesselParticularLabel});      
    }
    
    onSelectionChanged(e) {
    }

    getDetail(){
      if (this.state.visibleDetail === vesselParticularLabel)
        return (
          <Box m={[20]}>
            <FleetParticularForm 
              firebase={this.props.firebase}
              initialFilter={this.initialFilter}
              svcFilter={this.svcFilter}
              returnCallback={this.backFromSubScreen}
              /> 
          </Box>                            
        );
      else
        return <></>;
    }

    render() {
      return (
        <React.Fragment>
        <Flex>
        <Box>
        <DataGrid
          elementAttr={{
            class: "myClass"
          }}
          dataSource={fleetDataSource}
          showBorders={true}
          selection={{mode: 'single'}}
          ref={this.setGrid}
          columnWidth={200}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={false}
          wordWrapEnabled={false}
          rowAlternationEnabled={false}
          onSelectionChanged={this.onSelectionChanged}
          visible={!this.state.visibleDetail}

            >
          <Paging defaultPageSize={10} />
          <Pager
            showInfo={true} />        
          <ColumnChooser enabled={true} mode='select' allowSearch/>
          <ColumnFixing enabled={true} />
          <Column dataField='vesselName'
            dataType={'string'}
            fixed={true}
            cellRender={this.vesselNameTemplate}
            minWidth={220}
          />

          <Column dataField='typeOfShip'
            caption='Type'
            dataType={'string'}
            width={70}
          />

          <Column dataField='teu'
            dataType={'number'}
            caption={'TEU'}
            format={{type:'fixedPoint',precision:0}}
            defaultSortOrder={'desc'}
            width={80}
          />

          <Column dataField='dwt'
            dataType={'number'}
            caption={'DWT'}
            format={{type:'fixedPoint',precision:0}}
            width={90}
          />

          <Column dataField='deliveryDate'
            caption='Built'
            dataType={'date'}
            format={'year'}
            width={70}
          />

          <Column dataField='shipyardName'
            caption='Shipyard'
            dataType={'string'}
            minWidth={200}
          />

          <Column dataField='operator'
            dataType={'string'}
            minWidth={200}
          />

          <Column dataField='route'
            dataType={'string'}
            width={100}
          />

          <Column dataField='svcDesc'
            caption={'Service Name'}
            dataType={'string'}
            minWidth={100}
          />

          <Column dataField='shipStatus'
            dataType={'string'}
            caption={'Status'}
            width={80}
          />
          
          <Column dataField='flag'
            dataType={'string'}
            width={90}
            visible={false}
          />

          <Column dataField='loa'
            dataType={'number'}
            width={80}
            visible={false}
            caption={'LOA'}
          />

          <Column dataField='beam'
            dataType={'number'}
            width={80}
            visible={false}
          />

          <Column dataField='teu14'
            dataType={'number'}
            width={80}
            visible={false}
            caption={'TEU @14T'}
          />

          <Column 
            dataField='grossTonnage'
            dataType={'number'}
            caption='GT'
            format={{ type: 'fixedPoint', precision: 0}}
            width={80}
            visible={false}
          />

          <Column dataField='gears'
            dataType={'string'}
            width={80}
            visible={false}
            caption={'Gear'}
          />

          <Column dataField='dScrubber'
            dataType={'string'}
            width={70}
            visible={false}
            caption={'Scrubbler'}
          />

          <Column dataField='iceClass'
            dataType={'string'}
            width={80}
            visible={false}
          />

          <Column dataField='registerredOwner'
            dataType={'string'}
            width={120}
            visible={false}
            caption={'Owner'}
          />
  
          <Column dataField='vesselId'
            dataType={'string'}
            width={0}
            visible={false}
            caption={'Id'}
          />
        </DataGrid>
        </Box>
        </Flex>
  
          {this.getDetail()}

        </React.Fragment>
      );
    }
  }

  export default FleetGrid;