import {
  DDown_Charterer } from '../commonDataSource';
import {processCriteriaFormItems} from '../helper';

const yesNoBoth = [
    { 'Name': 'Yes', 'ID': 'Y'},
    { 'Name': 'No', 'ID': 'N'}
  ];

export function getFormItems(mode) 
{
    //  dataType: for FilterBuilder only
    //  editorTemplate: for FilterBuilder only when custom editor is used
    // caption: for FilterBuilder only
    // defaultCriteria: for form criteria only if default criteria will be set
    // searchOperator: for form criteria only if custom operator is used
    
    return processCriteriaFormItems([

      {                    
        dataField: 'charterer',
        editorType: 'dxAutocomplete',
        editorOptions: {
            dataSource: DDown_Charterer(),
            displayValue: 'charterer',
            valueExpr:'charterer',
            searchEnabled:'true',
            searchMode:'contains',
            searchTimeout:'200',
            minSearchLength:3,
            showDataBeforeSearch:'true',
            showClearButton:'true',
            placeholder:'Type to charterer...'
        },
        visible: mode !== 'simple',
        colSpan: 2,
        label: {text: 'Charterer'}
      },

      {
        dataField:'dateReported',
        editorType: 'dxDateBox',
        dataType:'Date',
        editorOptions: {
            pickerType: 'rollers'
        },
        label: {text: 'Reported Date'},
        searchOperator: 'between',
        visible: mode !== 'simple',
        colSpan: 1
      },    
    
      {
        dataField:'estOpenDateEarliest',
        editorType: 'dxDateBox',
        dataType:'Date',
        editorOptions: {
            pickerType: 'rollers'
        },
        label: {text: 'Est. Open'},
        searchOperator: 'between',
        visible: mode !== 'simple',
        colSpan: 1
      },

      {
        dataField:'teu',
        editorType: 'dxRangeSlider',
        dataType:'number',
        editorOptions: {
            min: 0,
            max: 25000,
            tooltip:{
            enabled: true,
            showMode: 'onHover',
            position: 'top'
            }
        },
        defaultCriteria: [0,25000],
        visible: mode !== 'simple',
        label:{
          text: 'TEU'
        },            
        colSpan: 1
      },
      {
        dataField:'dwt',
        editorType: 'dxRangeSlider',
        dataType:'number',
        editorOptions: {
            min: 0,
            max: 300000,
            tooltip:{
            enabled: true,
            showMode: 'onHover',
            position: 'top'
            }
        },
        label:{
          text: 'DWT'
        },            
        defaultCriteria: [0,300000],
        colSpan: 1,
        visible: mode !== 'simple'
      },
   
        {
          dataField:'yearBuilt',
          editorType: 'dxRangeSlider',
          dataType:'number',
          editorOptions: {
              min: 1936,
              max: 2025,
              tooltip:{
              enabled: true,
              showMode: 'onHover',
              position: 'top'
              }
          },
          label:{
            text: 'Year Built'
          },            
          defaultCriteria: [1936,2025],
          visible: mode !== 'simple',
          colSpan: 1
        },
        
        {                    
          dataField: 'sCellular',
          editorType: 'dxSelectBox',
          editorOptions: {
            dataSource: yesNoBoth,
            displayExpr: 'Name',
            valueExpr:'ID'
          },
          label: {text: 'Select cellular'},
          colSpan: 1,
          visible: mode !== 'simple'
      },

        {                    
            dataField: 'sGear',
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: yesNoBoth,
              displayExpr: 'Name',
              valueExpr:'ID'
            },
            label: {text: 'Gear'},
            colSpan: 1,
            visible: mode !== 'simple'
        }
    
    ], mode);
}