import React from 'react';
import PowerBIComponent from './powerBIComponent';
import PostList from '../../CMS/postList';
import { Box } from '../../Layout';
import Discussion from '../../CMS/discussion';
import { TabPanel, Item } from "devextreme-react/tab-panel";

class PowerBIWithCmtComponent extends React.Component {
    constructor(props) {
        super(props);
 
        this.getPostList = this.getPostList.bind(this);
        this.getDiscussion = this.getDiscussion.bind(this);

    }    
    getPostList() {
        return <Box m={20}><PostList firebase={this.props.firebase} type={'comment'} parentId={this.props.commentaryId}  limit={3}/></Box>;
    }
    getDiscussion() {
        return <Box m={20}><Discussion firebase={this.props.firebase} parentId={this.props.commentaryId}/></Box>;
    }
    render() {
        return (
        <div>
            <Box height={['calc(100vw * ' + this.props.hieghtToWidthRatio + ')', 'calc(100vw * ' + this.props.hieghtToWidthRatio + ')', 'calc(100vw * ' + this.props.hieghtToWidthRatio + ' * ' + this.props.colSpan + ' / ' + this.props.noOfColumn.toString() + ')']}>
                <PowerBIComponent {...this.props}>{this.props.children}></PowerBIComponent>
            </Box>
            <TabPanel
            loop={false}
            animationEnabled={true}
            swipeEnabled={true}>
                <Item title={"Commentary"} render={this.getPostList} />
                <Item title={"Discussion"} render={this.getDiscussion} />


            </TabPanel>  
        </div>
        
        );
    }
}
    
export default PowerBIWithCmtComponent;