import {
  DDown_Operator,
  DDown_Ship, 
  DDown_Flag, 
  DDown_Shipyard,
  DDown_FuelType
} from '../commonDataSource';
import {processCriteriaFormItems} from '../helper';


const yesNoBoth = [
    { 'Name': 'Yes', 'ID': 'Y'},
    { 'Name': 'No', 'ID': 'N'}
  ];

  
export function getFormItems(mode) 
{
    //  dataType: for FilterBuilder only
    //  editorTemplate: for FilterBuilder only when custom editor is used
    // caption: for FilterBuilder only
    // defaultCriteria: for form criteria only if default criteria will be set
    // searchOperator: for form criteria only if custom operator is used
    
    return processCriteriaFormItems([

      {                    
        dataField: 'vesselSearch',
        editorType: 'dxAutocomplete',
        editorOptions: {
            dataSource: DDown_Ship(),
            displayValue: 'vesselSearch',
            valueExpr:'vesselSearch',
            searchEnabled:'true',
            searchMode:'contains',
            searchExpr:['vesselSearch','nameVariant'],
            searchTimeout:'200',
            minSearchLength:3,
            showDataBeforeSearch:'true',
            showClearButton:'true',
            placeholder:'Type to search by current & former name...'
        },
        visible: mode !== 'simple',
        colSpan: 2,
        label: {text: 'Name'}
      },
 
          {                    
            dataField: 'imoNo',
            label: {text: 'IMO'},
            editorOptions: {
              placeholder: 'contains ..'
            },
            visible: true
          },

          {
            dataField: 'shipStatus',            
            editorType: 'dxCheckBox', 
            label: {text: 'Include orderbook'},
            defaultCriteria: 'Y',
            visible: true
          },

          {
            dataField: 'deletionStatus',
            editorType: 'dxCheckBox', 
            label: {text: 'Include deleted ships'},
            defaultCriteria: 'Y',
            visible: true
          },

          {                    
            dataField: 'sCellular',
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: yesNoBoth,
              displayExpr: 'Name',
              valueExpr:'ID'
            },
            label: {text: 'Select cellular'},
            colSpan: 1,
            visible: mode !== 'simple',
        },
    
          {                    
            dataField: 'sGear',
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: yesNoBoth,
              displayExpr: 'Name',
              valueExpr:'ID'
            },
            label: {text: 'Gear'},
            colSpan: 1,
            visible: mode !== 'simple',
        },
    
        {                    
          dataField: 'sScrubber',
          editorType: 'dxSelectBox',
          editorOptions: {
              dataSource: yesNoBoth,
              displayExpr: 'Name',
              valueExpr:'ID'
          },
          label: {text: 'Scrubber'},
          colSpan: 1,
          visible: mode !== 'simple',
      },
  
        {                    
            dataField: 'sIceClass',
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: yesNoBoth,
                displayExpr: 'Name',
                valueExpr:'ID'
            },
            label: {text: 'Select iceClass'},
            colSpan: 1,
            visible: mode !== 'simple',
          },

          {
            dataField:'teu',
            editorType: 'dxNumberBox',
            editorOptions: {
                min: 0,
                max: 25000,
                showSpinButtons:true
            },
            defaultCriteria: [0,25000],
            searchOperator: 'between',
            visible: mode !== 'simple',
            label:{
              text: 'TEU'
            },            
            colSpan: 1
          },
          {
            dataField:'reefer',
            editorType: 'dxRangeSlider',
            dataType:'Number',
            editorOptions: {
                min: 0,
                max: 3000,
                tooltip:{
                enabled: true,
                showMode: 'onHover',
                position: 'bottom'
                }
            },
            defaultCriteria: [0,3000],
            visible: mode !== 'simple',
            colSpan: 1
          },
    
          {
            dataField:'dwt',
            editorType: 'dxRangeSlider',
            dataType:'Number',
            editorOptions: {
                min: 0,
                max: 300000,
                tooltip:{
                enabled: true,
                showMode: 'onHover',
                position: 'top'
                }
            },
            label:{
              text: 'DWT'
            },            
            defaultCriteria: [0,300000],
            colSpan: 1,
            visible: mode !== 'simple',
          },
    
          {
            dataField:'grossTonnage',
            editorType: 'dxRangeSlider',
            dataType:'Number',
            editorOptions: {
                min: 0,
                max: 300000,
                tooltip:{
                enabled: true,
                showMode: 'onHover',
                position: 'top'
                }
            },
            defaultCriteria: [0,300000],
            visible: mode !== 'simple',
            label:{
              text: 'GT'
            },            
            colSpan: 1
          },
        
        {
            dataField:'loa',
            editorType: 'dxRangeSlider',
            editorOptions: {
                min: 0,
                max: 500,
                tooltip:{
                enabled: true,
                showMode: 'onHover',
                position: 'top'
                }
            },
            defaultCriteria: [0,500],
            visible: mode !== 'simple',
            label:{
              text: 'LOA'
            },            
            colSpan: 1
          },
          
          {
            dataField:'beam',
            editorType: 'dxRangeSlider',
            editorOptions: {
                min: 0,
                max: 80,
                tooltip:{
                enabled: true,
                showMode: 'onHover',
                position: 'top'
                },
            },
            defaultCriteria: [0,80],
            visible: mode !== 'simple',
            colSpan:1
          },     
 
          {
            dataField:'draft',
            editorType: 'dxRangeSlider',
            editorOptions: {
                min: 0,
                max: 40,
                tooltip:{
                enabled: true,
                showMode: 'onHover',
                position: 'top'
                },
            },
            defaultCriteria: [0,40],
            visible: mode !== 'simple',
            colSpan: 1
          },
 
          {
            dataField:'dYear',
            editorType: 'dxRangeSlider',
            dataType:'Number',
            editorOptions: {
                min: 1936,
                max: 2025,
                tooltip:{
                enabled: true,
                showMode: 'onHover',
                position: 'top'
                }
            },
            label:{
              text: 'Year Built'
            },            
            defaultCriteria: [1936,2025],
            visible: mode !== 'simple',
            colSpan: 1
          },
    
          {                    
            dataField: 'flag',
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: DDown_Flag(),
                displayExpr: 'flag',
                valueExpr:'flag',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'flag',
                searchTimeout:'200',
                minSearchLength:'0',
                showDataBeforeSearch:'true'  
            },
            visible: mode !== 'simple',
            label: {text: 'Select Flag'}
          },
          {                    
            dataField: 'fuelType',
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: DDown_FuelType(),
                displayExpr: 'fuelType',
                valueExpr:'fuelType',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'fuelType',
                searchTimeout:'200',
                minSearchLength:'0',
                showDataBeforeSearch:'true'  
            },
            visible: mode !== 'simple',
            label: {text: 'Select FuelType'},
            searchOperator: '=',
          },        
          {                    
            dataField: 'operator',
            dataType: 'string',
            editorTemplate: 'operator',
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: DDown_Operator(),
                displayExpr: 'operator',
                valueExpr:'operator',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'operator',
                searchTimeout:'200',
                minSearchLength:'0',
                showDataBeforeSearch:'true',
                showClearButton:'true'
            },
            label: {text: 'Select Operator'},
            colSpan: 2
        },

        {                    
            dataField: 'shipyardName',
            editorType: 'dxAutocomplete',
            editorOptions: {
                dataSource: DDown_Shipyard(),
                displayExpr: 'shipyardName',
                valueExpr:'shipyardName',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'shipyardName',
                searchTimeout:'200',
                minSearchLength:'0',
                showDataBeforeSearch:'true',
                showClearButton:'true',
                placeholder:'Type to search ..'  
            },
            visible: mode !== 'simple',
            label: {text: 'Shipyard'}
          }
    
    ], mode);
}
