import React from 'react';
import { Button } from 'devextreme-react';
import { Box, Flex } from '../../Layout';
import CriteriaFormBase from './criteriaFormBase';
import {logRouteUsage, isBrowser} from '../../../utils/auth';

class EnquiryBase extends React.Component {
    constructor(props) {
      super(props);
      this.formItemsBasic = props.formItemsBasic;

      this.onRetrieveClick = this.onRetrieveClick.bind(this);
      this.onResetClick = this.onResetClick.bind(this);
      this.getCriteria = this.getCriteria.bind(this);

      this.setBasicCriteriaForm = (ref) => {
        if (ref != null)
            this.basicCriteriaForm = ref;
      };
      this.setSearchResult = (ref) => {
        if (ref != null)
            this.searchResult = ref;
      };    
      this.state = {showCriteria: (this.formItemsBasic? true:false)};
    }

    onRetrieveClick(e) {
      const route = isBrowser()?`${encodeURI(window.location.pathname)}/[search]`.replace('//','/'): null;
      logRouteUsage(route);
      let filter;

      filter = this.basicCriteriaForm.getCriteriaFilter();
      if (filter) {
        // if input is null: reset the search result
        this.searchResult.load(filter.length===0?null: filter);
      }
    }

    getCriteria() {
      return (
      <div style={{border: 'solid', borderWidth: 1, borderColor: '#c5d2d9', marginBottom: 10}}>
      <Flex justifyContent="left" wrap={['wrap', 'nowrap','nowrap']} >
      <Box m={[10, 10, 10]} width={'100%'}>
        <CriteriaFormBase formItems={this.formItemsBasic} ref={this.setBasicCriteriaForm}/>
      </Box>
      <Box m={[10, 10, 10]} width={[1,'120px','120px']}>
        <Flex justifyContent="left" wrap={['nowrap', 'wrap','wrap']} >
        <Box>
        <Button
          width={100}
          text="Search"
          icon="search"
          onClick={this.onRetrieveClick}
          id={`Retrieve`}
        />
        </Box>
        <Box m={['0 0 0 10px', '10px 0 0 0', '10px 0 0 0']}>
        <Button
          width={100}
          text="Reset"
          icon="close"
          stylingMode="contained"
          onClick={this.onResetClick}
          id={`Reset`}
        />   
        </Box>         
        </Flex>

      </Box>
      </Flex>      
      </div>
       );
    }

    onResetClick(e) {
      this.basicCriteriaForm.resetCriteria();
    }  

    setCriteriaVisible(visible) {
      this.setState({showCriteria: visible});
    }
    render() {
      return (
        <Box m={'0 0 15px 0'}>
          <div style={this.state.showCriteria?{}:{display: 'none'}}>
          <div className="no-printme">
              {this.getCriteria()}


          </div>
          </div>
          <Box>
            <this.props.searchResultComponent firebase={this.props.firebase} ref={this.setSearchResult} enquiryBase={this} filter={(this.state)?this.state.criteriaFilter:null}/>
          </Box>
        </Box>
      );  
    }
  }

  export default EnquiryBase;