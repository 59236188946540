export const fleetFormItems = [

    {
      dataField: 'vesselName',
      editorType: 'dxTextBox', 
      colSpan: '2'
    },
    {
      dataField: 'imoNo',
      label: {
        text: 'IMO No.'
      },
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'mmsi',
      label: {
        text: 'MMSI'
      },
      editorType: 'dxTextBox'
    },     
    {
      dataField: 'formerNames',
      label: {
        text:'ex Names'
      },
      editorType: 'dxTextBox',
      colSpan:'2'
    },     
    {
      dataField: 'typeCombined',
      label: {
        text: 'Type'
      },
      editorType: 'dxTextBox'
    },     
    {
      dataField: 'flag',
      editorType: 'dxTextBox'
    },
    {
      dataField: 'teu',
      editorType: 'dxNumberBox',
      editorOptions:{
        format: { type: 'fixedPoint', precision: 0}
      }
    },      
    {
      dataField: 'teu14',
      editorType: 'dxNumberBox',
      label: {
        text: 'TEU@14T'
      },
      editorOptions:{
        format: { type: 'fixedPoint', precision: 0}
      }
    },      
    {
      dataField: 'reefer',
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'gears',
      label: {
        text: 'Gear'
      },
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'dwt',
      editorType: 'dxNumberBox',
      editorOptions:{
        format: { type: 'fixedPoint', precision: 0}
      }
    },      
    {
      dataField: 'grossTonnage',
      label: {
        text: 'GT'
      },
      editorType: 'dxNumberBox',
      editorOptions:{
        format: { type: 'fixedPoint', precision: 0}
      }
    },      
    {
      dataField: 'netTonnage',
      label: {
        text: 'NT'
      },
      editorType: 'dxNumberBox',
      editorOptions:{
        format: { type: 'fixedPoint', precision: 0}
      }
    },      
    {
      dataField: 'iceClass',
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'loa',
      editorType: 'dxNumberBox'    
    },      
    {
      dataField: 'beam',
      editorType: 'dxNumberBox'
    },      
    {
      dataField: 'draft',
      editorType: 'dxNumberBox',
      editorOptions:{
        format: { type: 'fixedPoint', precision: 0}
      }
    },      
    {
      dataField: 'speed',
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'shipyardName',
      editorType: 'dxTextBox',
      label: {
        text: 'Shipyard'
      },
    },      
    {
      dataField: 'hullNo',
      label: {
        text: 'Hull No.'
      },
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'launchName',
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'price',
      editorType: 'dxNumberBox',
      editorOptions:{
        format: { type: 'fixedPoint', precision: 0}
      }
    },      
    {
      dataField: 'orderDate',
      label: {
        text: 'Ordered'
      },
      editorType: 'dxDateBox',
      editorOptions: {
        type:'date', displayFormat: 'yyyy-MM-dd'       
      }
    },      
    {
      dataField: 'keelLaid',
      editorType: 'dxDateBox',
      editorOptions: {
        type:'date', displayFormat: 'yyyy-MM-dd'
      }
    },      
    {
      dataField: 'launchDate',
      label: {
        text: 'Launched'
      },
      editorType: 'dxDateBox',
      editorOptions: {
        type:'date', displayFormat: 'yyyy-MM-dd'       
      }
    },      
    {
      dataField: 'deliveryDate',
      label: {
        text: 'Delivered'
      },
      editorType: 'dxDateBox',
      editorOptions: {
        type:'date', displayFormat: 'yyyy-MM-dd'       
      }
    },      
    {
      dataField: 'engineType',
      label: {
        text: 'Engine'
      },
      editorType: 'dxTextBox'    
    }, 
    {
      dataField: 'powerKw',
      label: {
        text: 'Power (kW)'
      },
      editorType: 'dxTextBox'    
    }, 
    {
      dataField: 'dScrubber',
      label: {
        text: 'Scrubber'
      },
      editorType: 'dxTextBox'    
    }, 
    {
      dataField: 'fuelType',
      label: {
        text: 'Fuel Type'
      },
      editorType: 'dxTextBox'    
    }, 
    {
      dataField: 'notes',
      label: {
        text: 'Notes'
      },
      editorType: 'dxTextBox'    ,
      colSpan: 4
    }, 
    {
      dataField: 'filler',
      label: {
        text: 'Remark'
      },
      editorType: 'dxTextArea',
      editorOptions: {
        height: 60
      },
      colSpan: 4
    }];
  
    export const operatingFormItems =[
    {
      dataField: 'registeredOwner',
      label: {
        text: 'Registered Owner'
      },
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'registeredManager',
      label: {
        text: 'Ship Manager'
      },
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'operator',
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'charterOwn',
      label: {
        text: 'Status'
      },
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'shipClass',
      label: {
        text: 'Class'
      },
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'lastClassRenewalSurveyDate',
      label: {
        text: 'Last Survey Date'
      },
      editorType: 'dxDateBox',
      editorOptions: {
        type:'date', displayFormat: 'yyyy-MM-dd'       
      }
    },      
    {
      dataField: 'nextClassRenewalSurveyDate',
      label: {
        text: 'Next Survey Date'
      },
      editorType: 'dxDateBox',
      editorOptions: {
        type:'date', displayFormat: 'yyyy-MM-dd'       
      }
    }
  ];

    
  export const svcFormItems=[     
    {
      dataField: 'serviceName',
      label: {
        text: 'Service Name'
      },
      colSpan: '2',
      editorType: 'dxTextBox'
    },      
    {
      dataField: 'trade',
      label: {
        text: 'Route'
      },
      editorType: 'dxTextBox'
    }
  ];
  
  export const deletionFormItems=[     
    {
      dataField: 'deletionReason',
      label: {
        text: 'Deletion Reason'
      },
      editorType: 'dxTextBox',
      colSpan: 2
    },      
    {
      dataField: 'scrapDate',
      editorOptions: {
        type:'date', format: 'monthAndYear'
      }
    },      
    {
      dataField: 'scrapLocation',
      label: {
        text: 'Scrap Location'
      },
      editorType: 'dxTextBox'
    },
    {
      dataField: 'deletionDate',
      editorOptions: {
        type:'date', displayFormat: 'monthAndYear'
      }
    },      
    {
      dataField: 'scrapName',
      label: {
        text: 'Name at Deletion'
      },
      editorType: 'dxTextBox'
    },
    {
      dataField: 'ldt',
      label: {
        text: 'LDT'
      },
      editorType: 'dxTextBox'
    },
    {
      dataField: 'pricePerLdt',
      label: {
        text: 'Scrap Price $/LDT'
      },
      editorType: 'dxTextBox'
    },
    {
      dataField: 'beneficialOwner',
      label: {
        text: 'Last Owner'
      },
      editorType: 'dxTextBox',
      colSpan: 2
    },      
    {
      dataField: 'registeredManager',
      label: {
        text: 'Last Manager'
      },
      editorType: 'dxTextBox'
    },
    {
      dataField: 'charterOwn',
      label: {
        text: 'Last Status'
      },
      editorType: 'dxTextBox'
    }
  ];
  
  export const ServiceFormItems = [
    {
      dataField: 'carriers',
      editorType: 'dxTextArea'
    },
    {
      dataField: 'rotations',
      editorType: 'dxTextArea'
    },
    {
      dataField: 'duration',
      editorType: 'dxTextBox'
    },
    {
      dataField: 'frequency',
      editorType: 'dxTextBox'
    }
  ];
  