import React from 'react';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { SelectBox } from 'devextreme-react';
import {
  DDown_Operator, 
  DDown_Ship,
  formerNamesDataSource} from './commonDataSource';

export const renderOperator = e => {
  return       <SelectBox
  value={e.value}
  dataSource={DDown_Operator()}
  displayExpr='operator'
  valueExpr='operatorGroup'
  searchExpr='operator'
  searchTimeout={200}
  minSearchLength={0}
  showClearButton={true}
  showDataBeforeSearch={true}
  searchMode='contains'
  searchEnabled={true}
  onValueChanged={(ee)=>{e.setValue(ee.value);}}
  width="300px"
/>;
}

export const renderVesselName = e => {
  return       <Autocomplete
  dataSource={DDown_Ship()}
  displayExpr='sVesselName'
  valueExpr='sVesselName'
  searchExpr='sVesselName'
  searchTimeout={200}
  minSearchLength={3}
  showClearButton={true}
  onValueChanged={(ee)=>{e.setValue(ee.value);}}
  width="400px"
/>;
}

export const renderFormerName = e => {
  return       <Autocomplete
  dataSource={formerNamesDataSource}
  displayExpr='formerNames'
  valueExpr='id'
  searchExpr='formerNames'
  searchTimeout={200}
  minSearchLength={3}
  showClearButton={true}
  onValueChanged={(ee)=>{e.setValue(ee.value);}}
  width="300px"
/>;
}


export function processCriteriaFormItems(formItems, mode) {
  var index = 0;
  while (index < formItems.length) {
    if (formItems[index].visible !== null && !formItems[index].visible) {
      formItems.splice(index,1);
    } else {
      if (formItems[index].searchOperator === 'between' && mode !== 'advance') {
        formItems.splice(index + 1, 0, Object.assign({}, JSON.parse(JSON.stringify(formItems[index]))));
        
        formItems[index].searchOperator = '>=';
        if (formItems[index].label)
          formItems[index].label.text += ' (from)';
        if (formItems[index].caption)
          formItems[index].caption += ' (from)';
        
        formItems[index + 1].searchOperator = '<=';
        if (formItems[index + 1].label)
          formItems[index + 1].label.text += ' (to)';
        if (formItems[index + 1].caption)
          formItems[index + 1].caption += ' (to)';

        formItems[index + 1].dataField += '_SYSTO';
      }


      if (formItems[index].caption) {
        if (!formItems[index].label) {
          formItems[index].label = {text: formItems[index].caption};
        }
      }

      index++;

    }
  }
  return formItems;
}

export function getDefaultCriteria(itemList) {
    let ret={};
    var idx = 0;
    for (const item in itemList) {

        if (itemList[item].defaultCriteria && !itemList[item].dataField.endsWith('_SYSTO')) {
          if (itemList[item].searchOperator === '>=' && itemList[idx + 1] && itemList[idx + 1].searchOperator === '<=' && Array.isArray(itemList[item].defaultCriteria)) {
            ret[itemList[item].dataField] = itemList[item].defaultCriteria[0];
            ret[itemList[idx + 1].dataField] = itemList[item].defaultCriteria[1];
          } else 
            ret[itemList[item].dataField] = itemList[item].defaultCriteria;
        }
        idx++;
    }
    return ret;
}
export function getFilterArrayFromForm(criteria, exceptionList) {
    let ret = [];
    let operator;   
    for (const property in criteria) {
        let criteriaProp = criteria[property];
        if (criteriaProp || !isNaN(criteriaProp)) {
          

          let exception = exceptionList.find(function(element) { 
            return element.dataField === property; 
          });             
          if (Array.isArray(criteriaProp)) {// assume it is a slider range 
            if (exception && exception.defaultCriteria) {
              if (exception.defaultCriteria[0] === criteriaProp[0] && exception.defaultCriteria[1] === criteriaProp[1])
                continue;
            }
            if (ret.length > 0) ret = [...ret, 'and'];
            ret = [...ret, [property, '>=', criteriaProp[0]], 'and', [property, '<=', criteriaProp[1]]];  
          } else if (criteriaProp.getMonth) { //assume it is a date
            if (exception && exception.searchOperator) {
              operator = exception.searchOperator;
            } else 
              operator = '=';
            if (ret.length > 0) ret = [...ret, 'and'];
            ret = [...ret, [property.replace('_SYSTO','').replace('_SYSFROM',''), operator, criteriaProp]];
          } else if (!isNaN(criteriaProp)) { // it is a number
            if (exception && exception.searchOperator) {
              operator = exception.searchOperator;
            } else 
              operator = '=';              
            if (ret.length > 0) ret = [...ret, 'and'];
            ret = [...ret, [property.replace('_SYSTO','').replace('_SYSFROM',''), operator, criteriaProp]];
          } else { //it is a string
            if (exception && exception.searchOperator) {
              operator = exception.searchOperator;
            } else 
              operator = 'contains';              
            
            if (ret.length > 0) ret = [...ret, 'and'];
            ret = [...ret, [property.replace('_SAME',''), operator, criteriaProp]];
          }
        }
    }
    return ret;
}