import React from 'react';
//import * as request from 'request';
import { Box } from '../../components/Layout';
import colors from '../../utils/colors';
import Link from 'gatsby-link';
import { css } from 'emotion';
import Pagination from './pagination';
import { getFirebaseIdToken } from "../../utils/auth";

const listStyle = css`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const dateStyle = css`
    font-size: 12px;
    margin-bottom: 0px;
`;

const postStyle = css`
    padding-bottom: 20px;
`;

class PostList extends React.Component {
    constructor(props) {
        super(props);
        this.setState({posts:[]});
        this.goPreviousPage = this.goPreviousPage.bind(this);
        this.goNextPage = this.goNextPage.bind(this);
        this.refreshList = this.refreshList.bind(this);
        if (props.page)
            this.currentPage = props.page;
        else
            this.currentPage = 1;
    }


    refreshList() {
        
        getFirebaseIdToken(this.props.firebase, ()=>{

            let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/browsePostByTypeByParentId?type=${this.props.type}&parentId=${this.props.parentId}&limit=${this.props.limit}&page=${this.currentPage}`;    
            //let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/browseAllPost?type=${this.props.type}&parentId=${this.props.parentId}&limit=${this.props.limit}&page=${this.currentPage}`;

            var request = new XMLHttpRequest();
            request.callObj = this;
            request.open('GET', postApiUrl,true);
            request.setRequestHeader('Content-Type','application/json; charset=utf-8');
            request.setRequestHeader('Authorization',`Bearer ${(typeof window !== 'undefined')?window.localStorage.getItem("userIdToken"):''}`);
            request.onreadystatechange = function (reposta) {
            if (this.readyState === 4) {
                //console.log(this.responseText);
                let body = JSON.parse(this.responseText);
                this.callObj.setState({posts: body.posts, pagination: body.pagination});            
            }
            };
            request.send();
        });
    }

    componentDidMount() {
        this.refreshList();
    }

    goPreviousPage() {
        if (this.currentPage > 1) {
            this.currentPage --;
            this.refreshList();
        }
    }

    goNextPage() {
        if (this.currentPage < this.state.pagination.pages) {
            this.currentPage ++;
            this.refreshList();
        }
    }


    postListCard(type, post) {
        if (type === 'comment') {
            return (
            <Link to={`/app/post/${post.slug}`}>
            <h3>{post.title}</h3>

            <p className={dateStyle}>{(new Date(post.published_at)).toLocaleString(typeof window !== 'undefined'?window.navigator.userLanguage || window.navigator.language:null,{year: 'numeric',month: 'short',day: 'numeric'})} by {post.authors[0].name}</p>
            <p className={postStyle}>{post.excerpt}</p>
            </Link>);
        } else if (type === 'discussion') {
            return (
            <>
            <p className={dateStyle}>{(new Date(post.published_at)).toLocaleString(typeof window !== 'undefined'?window.navigator.userLanguage || window.navigator.language:null,{year: 'numeric',month: 'short',day: 'numeric'})} by {post.tags[1].name.replace('#user:','')}</p>
            <section
                className="content-body load-external-scripts"
                dangerouslySetInnerHTML={{ __html: post.html }}
            />            
            </>
            );
        }
    }

    render() {
        return (

            <Box bg={colors.primary}>
                <ul className={listStyle}>
                {this.state?this.state.posts
                    .map((post, index) => {
                    return (
                        <li key={post.id}>
                            {this.postListCard(this.props.type, post)}
                        </li>
                    );
                }): null}
                </ul>
                {this.state ?
                    <Pagination 
                    currentPage={this.state.pagination.page} 
                    numberOfPages={this.state.pagination.pages}
                    previousPageCallback={this.goPreviousPage}
                    nextPageCallback={this.goNextPage}/>: null
                }
            </Box>

          );
    }
}
    
export default PostList;