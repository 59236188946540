import React from 'react';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import colors from '../../../../utils/colors';
import { Button } from 'devextreme-react';
import { getFirebaseIdToken } from "../../../../utils/auth";
import { Box, Flex } from '../../../Layout';
import PieChart, {  Series, Label, Connector, Legend, Tooltip, Size } from 'devextreme-react/pie-chart';
import { 

  svcRecordDataSource,   
  svcVesselDataSource,
  svcVesselChartDataSource,
  } from '../commonDataSource';
import FleetParticular from '../Fleets/FleetParticularForm';
import { serviceDetailItems } from './ServiceDetailItems'
import RecordFormBase from '../recordFormBase';

const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits:0
}).format;

class ServiceDetail extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {selectedRowsData: null, visibleDetail: null};
      this.setChart = (ref) => {
        if (ref != null)
            this.operatorChart = ref.instance;
      };
      this.setShipGrid= (ref) => {
        if (ref != null)
            this.shipGrid = ref.instance;
      };
      if (props.lsCode)
        this.initialFilter = ['lsCode','=',props.lsCode];   
      else
        this.initialFilter = props.initialFilter;
      //alert(this.initialFilter);

      this.onSelectionShipChanged = this.onSelectionShipChanged.bind(this);   
      this.backFromSubScreen = this.backFromSubScreen.bind(this);
      this.customizeTooltip = this.customizeTooltip.bind(this);

      svcRecordDataSource.filter(this.initialFilter);

      svcVesselDataSource.filter(this.initialFilter);
      svcVesselChartDataSource.filter(this.initialFilter);
    }
    
    componentDidMount() {
      svcVesselChartDataSource.load();
      this.operatorChart.refresh();

      /*
      svcRecordDataSource.filter(this.initialFilter);

      svcVesselDataSource.filter(this.initialFilter);
      this.shipGrid.refresh();

      svcVesselChartDataSource.filter(this.initialFilter);
      svcVesselChartDataSource.load();
      this.operatorChart.refresh(); */
    }

    onSelectionShipChanged(e) {
      var data = e.selectedRowsData[0];
      if (data && this.props.showFleet) {
          this.initialFilter = ['vesselId','=',data.vesselId];
          if (this.props.enquiryBase)
            this.props.enquiryBase.setCriteriaVisible(false);
          this.setState({visibleDetail: true});
      }
      else {
      }

    }

    backFromSubScreen() {
      this.shipGrid.clearSelection();
      if (this.props.enquiryBase)
        this.props.enquiryBase.setCriteriaVisible(true); 
      this.setState({visibleDetail: null})
    }

    resetResult() {
      getFirebaseIdToken(this.props.firebase,() => {
        svcRecordDataSource.filter('lsCode','=', 0);
        svcVesselChartDataSource.filter('lsCode','=', 0);
        svcVesselDataSource.filter('lsCode','=', 0);
      });
    }
  
    customizeTooltip(pointInfo){
    
      if(pointInfo){
          return{
              text: `${pointInfo.argumentText}: ${formatNumber(pointInfo.valueText)} TEU` 
          };
      } else {
          return{
              text: ``
          };
      }   
    }

    render() {
      return (
        <React.Fragment>
          <div style={{display: (!this.state.visibleDetail)?'inline':'none'}} >
          
          <Flex wrap={['nowrap']}>
            {this.props.returnCallback &&<Button style={{marginRight: 10}} icon="back" hint="Back" onClick={this.props.returnCallback} height='30'/>}
            <h3 style={{marginTop: 5}}>Service Particulars</h3>
          </Flex>          
          <Box>
            <RecordFormBase
              recordFormItems={serviceDetailItems}
              recordFormDataSource={svcRecordDataSource}
              colCount={5}
              initialFilter={this.initialFilter}
              firebase={this.props.firebase}/>            
          </Box>
          </div>
          <div style={{display: (!this.state.visibleDetail)?'inline':'none'}} >
          <Flex wrap={['wrap','nowrap','nowrap']}>
          <Box width={['100%','70%','70%']}>
            <h3>Ships Deployed</h3>
            <DataGrid
              elementAttr={{
                class: "myClass"
              }}
              dataSource={svcVesselDataSource}
              showBorders={true}
              selection={{mode: 'single'}}
              onSelectionChanged={this.onSelectionShipChanged}
              ref={this.setShipGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={false}
              wordWrapEnabled={false}
                >
              <Paging defaultPageSize={15} />
              <Pager
                showInfo={true} />     

              <Column dataField='vesselName'
                dataType={'string'}
                width={'10%'}
              />

              <Column dataField='operator'
                caption='Operator'
                dataType={'string'}
                width={'10%'}
              />

              <Column dataField='teu'
                dataType={'number'}
                caption={'TEU'}
                format={{type:'fixedPoint',precision:0}}
                width={'10%'}
              />

              <Column dataField='lastPort'
                dataType={'string'}
                caption={'Last Port'}
                width={'10%'}
              />

              <Column dataField='lastHeadHaulDate'
                dataType={'Date'}
                caption={'ATD'}
                width={'10%'}
              />

              <Column dataField='nextPort'
                dataType={'string'}
                caption={'Next Port'}
                width={'10%'}
              />

              <Column dataField='nextHeadHaulDate'
                dataType={'Date'}
                caption={'ETA'}
                width={'10%'}
              />
              <Column dataField='aisRotation'
                dataType={'string'}
                caption={'AIS History'}
                width={'30%'}
              />              

              <Column dataField='vesselId'
                visible={false}
              />
            </DataGrid>
          </Box>

          <Box width={['100%','30%','30%']}>
            <h3>Operating Capacity by Operators</h3>
            <Box width={[1,1,1]}>
            <PieChart 
              ref={this.setChart}
              type='doughnut'
              dataSource={svcVesselChartDataSource}
              visible={!this.state.visibleDetail}  
              >  
                <Size
                    height={300}
                />                          
              <Series
                name='TEU'
                valueField='totalTeu'
                argumentField='operator'                                                
              >
                <Label 
                visible={true}
                  format={{type:'fixedPoint',precision:0}} >
                  <Connector visible={true} width={1} />

                  </Label>
              </Series>
              <Legend
                verticalAlignment={'bottom'}
                horizontalAlignment={'center'} />
              <Tooltip enabled={true} location="edge" customizeTooltip={this.customizeTooltip} />
            </PieChart>
            </Box>
          </Box>        
          </Flex>
          </div>

          {this.state.visibleDetail && this.props.showFleet &&
            <FleetParticular
              firebase={this.props.firebase}
              initialFilter={this.initialFilter}
              returnCallback={this.backFromSubScreen}
              />
          }

        </React.Fragment>
      );
    }
  }


class  ServiceDetailQuery extends React.Component {
  render() {
    if (this.props.lsCode) 
    return (
      <Box bg={colors.primary}>
        
        <Box
            width={[1, 1, 1]}
            m={['2.7rem 0 0 0', '2.7rem 0 0 0', '2.7rem auto 0 auto']}
            px={[0, 0, 0]}
            color={colors.secondary}
            minHeight={'500px'}
        >

            <Box m={'20px 40px 20px 40px'}>
              <ServiceDetail {...this.props} />
            </Box>       

        </Box>
      </Box>
    );
    else
      return (
        <ServiceDetail {...this.props} />
    );
  }  
}
export default ServiceDetailQuery;