import React from "react"
import RecoverPassword from "./RecoverPassword";
import EmailVerify from "./EmailVerify";


class ActionHandler extends React.Component {
  constructor(props) {
    super(props);
    const qs = require('query-string');
    const parsed = qs.parse(window.location.search);      

    this.state = {mode: parsed.mode};
  }

  render() {
    return (
        <>
            {this.state.mode === 'resetPassword'?<RecoverPassword />:
            this.state.mode === 'verifyEmail'?<EmailVerify />:null}
        </>
      );
    }
  
}


export default ActionHandler
