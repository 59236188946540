import React from 'react';
import Pagination from './pagination';
import PostCard from './postCard';

class PostFeedList extends React.Component {

    render() {
        return (
            <div>
                
                    {this.props.posts.map((post) => (
                        <React.Fragment key={post.slug}>
                            <PostCard hideImage={this.props.hideImage} post={post} showPrimaryTag={true} />
                        </React.Fragment>
                    ))}

                <Pagination 
                    currentPage={this.props.pageContext.humanPageNumber} 
                    numberOfPages={this.props.pageContext.numberOfPages}
                    previousPagePath={this.props.pageContext.previousPagePath}
                    nextPagePath={this.props.pageContext.nextPagePath}/>
            </div>

          );
    }
}
    
export default PostFeedList;