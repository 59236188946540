import React from 'react';
import { getFirebaseIdToken, isBrowser } from "../../utils/auth";
import {getUser} from '../../utils/auth';

var CommentsBlock = isBrowser() ? require("simple-react-comments").default : null;

class Discussion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          comments: [],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loadData();
    }
    loadData() {
      getFirebaseIdToken(this.props.firebase, ()=>{

        let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/browsePostByTypeByParentId?type=discussion&parentId=${this.props.parentId}`;    
        var request = new XMLHttpRequest();
        request.callObj = this;
        request.open('GET', postApiUrl,true);
        request.setRequestHeader('Content-Type','application/json; charset=utf-8');
        request.setRequestHeader('Authorization',`Bearer ${(typeof window !== 'undefined')?window.localStorage.getItem("userIdToken"):''}`);
        request.onreadystatechange = function (reposta) {
          if (this.readyState === 4) {
              //console.log(this.responseText);
              let body = JSON.parse(this.responseText);
              let comments = [];
              for (let i=0; i < body.posts.length;i++) {
                comments = 
                [
                  
                  {
                    authorUrl: '#',
                    avatarUrl: '#avatarUrl',
                    createdAt: new Date(body.posts[i].published_at),
                    fullName: body.posts[i].tags[1].name.replace('#user:',''),
                    text: body.posts[i].html.replace('<p>','').replace('</p>',''),
                  },
                  ...comments,
                ];
              }
              this.callObj.setState({comments: comments,});
          }
        };
        request.send();
      });      
    }
    handleSubmit(text) {
      if (!text || text.length === 0)
        return;
      getFirebaseIdToken(this.props.firebase, ()=>{
        if(typeof XMLHttpRequest !== "undefined") {
          var request = new XMLHttpRequest();
          request.thisObj = this;
          let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/addDiscussionPost`;    
          request.open('POST', postApiUrl,true);
          request.setRequestHeader('Content-Type','application/json; charset=utf-8');
          request.setRequestHeader('Authorization',`Bearer ${(typeof window !== 'undefined')?window.localStorage.getItem("userIdToken"):''}`);
          request.onreadystatechange = function (reposta) {
            if (this.readyState === 4) {
              this.thisObj.loadData();
            }
          };
          let userId = getUser().email;
          request.send(JSON.stringify({
            title: `DISCUSSION of ${this.props.parentId} by ${userId}`,
            html: text,
            user: userId,
            parentId: this.props.parentId
          }));     
        }   
      });
    }
     
    render() {
        return (
          <div>
            <CommentsBlock
              comments={this.state.comments}
              isLoggedIn={true}
              reactRouter={false}
              onSubmit={this.handleSubmit}
            />
          </div>
        );
    }
}
    
export default Discussion;