import {dDown_CapacityBatch} from '../commonDataSource';

export function getFormItems(mode) 
{
    //  dataType: for FilterBuilder only
    //  editorTemplate: for FilterBuilder only when custom editor is used
    // caption: for FilterBuilder only
    // defaultCriteria: for form criteria only if default criteria will be set
    // searchOperator: for form criteria only if custom operator is used
    return [
        
        {                    
            dataField: 'batchLabel',
            dataType: 'string',
            editorType: 'dxSelectBox',
            validationRules:[{type:"required", message:"It is required"}],
            editorOptions: {
                dataSource: dDown_CapacityBatch(),
                displayExpr: 'batchLabel',
                valueExpr:'batchLabel',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'batchLabel',
                searchTimeout:'200',
                minSearchLength:'0',
                showDataBeforeSearch:'true'
            },
            label: {text: 'Select Date'},
            colSpan: 2,
            searchOperator: '='
        }
    ];
}