import React from 'react';
import PostFeedList from './postFeedList';
import { Box } from '../../components/Layout';
import colors from '../../utils/colors';
import SubNav from '../../components/Navigation/subNav';
import { LoadPanel } from 'devextreme-react/load-panel';

class SearchPost extends React.Component {
    constructor(props) {
        super(props);
        
        if (props.page)
            this.currentPage = props.page;
        else
            this.currentPage = 1;
        this.state = {posts:null, pageContext:{humanPageNumber:this.currentPage, numberOfPages:0}, showLoading: true};
    }

    refreshList() {
        let callObj = this;
        let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/browsePostByKeywords?keywords=${this.props.keywords}&limit=10&page=${this.props.page}`;

        var request = new XMLHttpRequest();
        request.callObj = callObj;
        request.open('GET', postApiUrl,true);
        request.setRequestHeader('Content-Type','application/json; charset=utf-8');
        request.onreadystatechange = function (reposta) {
        if (this.readyState === 4) {
            //console.log(this.responseText);
            let body = JSON.parse(this.responseText);
            body.posts.forEach((post, index ) => {
                if (!post.feature_image) {
                    var postCatTag = post.tags.find(tag=> tag.feature_image);
                    if (postCatTag)
                        post.feature_image = postCatTag.feature_image;
                }
            });     
            this.callObj.refreshBy = 'refresh';
            this.callObj.setState({posts: body.posts, pageContext: 
                {
                    humanPageNumber: parseInt(body.pagination.page), 
                    numberOfPages: parseInt(body.pagination.pages),
                    previousPagePath: body.pagination.page > 1?`/app/searchPost/${encodeURI(this.callObj.props.keywords)}/${parseInt(body.pagination.page) - 1}`: null,
                    nextPagePath: body.pagination.page < body.pagination.pages?`/app/searchPost/${encodeURI(this.callObj.props.keywords)}/${parseInt(body.pagination.page) + 1}`: null,
                    total: body.pagination.total,
                }, showLoading: false});            
        }
        };
        request.send();

    }

    componentDidMount() {
        this.refreshList();
    }
    
    componentDidUpdate() {
        if (this.refreshBy !== 'refresh') {
            this.refreshBy = null;            
            this.refreshList();
        }
        this.refreshBy = null;
     }


    render() {
        return (
            <div>
            
                <Box
                    width={[1, 1, 2 / 3]}
                    minWidth={[0,0,'1024px']}
                    m={['4rem 0 1.5rem 0', '4rem 0 1.5rem 0', '5rem auto 1.5rem auto']}>    
                    <SubNav/>
                    <Box
                    mt={['1.5rem', '1.5rem', '1.5rem']} mb={['1rem', '1rem', '1rem']}
                    color={colors.secondary}
                    >
                        <h2>Search Result</h2>
                        <p>Search for: {this.props.keywords}<br/>
                        No. of Posts Found: {this.state.pageContext.total}</p>
                    {this.state.posts?
                    <PostFeedList hideImage={true} key={`searchPost_${this.state.pageContext.humanPageNumber}`} posts={this.state.posts} pageContext={this.state.pageContext}/>:null}                        
                    </Box>

                </Box>
                <LoadPanel visible={this.state.showLoading}/>
            </div>

          );
    }
}
    
export default SearchPost;