import {
  DDown_Seller, 
  DDown_Buyer
  } from '../commonDataSource';
import {processCriteriaFormItems} from '../helper';

const yesNoBoth = [
    { 'Name': 'Yes', 'ID': 'Y'},
    { 'Name': 'No', 'ID': 'N'}
  ];

export function getFormItems(mode) 
{
    //  dataType: for FilterBuilder only
    //  editorTemplate: for FilterBuilder only when custom editor is used
    // caption: for FilterBuilder only
    // defaultCriteria: for form criteria only if default criteria will be set
    // searchOperator: for form criteria only if custom operator is used
    
    return processCriteriaFormItems([

      {
        dataField:'teu',
        editorType: 'dxRangeSlider',
        dataType:'number',
        editorOptions: {
            min: 0,
            max: 25000,
            tooltip:{
            enabled: true,
            showMode: 'onHover',
            position: 'bottom'
            }
        },
        defaultCriteria: [0,25000],
        visible: mode !== 'simple',
        label:{
          text: 'TEU'
        },            
        colSpan: 1
      },
   
        {
          dataField:'yearBuilt',
          editorType: 'dxRangeSlider',
          dataType:'number',
          editorOptions: {
              min: 1936,
              max: 2025,
              tooltip:{
              enabled: true,
              showMode: 'onHover',
              position: 'bottom'
              }
          },
          label:{
            text: 'Year Built'
          },            
          defaultCriteria: [1936,2025],
          visible: mode !== 'simple',
          colSpan: 1
        },
        
        {
          dataField:'price',
          editorType: 'dxRangeSlider',
          dataType:'number',
          editorOptions: {
              min: 0,
              max: 100,
              tooltip:{
              enabled: true,
              showMode: 'onHover',
              position: 'bottom'
              }
          },
          label:{
            text: 'Price'
          },            
          defaultCriteria: [0,100],
          visible: mode !== 'simple',
          colSpan: 1
        },

        {                    
          dataField: 'sCellular',
          editorType: 'dxSelectBox',
          editorOptions: {
            dataSource: yesNoBoth,
            displayExpr: 'Name',
            valueExpr:'ID'
          },
          label: {text: 'Select cellular'},
          colSpan: 1,
          visible: mode !== 'simple'
      },

        {
          dataField:'dateReported',
          editorType: 'dxDateBox',
          dataType:'Date',
          editorOptions: {
              pickerType: 'rollers'
          },
          label: {text: 'Reported Dated'},
          searchOperator: 'between',
          visible: mode !== 'simple',
          colSpan: 1
        },

          {                    
            dataField: 'buyer',
            dataType: 'string',
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: DDown_Buyer(),
                displayExpr: 'buyer',
                valueExpr:'buyer',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'buyer',
                searchTimeout:'200',
                minSearchLength:3,
                showDataBeforeSearch:'true',
                showClearButton:'true',
                placeholder:'Type to search ..'  
            },
            label: {text: 'Buyer'},
            visible: mode !== 'simple',
            colSpan: 2
        },

        {                    
          dataField: 'seller',
          dataType: 'string',
          editorType: 'dxSelectBox',
          editorOptions: {
              dataSource: DDown_Seller(),
              displayExpr: 'seller',
              valueExpr:'seller',
              searchEnabled:'true',
              searchMode:'contains',
              searchExpr:'seller',
              searchTimeout:'200',
              minSearchLength:3,
              showDataBeforeSearch:'true',
              showClearButton:'true',
              placeholder:'Type to search ..'  
          },
          label: {text: 'Seller'},
          visible: mode !== 'simple',
          colSpan: 2
      }

    ], mode);
}