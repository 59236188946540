import React from 'react';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import {Button} from 'devextreme-react';
import { getFirebaseIdToken } from "../../../../utils/auth";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import Chart, { CommonSeriesSettings, Size, Series,  ArgumentAxis, Legend, Tooltip, ValueAxis } from 'devextreme-react/chart';
import { Box, Flex } from '../../../Layout';
import { OwnerCurrentFleetDataSource, OwnerOrderbookDataSource, OwnerFleetChartDataSource } from '../commonDataSource'
import FleetParticular from '../Fleets/FleetParticularForm';

const teuRange =[
  '<1,000','1,000-1,999','2,000-2,999','3,000-3,999','4,000-5,099','5,100-7,499','7,500-9,999','10,000-12,499','12,500-15,199 NPX','15,200-17,999','>18,000'
];

class OwnerDetail extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {ownerName: null, visibleShipDetail: false};
      this.setGrid = (ref) => {
        if (ref != null)
          this.resultGrid = ref.instance;
          this.resultGrid.refresh();
      };
      this.setOdaGrid = (ref) => {
        if (ref != null)
          this.odaGrid = ref.instance;
          this.odaGrid.refresh();
      };
      this.setChart = (ref) => { 
        if (ref != null)
          this._chart = ref.instance;
      }; 

      this.initialFilter = ['beneficialOwner','=','X'];
      if (this.props.initialFilter) {
        this.initialFilter = this.props.initialFilter;
      }


      this.onShipSelectionChanged = this.onShipSelectionChanged.bind(this);
      this.backFromSubScreen = this.backFromSubScreen.bind(this);
      this.load = this.load.bind(this);
    }
    
    componentDidMount() {
      this.load(this.initialFilter);

    }

    backFromSubScreen() {
      this.resultGrid.clearSelection();
      this.odaGrid.clearSelection();
      this.props.enquiryBase.setCriteriaVisible(true); 
      this.setState({visibleShipDetail: null})
    }

    resetResult() {
      this.resultGrid.refresh();
    }
  
    load(filter) {
      var thisObj = this;
      getFirebaseIdToken(this.props.firebase, () => {
        OwnerCurrentFleetDataSource.filter(filter);
        //thisObj.resultGrid.refresh();
        //thisObj.resultGrid.clearSelection();

        OwnerOrderbookDataSource.filter(filter);
        //thisObj.odaGrid.refresh();
        //thisObj.odaGrid.clearSelection();

        OwnerFleetChartDataSource.filter(filter);
        OwnerFleetChartDataSource.load().then(
          (data) => {
            thisObj._chart.refresh();
            thisObj.setState({
              ownerName: data[0].beneficialOwner
            }) 
          },
        (error) => {
            console.log(error);
          }
        )
        
        thisObj.setState({visibleShipDetail: null})
      });
    }
    
    onShipSelectionChanged(e) {
        var data = e.selectedRowsData[0];
        if (data) {
            this.initialFilter = ['vesselId','=',data.vesselId];
            this.props.enquiryBase.setCriteriaVisible(false);
            this.setState({visibleShipDetail: true});
        }
        else {
        }
    }

    render() {
      var ownerName = null;
      ownerName = this.state.ownerName;

      return (
        <React.Fragment>

        <div style={{display: (!this.state.visibleShipDetail)?'inline':'none'}} >

        <Flex wrap={['wrap', 'nowrap','nowrap']}>
          <Box width={[1,'60%','60%']}>
            <Flex wrap='nowrap'>
              <Button style={{marginRight: 10}} icon="back" hint="Back" onClick={this.props.returnCallback} height='30'/>
              <h3 style={{marginTop: 5}}>{ownerName} Fleet List</h3>
            </Flex>
          <TabPanel
            loop={false}
            animationEnabled={true}
            swipeEnabled={true}
            deferRendering={false}
            visible={!this.visibleDetail}
            >
          <Item title={"Current Fleet"}>
            <DataGrid
              elementAttr={{
                class: "myClass"
              }}
              dataSource={OwnerCurrentFleetDataSource}
              showBorders={true}
              selection={{mode: 'single'}}
              ref={this.setGrid}
              onSelectionChanged = {this.onShipSelectionChanged}
              allowColumnReordering={true}
              allowColumnResizing={true}
                >
              <Paging defaultPageSize={20} />
              <Pager
              showInfo={true} />

              <Column dataField='vesselName'
                width={200}
                dataType={'string'}
              />

              <Column dataField='teu'
                width={60}
                dataType={'number'}
                caption={'TEU'}
                format={{type:'fixedPoint',precision:0}}
                defaultSortOrder={'desc'}
              />

              <Column 
                width={70}
                dataField='dwt'
                dataType={'number'}
                caption='DWT'
                format={{type:'fixedPoint',precision:0}}
              />

              <Column dataField='yearBuilt'
                width={50}
                dataType={'number'}
                caption='Built'
              />

              <Column dataField='charterer'
                width={180}
                dataType={'string'}
                caption='Operator'
              />

              <Column dataField='laycan'
                width={90}
                dataType={'Date'}
                format={'MMM-yyyy'}
              />

              <Column dataField='periodInMonth'
                width={100}
                dataType={'string'}
                caption={'Period'}
              />

              <Column dataField='rate'
                width={100}
                dataType={'number'}
                format={{type:'fixedPoint',precision:2}}
              />

              <Column dataField='vesselId'
                width={100}
                dataType={'string'}
                visible={false}
              />

              <Column dataField='imoNo'
                width={100}
                dataType={'string'}
                visible={false}
              />
            </DataGrid>
          </Item>
          <Item title={'Orderbook'}>
            <DataGrid
              elementAttr={{
                class: "myClass"
              }}
              dataSource={OwnerOrderbookDataSource}
              showBorders={true}
              onSelectionChanged = {this.onShipSelectionChanged}
              selection={{mode: 'single'}}
              ref={this.setOdaGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
                >
              <Paging defaultPageSize={20} />
              <Pager
              showInfo={true} />

              <Column dataField='vesselName'
                width={180}
                dataType={'string'}
              />

              <Column dataField='teu'
                width={60}
                dataType={'number'}
                caption={'TEU'}
                format={{type:'fixedPoint',precision:0}}
                defaultSortOrder={'desc'}
              />

              <Column dataField='yearBuilt'
                width={50}
                dataType={'number'}
                caption='Built'
              />

              <Column dataField='shipyardName'
                width={180}
                dataType={'string'}
              />

              <Column dataField='charterOwn'
                width={60}
                caption={'Own/Char'}
                dataType={'string'}
              />

              <Column dataField='vesselId'
                width={220}
                dataType={'string'}
                visible={false}
              />

              <Column dataField='imoNo'
                width={220}
                dataType={'string'}
                visible={false}
              />
            </DataGrid>
          </Item>
          </TabPanel>
          </Box>
          <Box width={[1,'40%','40%']}>
          <h3>Breakdown of Number of Ship by TEU Range of {ownerName}</h3>
          <Chart 
            ref={this.setChart}
            dataSource={OwnerFleetChartDataSource}            
            rotated={true}
            >
            <Legend verticalAlignment={'bottom'} horizontalAlignment={'center'} position={'outside'}  />
            <Size height={620} />
            <CommonSeriesSettings type ="stackedBar" argumentField='teuRange' />
            <Series
              name='Current'
              valueField='totalShips'
              color='#BE1E2D'
              />
            <Series
              name='Ordered'
              valueField='orderedShips'                            
              color='#636466'
              />
            <ValueAxis tickInterval={5} allowDecimals={false} />
            <ArgumentAxis categories={teuRange} /> 
            
            <Tooltip enabled={true} />
          </Chart>
          </Box>
        </Flex>
        </div>

            {this.state.visibleShipDetail &&
            <FleetParticular
              firebase={this.props.firebase}
              initialFilter={this.initialFilter}
              returnCallback={this.backFromSubScreen}
            />
            }

        </React.Fragment>
      );
    }
  }

  export default OwnerDetail;