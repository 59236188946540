import {
  dDown_BenOwner, 
  DDown_Operator, 
  DDown_Shipyard} from '../commonDataSource';
import {processCriteriaFormItems} from '../helper';

const yesNoBoth = [
    { 'Name': 'Yes', 'ID': 'Y'},
    { 'Name': 'No', 'ID': 'N'}
  ];

export function getFormItems(mode) 
{
    //  dataType: for FilterBuilder only
    //  editorTemplate: for FilterBuilder only when custom editor is used
    // caption: for FilterBuilder only
    // defaultCriteria: for form criteria only if default criteria will be set
    // searchOperator: for form criteria only if custom operator is used
    
    return processCriteriaFormItems([

    {
        dataField:'deliveryDate',
        editorType: 'dxDateBox',
        dataType:'Date',
        editorOptions: {
            pickerType: 'rollers'
        },
        label: {text: 'Delivery Date'},
        searchOperator: 'between',
        visible: mode !== 'simple',
        colSpan: 1
      },

      {
        dataField:'orderDate',
        editorType: 'dxDateBox',
        dataType:'Date',
        editorOptions: {
            pickerType: 'rollers'
        },
        label: {text: 'Ordered Date'},
        searchOperator: 'between',
        visible: mode !== 'simple',
        colSpan: 1
      },

      {
        dataField: 'groupCode',
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: DDown_Operator(),
            displayExpr: 'fullOperator',
            valueExpr:'groupCode',
            searchEnabled:'true',
            searchMode:'contains',
            searchExpr:'fullOperator',
            searchTimeout:200,
            minSearchLength: 2,
            showClearButton:true,
            showDataBeforeSearch:true,
            placeholder: 'Type to search by entity, group name or country ...',
        },
        label: {text: 'Operator'},
        visible: mode !== 'simple',
        searchOperator: 'contains',
        colSpan: 2
    },

   
          {                    
            dataField: 'beneficialOwner',
            dataType: 'string',
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: dDown_BenOwner(),
                displayExpr: 'benOwner',
                valueExpr:'benOwner',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'benOwner',
                searchTimeout:'200',
                minSearchLength:3,
                showDataBeforeSearch:'true',
                showClearButton:'true',
                placeholder:'Type to search ..'  
            },
            label: {text: 'Owner'},
            visible: mode !== 'simple',
            colSpan: 1
        },

        {
          dataField: 'shipyardName',
          dataType: 'string',
          editorType: 'dxSelectBox',
          editorOptions: {
              dataSource: DDown_Shipyard(),
              displayExpr: 'shipyardName',
              valueExpr:'shipyardName',
              searchEnabled:'true',
              searchMode:'contains',
              searchExpr:'shipyardName',
              searchTimeout:'200',
              minSearchLength:3,
              showDataBeforeSearch:'true',
              showClearButton:'true',
              placeholder:'Type to search ..'  
          },
          label: {text: 'Shipyard'},
          visible: mode !== 'simple',
          colSpan: 1
      },


    {                    
      dataField: 'sCellular',
      editorType: 'dxSelectBox',
      editorOptions: {
        dataSource: yesNoBoth,
        displayExpr: 'Name',
        valueExpr:'ID'
      },
      label: {text: 'Cellular only'},
      colSpan: 1,
      visible: mode !== 'simple'
    },
    {
      dataField:'teu',
      editorType: 'dxRangeSlider',
      dataType:'number',
      editorOptions: {
          min: 0,
          max: 25000,
          tooltip:{
          enabled: true,
          showMode: 'onHover',
          position: 'top'
          }
      },
      defaultCriteria: [0,25000],
      visible: mode !== 'simple',
      label:{
        text: 'TEU'
      },            
      colSpan: 1
    }

  ], mode);
}