import React from 'react';
import { Box, Flex } from '../../../Layout';
import { getFirebaseIdToken } from "../../../../utils/auth";
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import Chart, { CommonSeriesSettings, Size, SeriesTemplate, Legend } from 'devextreme-react/chart';
import ODataStore from 'devextreme/data/odata/store';
import {oDataStoreDefaultSetting} from '../../oDataStoreDefaultSetting';
import DataSource from 'devextreme/data/data_source';
import PortDetail from './portDetail'
// import ChartCell from './chartCell'

const portDetailLabel = "Port Detail"

var legendData;
const ThruputYtdChartDataSource = new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/PortThruputYtdChart`,
    onLoaded: (e) => {  
      const yStart = (new Date()).getFullYear().toString() + '01';
      const ytdData = e.filter(
        (rec) => 
        {
            return (rec.tpMonth >= yStart) ;
        }
      );

      legendData = ytdData.reduce(function (r, o) {
        (r[o.portGroup])? r[o.portGroup] += o.teu : r[o.portGroup] = o.teu;
        return r;
      }, {});
    } ,      
    ...oDataStoreDefaultSetting
  })
});

function getDateMonthsBefore(date,nofMonths) {
  var thisMonth = date.getMonth();
  var newDate = new Date(date);
  // set the month index of the date by subtracting nofMonths from the current month index
  newDate.setMonth(thisMonth - nofMonths);
  // When trying to add or subtract months from a Javascript Date() Object which is any end date of a month,  
  // JS automatically advances your Date object to next month's first date if the resulting date does not exist in its month. 
  // For example when you add 1 month to October 31, 2008 , it gives Dec 1, 2008 since November 31, 2008 does not exist.
  // if the result of subtraction is negative and add 6 to the index and check if JS has auto advanced the date, 
  // then set the date again to last day of previous month
  // Else check if the result of subtraction is non negative, subtract nofMonths to the index and check the same.
  if ((thisMonth - nofMonths < 0) && (newDate.getMonth() !== (thisMonth + nofMonths))) {
    newDate.setDate(0);
  } else if ((thisMonth - nofMonths >= 0) && (newDate.getMonth() !== thisMonth - nofMonths)) {
    newDate.setDate(0);
  }
  return newDate;
}

class portThruput extends React.Component {
    constructor(props) {
      super(props);

      this.state = {visibleDetail: null};

      this.setTpGrid =(ref) => {
        if (ref != null)
          this.tpGrid = ref.instance;
      };

      this.setTpChart = (ref) => { 
        if (ref != null)
          this.TpChart = ref.instance;
      }; 

      this.setPortDetail = (ref) => { 
        if (ref != null)
          this.PortDetailForm = ref;
      }; 

      var thisObj = this;
      this.ThruputTtmDataSource = new DataSource({
        store: new ODataStore({
          url: `${process.env.GATSBY_ODATA_SERVER_URL}/PortThruputTtm`,
          onLoaded: (e) => {  
            if (e.length > 0) {
              const currDate = new Date(e[0].latestMonth);
              const currDate1 = getDateMonthsBefore(currDate,1);
              const currDate2 = getDateMonthsBefore(currDate,2);              
              
              thisObj.setState({captionCurr:currDate.toLocaleString(typeof window !== 'undefined'?window.navigator.userLanguage || window.navigator.language:'en-US',{year: 'numeric',month: 'short'})
              , captionCurr1:currDate1.toLocaleString(typeof window !== 'undefined'?window.navigator.userLanguage || window.navigator.language:'en-US',{year: 'numeric',month: 'short'})
              , captionCurr2:currDate2.toLocaleString(typeof window !== 'undefined'?window.navigator.userLanguage || window.navigator.language:'en-US',{year: 'numeric',month: 'short'})});
        
            }
          } ,                
          ...oDataStoreDefaultSetting
        })
      });

      this.ThruputTtmDataSource.filter();
      ThruputYtdChartDataSource.paginate(false);
      ThruputYtdChartDataSource.filter();

      this.backFromSubScreen = this.backFromSubScreen.bind(this);
      this.onSelectionChanged=this.onSelectionChanged.bind(this);
    }

    componentDidMount() {
    }

    backFromSubScreen() {
      this.tpGrid.clearSelection();
      this.setState({visibleDetail: null})
    }

    load(filter) {
      
      var thisObj = this;
      getFirebaseIdToken(this.props.firebase, () =>  {
        thisObj.PortDetailForm.load(filter);
        this.setState({visibleDetail: portDetailLabel});
      });

    }

    resetResult() {
    }

    sortLegendItems(items) {
      return items.sort((a, b) => {
          let itemA = legendData[a.text];
          let itemB = legendData[b.text];
          if(itemA < itemB) return 1;
          if(itemA > itemB) return -1;
          return 0;
      });
    }

    onSelectionChanged(e){
      var data = e.selectedRowsData[0];
      if (data) {
        var filter = ["portGroup","contains",data.portGroup];
        this.load([filter]);
      }
      else {
      }
    }

    render() {
      return (
        <React.Fragment>
          <Flex wrap={['wrap', 'nowrap','nowrap']}>
          <Box width={[1,'630px','630px']}>
          <div style={{display: (!this.state.visibleDetail)?'inline':'none'}} >
          <h3>Top Ports by Trailing Twelve Months (TTM) Throughput in TEU</h3>
          <DataGrid
              elementAttr={{
                class: "myClass"
              }}
              dataSource={this.ThruputTtmDataSource}
              showBorders={true}
              selection={{mode: 'single'}}
              onSelectionChanged={this.onSelectionChanged} 
              ref={this.setTpGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onContentReady={this.onContentReady}              
              visible={!this.state.visibleDetail}
              >
          <Paging defaultPageSize={10} />
          <Pager
            showInfo={true} />     
                
              <Column 
                dataField='ranking' 
                caption='#'
                width={30}                
              />

              <Column 
                dataField='portGroup' 
                caption='Port Name'
                width={100}                
              />

              <Column dataField='M1'
                width={'70'}
                caption={this.state.captionCurr}
                format={{type:'fixedPoint',precision:0}}
              />

              <Column dataField='M2'
                width={'70'}
                caption={this.state.captionCurr1}
                format={{type:'fixedPoint',precision:0}}
              />

              <Column dataField='M3'
                width={'70'}
                caption={this.state.captionCurr2}
                format={{type:'fixedPoint',precision:0}}
              />

              <Column dataField='ttmTeu'
                width={'70'}

                caption={'TTM'}
                format={{type:'fixedPoint',precision:0}}
                defaultSortOrder={'desc'}
              />

              <Column dataField='lastTtmTeu'
                width={'70'}

                caption={'last TTM'}
                format={{type:'fixedPoint',precision:0}}
              />

              <Column dataField='yoyChange'
                width={'60'}
                caption={'TTM % Chg'}
                format={'#.##%'}
              />

              <Column 
                dataField='tpQtrly' 
                caption='*'
                width={30}                
              />

            </DataGrid>
          </div>
          </Box>
          <Box width={[1,'calc(100vw - 632px)','calc(100vw - 632px)']}>
            <div style={{display: (!this.state.visibleDetail)?'inline':'none'}} >
            <h3>Chart: Top 10 Ports Monthly Throughput (Last 24 Months) in TEU</h3>
            <Chart
                ref={this.setTpChart}
                dataSource={ThruputYtdChartDataSource}  
                >
                <CommonSeriesSettings type="line" argumentField="tpMonth" valueField="teu"/>
                <SeriesTemplate nameField="portGroup" />
                <Size height={300} />
                <Legend horizontalAlignment="right" verticalAlignment="bottom"
                  customizeItems={this.sortLegendItems} />
            </Chart>
            </div>

          </Box>
        </Flex>

        <div style={{display: (this.state.visibleDetail)?'inline':'none'}} >
          <Box m={'0 0 20px 0'}>
            <PortDetail
              firebase={this.props.firebase}
              enquiryBase={this.props.enquiryBase}
              ref={this.setPortDetail}
              returnCallback={this.backFromSubScreen}
            />
          </Box>
        </div>
     
        </React.Fragment>
      );
    }
  }

  export default portThruput;