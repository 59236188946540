import React from 'react';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { getFirebaseIdToken } from "../../../../utils/auth";
import { Box, Flex } from '../../../Layout';
import { servicesDataSource } from '../commonDataSource';
import ServiceDetail from './ServiceDetail';

class ServiceGrid extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {visibleDetail: null};
      this.setGrid = (ref) => {
        if (ref != null)
            this.resultGrid = ref.instance;
      };
      this.setSvcDetail = (ref) => {
        if (ref != null)
            this.svcDetail = ref;
      };

      this.onSelectionChanged = this.onSelectionChanged.bind(this);
      this.backFromSubScreen = this.backFromSubScreen.bind(this);
    }

    backFromSubScreen() {
      this.resultGrid.clearSelection();
      this.props.enquiryBase.setCriteriaVisible(true); 
      this.setState({visibleDetail: null});
    }

    resetResult() {
      getFirebaseIdToken(this.props.firebase,() => {
        servicesDataSource.filter('lsCode','=', 0);
        this.resultGrid.refresh();
      });
    }

    load(filter) {

    if (filter) {
      const groupCodesIndex = filter.findIndex((criteria) => {return criteria[0] === 'groupCodes'});

      if (groupCodesIndex >= 0) {
        filter[groupCodesIndex] = ['groupCodes','contains', filter[groupCodesIndex][2].substring(0,4)];
      };

    }

    getFirebaseIdToken(this.props.firebase,() => {
        servicesDataSource.filter(filter);
        servicesDataSource.group("");
        servicesDataSource.load();
      });
      this.resultGrid.clearSelection();
    }
  
    onSelectionChanged(e) {
      var thisObj = this;
      var data = e.selectedRowsData[0];
      getFirebaseIdToken(this.props.firebase,() => {
        if (data) {
          this.currLsCode = data.lsCode;
          //thisObj.svcDetail.load(['lsCode','=',data.lsCode]);
          this.props.enquiryBase.setCriteriaVisible(false);
          thisObj.setState({initialFilter: ['lsCode','=',data.lsCode],  visibleDetail: true})
          //console.log(this.currLsCode);
        }
        else {
        }
      });
    }

    render() {
      return (
        <React.Fragment>
        <div style={!this.state.visibleDetail?{}:{display: 'none'}}>
        <Flex wrap={['wrap', 'nowrap','nowrap']}>
        <Box width={'100%'}>
        <DataGrid
          elementAttr={{
            class: "myClass"
          }}
          dataSource={servicesDataSource}
          showBorders={true}
          selection={{mode: 'single'}}
          ref={this.setGrid}
          allowColumnReordering={true}
          allowColumnResizing={true}
          onSelectionChanged={this.onSelectionChanged} 
          columnAutoWidth={false}
          wordWrapEnabled={false}
          visible={!this.state.visibleDetail}
            >
          <Paging defaultPageSize={15} />
          <Pager
            showInfo={true} />     

          <Column dataField='serviceName'
            dataType={'string'}
            minWidth={'150'}
          />

          <Column dataField='trade'
            caption='Trade'
            dataType={'string'}
            width={'80'}
          />

          <Column dataField='operators'
            caption='Operators'
            dataType={'string'}
            minWidth={'200'}
          />

          <Column dataField='ships'
            dataType={'string'}
            caption={'Ships Deployed'}
            minWidth={'250'}
          />

          <Column dataField='frequency'
            width={'80'}
            dataType={'number'}
            format={{type:'fixedPoint',precision:0}}
        />

          <Column dataField='duration'
            width={'80'}
            caption={'Rotation Days'}
            dataType={'number'}
            format={{type:'fixedPoint',precision:0}}
          />

          <Column dataField='teuWeekly'
            width={'80'}
            caption={'Avg Capacity'}
            dataType={'number'}
            format={{type:'fixedPoint',precision:0}}
          />

          <Column dataField='sequence'
            dataType={'number'}
            visible={false}
            defaultSortOrder={'asc'}
          />

          <Column dataField='lsCode'
            dataType={'string'}
            visible={false}
          />
        </DataGrid>
        </Box>
        </Flex>
        </div>

        {this.state.visibleDetail && 
          <ServiceDetail 
            firebase={this.props.firebase}
            lsCode={this.currLsCode}
            ref={this.setSvcDetail}
            showFleet={true}
            enquiryBase={this.props.enquiryBase}
            initialFilter={this.state.initialFilter}
            returnCallback={this.backFromSubScreen}
          />
        }
        </React.Fragment>
      );
    }
  }

  export default ServiceGrid;