import React from 'react';
import portThruputPivot from './portThruput';
import {getFormItems} from './formItem';
import EnquiryBase from '../enquiryBase';

class portThruput extends React.Component {
    constructor(props) {
      super(props);
      this.formItemsSimple = getFormItems('simple');
      this.formItemsBasic = getFormItems('basic');
      this.formItemsAdvance = getFormItems('advance');

    }

    render() {
      return (
        <EnquiryBase 
        formItemsBasic={this.formItemsBasic}
        searchResultComponent={portThruputPivot} 
        firebase={this.props.firebase} />
      );  
    }
  }

  export default portThruput;