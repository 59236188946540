import {dDown_Port} from '../commonDataSource';
import {processCriteriaFormItems} from '../helper';

export function getFormItems(mode) 
{
    //  dataType: for FilterBuilder only
    //  editorTemplate: for FilterBuilder only when custom editor is used
    // caption: for FilterBuilder only
    // defaultCriteria: for form criteria only if default criteria will be set
    // searchOperator: for form criteria only if custom operator is used
    return processCriteriaFormItems([
        
        {                    
            dataField: 'depPort',
            editorType: 'dxSelectBox',
            dataType: 'string',
            validationRules:[{type:"required", message:"It is required"}],
            editorOptions: {
                dataSource: dDown_Port(),
                displayExpr: 'fullPort',
                valueExpr:'portCode',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'fullPort',
                searchTimeout:'200',
                minSearchLength:'0',
                showDataBeforeSearch:'true',
                showClearButton:'true'
            },
            label: {text: 'Departing by Port / Country / Region'},
            colSpan: 2,
            visible: true,
            searchOperator: "="
        },

        {                    
            dataField: 'arrPort',
            editorType: 'dxSelectBox',
            editorTemplate: 'arrPort',
            dataType: 'string',
            validationRules:[{type:"required", message:"It is required"}],
            editorOptions: {
                dataSource: dDown_Port(),
                displayExpr: 'fullPort',
                valueExpr:'portCode',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'fullPort',
                searchTimeout:'200',
                minSearchLength:'0',
                showDataBeforeSearch:'true',
                showClearButton:'true'
            },
            label: {text: 'Arriving by Port / Country / Region'},
            colSpan: 2,
            visible: true,
            searchOperator: "="
        },
    
        {
            dataField:'departureDateTime',
            caption: 'Departure Date',
            editorType:'dxDateBox',
            dataType: 'date',
            searchOperator: 'between',
            visible: mode !== 'simple',
        },

        {
            dataField:'arrivalDateTime',
            caption: 'Arrival Date',
            editorType:'dxDateBox',
            dataType: 'date',
            searchOperator: 'between',
            visible: mode !== 'simple',
        }

        ], mode);
}