import React from 'react';
import Port2PortGrid from './Port2PortGrid';
import {getFormItems} from './formItem';
import EnquiryBase from '../enquiryBase';

class Port2Port extends React.Component {
    constructor(props) {
      super(props);
      this.formItemsSimple = getFormItems('simple');
      this.formItemsBasic = getFormItems('basic');
      this.formItemsAdvance = getFormItems('advance');

    }

    render() {
      return (
        <EnquiryBase 
        formItemsSimple={this.formItemsSimple}
        formItemsBasic={this.formItemsBasic}
        searchResultComponent={Port2PortGrid} 
        firebase={this.props.firebase} />
      );  
    }
  }

  export default Port2Port;