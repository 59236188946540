import React from 'react';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { getFirebaseIdToken } from "../../../../utils/auth";
import Chart, { CommonSeriesSettings, Size, Series, ArgumentAxis, Legend } from 'devextreme-react/chart';
import { Box, Flex } from '../../../Layout';
import { ownerSummaryDataSource } from '../commonDataSource';
import OwnerParticular from './ownerDetail';
// import ChartCell from './chartCell';

class OwnersSummaryGrid extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { showSummary: true, initialFilter: null, showDetail: null };
      this.setSummaryGrid = (ref) => {
        if (ref != null)
            this.summaryGrid = ref.instance;
      };
      this.setSummaryChart = (ref) => { 
        if (ref != null)
          this.summaryChart = ref.instance;
      }; 
      this.setOwnerDetailForm = (ref) => { 
        if (ref != null)
          this.OwnerDetailForm = ref;
      }; 

      this.onSelectionChanged = this.onSelectionChanged.bind(this);
      this.backFromSubScreen = this.backFromSubScreen.bind(this);
    }

    componentDidMount() {
      getFirebaseIdToken(this.props.firebase, () => {
        ownerSummaryDataSource.filter();
      });  
    }

    backFromSubScreen() {
      this.props.enquiryBase.setCriteriaVisible(true); 
      //this.summaryChart.render();
      this.setState({showSummary: true, showDetail: null});
    }

    resetResult() {
      this.setState({showSummary: true, showDetail: null});
    }
  
    load(filter) {
      this.setState({showDetail: false})
      this.setState({showDetail: true, initialFilter: filter, showSummary: null});      
    }
    
    onSelectionChanged(e) {
      var data = e.selectedRowsData[0];
      var benFilter = ['beneficialOwner','=','XX'];
      if (data) {
        benFilter=['beneficialOwner','=',data.benOwner];          
        this.setState({showDetail: true, initialFilter: benFilter, showSummary: null});
      }
      else {
      }
    }

    render() {
      return (
        <React.Fragment>
        <div style={{display: (this.state.showSummary)?'inline':'none'}} >
        <Flex wrap={['wrap', 'nowrap','nowrap']}>
        <Box width={[1,'530px','530px']}>
        <DataGrid
          elementAttr={{
            class: "myClass"
          }}
          dataSource={ownerSummaryDataSource}
          showBorders={true}
          selection={{mode: 'single'}}
          ref={this.setSummaryGrid}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={false}
          onSelectionChanged={this.onSelectionChanged}
            >
          <Paging defaultPageSize={20} />
          <Pager
          showInfo={true} />

          <Column 
            dataField='benOwner'
            Width={240}
            dataType={'string'}
            caption={'Beneficial Owner Name'}
          />

          <Column caption='Current' alignment='center'>
            <Column dataField='totalTeu'
              width={80}
              dataType={'number'}
              caption={'TEU'}
              format={{type:'fixedPoint',precision:0}}
              defaultSortOrder={'desc'}
            />

            <Column 
              width={40}
              dataField='totalShip'
              dataType={'number'}
              caption='Ships'
              format={{type:'fixedPoint',precision:0}}
            />
          </Column>

          <Column caption='Orderbook' alignment='center'>
            <Column dataField='orderedTeu'
              width={80}
              dataType={'number'}
              caption='TEU'
              format={{type:'fixedPoint',precision:0}}
            />

            <Column dataField='orderedShip'
              width={40}
              dataType={'number'}
              caption="Ships"
              format={{type:'fixedPoint',precision:0}}
            />
          </Column>

          <Column dataField='benOwner' 
            width={80}
            visible={false}
          />

        </DataGrid>
        </Box>
        <Box width={[1,'1%','1%']}>
        </Box>
        <Box width={[1,1,1]}>
          
          <h3>Fleet Chart by Range</h3>
          <Chart 
            ref={this.setSummaryChart}
            dataSource={ownerSummaryDataSource}            
            rotated={true}
            >              
            <Size height={617}/>
            <CommonSeriesSettings type ="stackedBar" />
            <Legend position={'outside'} verticalAlignment={'bottom'} horizontalAlignment={'center'} itemTextPosition='left'/>
            <Series
              name='TEU'
              valueField='totalTeu'
              argumentField='benOwner'                                                
              color='#BE1E2D'
              />
            <Series
              name='Ordered'
              valueField='orderedTeu'
              argumentField='benOwner'                                                 
              color='#636466'
            />
            <ArgumentAxis
              inverted={true}>
            </ArgumentAxis>            
          </Chart>
          </Box>
        </Flex>
        </div>

          {this.state.showDetail &&
          <OwnerParticular
            ref={this.setOwnerDetailForm}
            firebase={this.props.firebase}
            initialFilter={this.state.initialFilter}
            returnCallback={this.backFromSubScreen}
            enquiryBase={this.props.enquiryBase}              
            />
          }

        </React.Fragment>
      );
    }
  }

  export default OwnersSummaryGrid;