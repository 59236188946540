import SimpleHashTable from 'simple-hashtable';
//const accessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IkJCOENlRlZxeWFHckdOdWVoSklpTDRkZmp6dyIsImtpZCI6IkJCOENlRlZxeWFHckdOdWVoSklpTDRkZmp6dyJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvOWU0OTc3MzEtMjFmNS00YWMwLTg3N2QtMjFhODUyOWRmMjM2LyIsImlhdCI6MTU3NTUyNDIwNCwibmJmIjoxNTc1NTI0MjA0LCJleHAiOjE1NzU1MjgxMDQsImFpbyI6IjQyVmdZTmlmbjdMZ290bmVCS3ZOSjI5S0wxaHdCZ0E9IiwiYXBwaWQiOiIyNmFlYjM4Yi0xMTRhLTRkOTktOTJmNy1mYTQzZDgzODI2ZmUiLCJhcHBpZGFjciI6IjEiLCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC85ZTQ5NzczMS0yMWY1LTRhYzAtODc3ZC0yMWE4NTI5ZGYyMzYvIiwib2lkIjoiZmZiNzEzZGYtMjRkMS00OWIxLWFkMzUtNjRlMmZjYjYxZjQxIiwicm9sZXMiOlsiVGVuYW50LlJlYWRXcml0ZS5BbGwiLCJUZW5hbnQuUmVhZC5BbGwiXSwic3ViIjoiZmZiNzEzZGYtMjRkMS00OWIxLWFkMzUtNjRlMmZjYjYxZjQxIiwidGlkIjoiOWU0OTc3MzEtMjFmNS00YWMwLTg3N2QtMjFhODUyOWRmMjM2IiwidXRpIjoiQlVtVkV1WmRVRS1JX0RtbG1LbmNBUSIsInZlciI6IjEuMCJ9.A1spQhY4Pf003Aytyk7t0FKbrp7XRpiHaOb3nNOyuy6BTanQ4x0Hz0snHUKM_6XfJ01awfq0ZsnJlU9YXhZeKKj_6C6gFJ3AQ0QglQRexhXFiK37Bwro0JXaKrn5mLzI7DN9WrglWB8Rml6p6EemPH9DQPPfskD6pJswOjtQFcHX92V3aw3gGcG1-3GFiSCfo4keFEcJenWfFgCMBrUzNbdVtPCsy2fZG0ucB9oPp2qXcWsHRg898os8dzG1S5dfaZm_9v-CZkfA-Rs2n2eQaiuzKGiwisMud-MO8fdzB68jW48TIDoU22v6srtmeg0L1-3VLBDpBG-jVJXDway22w";
var powerBIToken = new SimpleHashTable();

export function getAccessToken(callingObj, callbackFunc)  {
  if(typeof XMLHttpRequest !== "undefined") {
    var request = new XMLHttpRequest();
    //var hostName = (window !== 'undefined'?window.location.hostname:'');
    //var apiurl = `${powerBiApiUrl}/getAccessToken`;
    var apiurl = `${process.env.GATSBY_POWERBI_API_URL}/getAccessToken`;
    request.open('GET', apiurl,true);
    request.setRequestHeader('Content-Type','application/json; charset=utf-8');
    request.setRequestHeader('Authorization',`Bearer ${(typeof window !== 'undefined')?window.localStorage.getItem("userIdToken"):''}`);
    request.onreadystatechange = function (reposta) {
      if (this.readyState === 4) {
        //console.log(this.responseText);
        var token = JSON.parse(this.responseText).accessToken;
        if (token)
          callbackFunc(token, callingObj);
        else
          console.log('PowerBI token is not obtained');
        
      }
    };
    request.send();  
  }
}

export function getPowerBIAccessToken(accessToken, embedType, embedTypeId, callingObj, callbackFunc) { 
  var hashKey = embedType + embedTypeId;
  if (powerBIToken.containsKey(hashKey)) {
    var token = powerBIToken.get(hashKey);
    if (token.expiration > (new Date())) {
      callbackFunc(token.accessToken, callingObj);
      return;
    }
  }

  var groupId = '2fe60ee4-a7d5-4904-a834-5d93a2e552e1';
  if(typeof XMLHttpRequest !== "undefined") {
    var request = new XMLHttpRequest();
      
    request.open('POST', 'https://api.powerbi.com/v1.0/myorg/groups/'+ groupId+'/' + embedType + 's/'+embedTypeId+'/GenerateToken',true);

    request.setRequestHeader('Content-Type','application/json; charset=utf-8');
    request.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    
    
    request.onreadystatechange = function (reposta) {
      if (this.readyState === 4) {
        //console.log('Status:', this.status);
        //console.log('Body:', this.responseText);
        var token = {
          accessToken: JSON.parse(this.responseText).token,
          expiration: new Date(JSON.parse(this.responseText).expiration)
        }
        powerBIToken.put(hashKey, token);
        //console.log('token:', token.accessToken);
        callbackFunc(token.accessToken, callingObj);
      }
    };

    var str = {
      accessLevel:"View"
    };
    request.send(JSON.stringify(str));
  }
}

