import React from 'react';
import DataGrid, { Column, Pager, Paging, FilterRow } from 'devextreme-react/data-grid';
import { getFirebaseIdToken } from "../../../../utils/auth";
import ContextMenu from 'devextreme-react/context-menu';
import { Popup } from 'devextreme-react/popup';
import {port2PortDataSource, servicesDataSource} from '../commonDataSource'
import { Box, Flex } from '../../../Layout';

class Port2PortGrid extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {selectedRowsData: null, popupOption: null};
      this.setGrid = (ref) => {
        if (ref != null)
            this.resultGrid = ref.instance;
      };
      this.setSvcGrid = (ref) => {
        if (ref != null)
            this.resultSvcGrid = ref.instance;
      };
      port2PortDataSource.filter(['depPort','=','xyz']);
      servicesDataSource.filter([['portCode','contains','cnytn'],'and',['portCode','contains','uslax']]);

      this.onSelectionChanged = this.onSelectionChanged.bind(this);
      this.onSvcSelectionChanged = this.onSvcSelectionChanged.bind(this);

      this.showContextMenu = this.showContextMenu.bind(this);
      this.contextMenuItemClick = this.contextMenuItemClick.bind(this);
      this.showPopup = this.showPopup.bind(this);
      this.hidePopup = this.hidePopup.bind(this);
    }



    showPopup(popupOption) {
      this.setState({popupOption: popupOption});
    }

    contextMenuItemClick(e) {
      if (!e.itemData.items) {
        this.showPopup(e.itemData.text);
      }
    }

    hidePopup() {
      this.setState({popupOption: null});
    }

    showContextMenu(e) {
      this.data1 = e.row.data.vesselName;
      this.data2 = e.row.data.imoNo;
    }

    resetResult() {
      port2PortDataSource.filter(['depPort','=','xyz']);
      servicesDataSource.filter([['portCode','contains','cnytn'],'and',['portCode','contains','uslax']]);
      getFirebaseIdToken(this.props.firebase, () =>{
          this.resultGrid.refresh();
          this.resultSvcGrid.refresh();
        });
      this.setState({selectedOperator: null});
    }
  
    load(filter) {
      port2PortDataSource.filter(filter);
      
      var fil = JSON.parse(JSON.stringify(filter));
      for (var index=0; index < fil.length; index ++) {
        if (fil[index][0] === "depPort") {
          fil[index][0] = "portCode";
          fil[index][1] = "contains";
        }
        else if (fil[index][0] === "arrPort") {
          fil[index][0] = "portCode";
          fil[index][1] = "contains";
        }
      };
      servicesDataSource.filter(fil);

      getFirebaseIdToken(this.props.firebase, () =>{
          this.resultGrid.refresh();
          this.resultSvcGrid.refresh();
        }); 
/*      this.resultGrid.clearSelection(); */
    }
  
    onSelectionChanged(e) {
      var data = e.selectedRowsData[0];
      if (data) {
      }
      else {
      }
    }

    onSvcSelectionChanged(e) {
      var data = e.selectedRowsData[0];
      if (data) {
      }
      else {
      }
    }

    vesselNameTemplate(props) {  
        const link = <a href={`javascript: alert('${props.data.vesselName}');`}>{props.data.vesselName}</a>  
        return link  
    } 

    render() {
      return (
        <React.Fragment>
          <h3>Standard Sailings</h3>
            <Flex>
              <Box m={[1]}>
              <DataGrid
                elementAttr={{
                  class: "myClass"
                }}
                dataSource={servicesDataSource}
                showBorders={true}
                selection={{mode: 'single'}}
                onSelectionChanged={this.onSvcSelectionChanged}        
                ref={this.setSvcGrid}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                rowAlternationEnabled={false}
                  >
                <Paging defaultPageSize={20} />
                <Pager showInfo={true} />        
                
                <Column dataField='serviceName' dataType={'string'} width={230} />
                <Column dataField='duration' dataType={'number'} />  
                <Column dataField='frequency' dataType={'number'} />  
                <Column dataField='rotations' dataType={'string'} />
                <Column dataField='carriers' dataType={'string'} />
              </DataGrid>
              </Box>
            </Flex>
          <h3>Scheduled Sailings</h3>
            <Flex>
            <Box m={[1]}>
              <DataGrid
                elementAttr={{
                  class: "myClass"
                }}
                dataSource={port2PortDataSource}
                showBorders={true}
                selection={{mode: 'single'}}
                onSelectionChanged={this.onSelectionChanged}        
                ref={this.setGrid}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                  >
                <Paging defaultPageSize={20} />
                <Pager
                  showInfo={true} />        

                <FilterRow visible={true} />

                <Column type={'buttons'} width={50}
                  caption={'i'}
                  buttons={[{
                    hint: 'more',
                    icon: 'more',
                    onClick: this.showContextMenu
                  }]} />

                <Column dataField='liner' dataType={'string'}  />
                <Column dataField='vessel' dataType={'string'}  />
                <Column dataField='departureDateTime' dataType={'date'} format={'yyyy-MM-dd hh:mm'} />
                <Column dataField='depVoyage' dataType={'string'} />
                <Column dataField='arrivalDateTime' dataType={'date'} format={'yyyy-MM-dd hh:mm'} />
                <Column dataField='arrVoyage' dataType={'string'} />  
              </DataGrid>
            </Box>
            </Flex>

      <ContextMenu
          dataSource={[{ text: 'Option 1' },{ text: 'Option 2' }]}
          width={200}
          target={".myClass .dx-link.dx-icon-more"}
          showEvent={"dxclick"}
          onItemClick={this.contextMenuItemClick} />
        <Popup
          visible={this.state.popupOption}
          onHiding={this.hidePopup}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={true}

          title="Detail Page"
        >
          {
            this.state.popupOption === 'Option 1'? <p>{this.data1}</p>:
            this.state.popupOption === 'Option 2'?<p>{this.data2}</p>: null
          }
        </Popup>
   
              
        </React.Fragment>
      );
    }
  }

  export default Port2PortGrid;