import React from "react"
import { navigate } from '@reach/router';
import { Box, Flex } from "../Layout"
import { LoadPanel } from 'devextreme-react/load-panel';
import colors from '../../utils/colors';  
import { PayPalButton } from "../../utils/paypal";
import { isBrowser, hasRole } from "../../utils/auth";
import Logout from  '../../components//Logout';
import Link from 'gatsby-link';


class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.isTrialOrExpired = hasRole(['trial','expired','notadmin']);
    this.currRole = window.localStorage.getItem("role");
    this.serviceCode = props.option;
    
    if (props.option === 'sailingnote') {
        this.price = parseFloat(process.env.GATSBY_PURCHASE_SAILINGNOTE);
        this.service = "Linerlytica Weekly Market Pulse";
    } else if (props.option === 'sailingnote_mth') {
        this.price = parseFloat(process.env.GATSBY_PURCHASE_SAILINGNOTE_MTH);
        this.service = "Linerlytica Monthly Market Pulse";
    } else if (props.option === 'sailingnote_mth_exist') {
        this.price = parseFloat(process.env.GATSBY_PURCHASE_SAILINGNOTE_MTH_EXIST);
        this.service = "Linerlytica Monthly Market Pulse (for Existing Subscribers)";
    } else if (props.option === 'full') {
        this.price = parseFloat(process.env.GATSBY_PURCHASE_FULL);
        this.service = "Linerlytica Full Website";
    } else {
        navigate('/');
        return;
    }

    this.state = {loadPanelVisible: false, showLoading: true, paymentSuccess: false, allowPayment: this.allowPayment()};    

  }

  willBeExpiredInMonths(noOfMonths) {
    let validDate =  new Date(window.localStorage.getItem("validDate"));
    let today = new Date();
    validDate.setMonth(validDate.getMonth() - noOfMonths);
    return validDate <= today;

  }
  allowPayment() {
    if (this.currRole === "advance")
        return (this.serviceCode === "sailingnote_mth_exist" || (this.serviceCode === "full" && this.willBeExpiredInMonths(2)));
    if (this.currRole === "general") 
        return (this.serviceCode === "sailingnote_mth_exist" || (this.serviceCode === "sailingnote" && this.willBeExpiredInMonths(2)));
    if (this.isTrialOrExpired && this.serviceCode === "sailingnote_mth_exist")
        return false;
    return true;
  }

  render() {
    const { showLoading } = this.state;
    if (!this.price)
        return <></>
    return (
      <Box bg={colors.primary}>
      <Box
      width={[1, 1, 1 / 2]}
      m={['3.5rem 0 3rem 0', '3.5rem 0 3rem 0', '3.5rem auto 20rem auto']}
      px={[3, 3, 0]}
      color={colors.accent}
      >
        <h1>Purchase</h1>
        {this.state.paymentSuccess &&
        <>
            <h2>Confirmation</h2>
            <p>Thank you for your support. Should you have any questions regarding your purchase, please email to <a href="mailto:info@linerlytica.com">info@linerlytica.com</a></p>
            {(this.serviceCode === 'sailingnote' || this.serviceCode === 'full') && <>
            <p>Please <Link style={{color: colors.secondary}} to="/app/login">login</Link> again to have access to your subscribed services.</p>
            <Logout redirectUrl={'NONE'} />
            </>}
        </>}
        {!this.state.allowPayment && <p>You have already subscribed to our service or you select the wrong service to subscribe.</p>}
        {!this.state.paymentSuccess && this.state.allowPayment && !this.state.error && <>
        <p>Service to subscribe: <span style={{color: colors.secondary}}>{this.service}</span><br/>
        Price: USD {this.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} / Year<br/>
        Payment options:
        </p>
        
        <Flex wrap={['wrap', 'nowrap','nowrap']}>
            <Box width={'150px'}>
                <span>By PayPal</span>
            </Box>
            <Box width={[1,1,2/3]}>
            {showLoading ? <span>Loading Button...</span> : null}

            <PayPalButton
                createOrder={(data, actions) => {
                    return actions.order.create({
                    purchase_units: [{
                        amount: {
                        currency_code: "USD",
                        value: this.price
                        },
                        description: this.service
                    }],
                    // application_context: {
                    //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                    // }
                    });
                }}            
                shippingPreference="NO_SHIPPING"
                onCreateOrder={() => {this.setState({loadPanelVisible: true});}}
                onSuccess={(details, data) => {
                    this.setState({loadPanelVisible: true});
                    let postApiUrl = `${process.env.GATSBY_USER_API_URL}/paymentCompleted?option=paypal&serviceCode=${this.props.option}&orderId=${data.orderID}`;    
                    var request = new XMLHttpRequest();
                    request.callObj = this;
                    
                    request.open('GET', postApiUrl,true);
                    request.setRequestHeader('Content-Type','application/json; charset=utf-8');
                    request.setRequestHeader('Authorization',`Bearer ${isBrowser()?window.localStorage.getItem("userIdToken"):''}`);
                    request.onreadystatechange = function (reposta) {
                        
                        if (this.readyState === 4) {
                            let body = JSON.parse(this.responseText);
                            if (body.status === 'completed')
                                this.callObj.setState({loadPanelVisible: false, paymentSuccess: true});
                            else
                                this.callObj.setState({loadPanelVisible: false, error: 'Internal Error. Please contact the system admin.'});

                        }
                    };
                    request.send();                    
                }}
                onError={(err) => {console.log(err); this.setState({loadPanelVisible: false, error: err});}}
                onCancel={(data) => {console.log(data);this.setState({loadPanelVisible: false});}}
                onButtonReady={() => this.setState({ showLoading: false })}
                options={{
                    clientId: process.env.GATSBY_PAYPAL_CLIENT_ID,
                    disableFunding: 'card',
                                    }}
            />    
            </Box>
        </Flex>
        <Flex wrap={['wrap', 'nowrap','nowrap']}>
            <Box width={'150px'}>
                <span>By Bank Transfer</span>
            </Box>
            <Box>
                <span>
                Pay to: OCBC Bank, Singapore <br/>
SWIFT address: OCBCSGSG  <br/>
Bank Code: 7339<br/>
Account No.: 601-449390-201<br/>
Beneficiary name: Liner Analytics Pte. Ltd.<br/>
Please Email Transfer Confirmation to: <a href={'mailto:info@linerlytica.com'}>info@linerlytica.com</a><br/>

                </span>
            </Box>
        </Flex>        
        </>}
        
          
        {this.state.error && <p>You got an error in your transaction: {this.state.error.message}</p>}
        <LoadPanel visible={this.state.loadPanelVisible}/>
        </Box>    
        </Box>
      );
    }
  
}


export default Payment
