import ODataStore from 'devextreme/data/odata/store';
import {oDataStoreDefaultSetting} from '../../oDataStoreDefaultSetting';
import { Box } from '../../../Layout';
import React from 'react';
import { getFirebaseIdToken } from "../../../../utils/auth";
import PivotGrid, { FieldChooser} from 'devextreme-react/pivot-grid';
import Chart, {AdaptiveLayout, CommonSeriesSettings, Size} from 'devextreme-react/chart';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

const dataSource = new PivotGridDataSource({
  fields: [
   // {
   // caption: 'Trade',
   // width: 120,
   // dataField: 'trade',
   // area: 'row'
    //sortBySummaryField: 'Total'
  // }, 
  {
    dataField: 'trade',
    dataType: 'string',
    area: 'row'
  }, {
    dataField: 'depWeek',
    dataType: 'number',
    area: 'column'
  }, {
    caption: 'Total TEU',
    dataField: 'teu',
    dataType: 'number',
    summaryType: 'sum',
    format: 'fixedPoint',
    area: 'data'
  }],
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/CapacityPivot`,
    ...oDataStoreDefaultSetting
  }) 
});

class capacityPivot extends React.Component {
    constructor(props) {
      super(props);
  
      this.setPivot=(ref) => {
        if (ref != null)
          this._pivotGrid = ref.instance;
      };

      this.setChart = (ref) => { 
        if (ref != null)
          this._chart = ref.instance;
      }; 

      dataSource.filter('trade','=','Grand-Total');    

    }

    componentDidMount() {
      this._pivotGrid.bindChart(this._chart, {
        dataFieldsDisplayMode: 'splitPanes',
        alternateDataFields: false
      });

      setTimeout(function(){
        
      })
    }

    load(filter) {
      getFirebaseIdToken(this.props.firebase, () =>  {
        dataSource.filter(filter);
        dataSource.reload();
      });

    }

    resetResult() {
      dataSource.filter('trade','=', 'Grand-Total'); 
    }

    render() {
      return (
        <React.Fragment>
          <Box m={'0 0 20px 0'}>
          <Chart ref={this.setChart}>
            <Size height={350} />
            <CommonSeriesSettings type ="bar" />
            <AdaptiveLayout width={450}/>
          </Chart>
          </Box>
          <PivotGrid
           id="pivotgrid"
           dataSource={dataSource}
           allowSortingBySummary={true}
           allowFiltering={true}
           showBorders={true}
           showColumnTotals={false}
           showColumnGrandTotals={false}
           showRowTotals={false}
           showRowGrandTotals={false}
           ref={this.setPivot}
           >
             <FieldChooser enabled={true} height={400} />
           </PivotGrid>

          <text><br></br></text>
              
        </React.Fragment>
      );
    }
  }

  export default capacityPivot;