import { Box, Flex } from '../../../Layout';
import colors from '../../../../utils/colors';
import React from 'react';
import { Popup } from 'devextreme-react/popup';
import {Button, ScrollView} from 'devextreme-react';
import DataGrid, { Column, Grouping, Pager, Paging } from 'devextreme-react/data-grid';
import { svcFormItems, deletionFormItems, fleetFormItems, operatingFormItems } from './FleetParticularFormItems'
import { 
  fleetRecordDataSource, 
  FleetHistoryDataSource,
  svcRecordDataSource,
  fleetFixturesByShipDataSource } from '../commonDataSource'
import RecordFormBase from '../recordFormBase';
import ServiceDetail from '../Service/ServiceDetail';


var isDeletedShip = null;

class FleetParticularForm extends React.Component {
  constructor(props) {

    super(props);

    this.state = {popupVisible: null, svcPopupVisible: null};
    
    //console.log(props.svcFilter);
    //console.log(props.deletedShip);

    this.setFixtureGrid = (ref) => {
      if (ref != null) 
          this.fixtureGrid = ref.instance;
    };
    this.setHistoryGrid = (ref) => {
      if (ref != null) 
          this.historyGrid = ref.instance;
    };

    this.colCount = 4;
    
    if (props.imoNo)
      this.initialFilter = ['imoNo','=',props.imoNo];   
    else
      this.initialFilter = props.initialFilter;       
//    this.svcFilter = props.svcFilter; 

    this.showPopup = this.showPopup.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    this.showSvcPopup = this.showSvcPopup.bind(this);
    this.hideSvcPopup = this.hideSvcPopup.bind(this);

    FleetHistoryDataSource.filter(this.initialFilter);
    fleetFixturesByShipDataSource.filter(this.initialFilter);
  }

  showPopup() {    
    this.setState({
      popupVisible: true
    });
  }

  hidePopup() {    
    this.setState({
      popupVisible: null
    });
  }

  showSvcPopup() {    
    this.setState({
      svcPopupVisible: true
    });
  }

  hideSvcPopup() {    
    this.setState({
      svcPopupVisible: null
    });
  }

  render() {
    isDeletedShip=this.props.deletedShip;

    return (
      <React.Fragment>
          <Flex wrap={['wrap', 'nowrap','nowrap']}>
          <Box width={'100vw'}>
          <Flex wrap={['nowrap']} style={{verticalAlign: 'bottom'}} >
            {!this.props.imoNo && <Button style={{marginRight: 10}} icon="back" hint="Back" onClick={this.props.returnCallback} height='30'/>}
            <h3 style={{marginTop: 5}}>Vessel Particulars</h3>
          </Flex>          
            <RecordFormBase
              recordFormItems={fleetFormItems}
              recordFormDataSource={fleetRecordDataSource}
              colCount={this.colCount}
              initialFilter={this.initialFilter}
              firebase={this.props.firebase}/>

          {!isDeletedShip &&
          <Flex wrap={['wrap', 'nowrap','nowrap']}>
          <Box width={[1,'35%','35%']}>
          <h3>Vessel History</h3>  
            <DataGrid
              dataSource={FleetHistoryDataSource}
              showBorders={true}
              ref={this.setHistoryGrid}
              selection={{mode: 'single'}}
              >
              <Grouping autoExpandAll={false} />
              <Column dataField='category' width={'120'} dataType={'string'} groupIndex={0} />
              <Column dataField='detail' width={'300'} dataType={'string'} />
              <Column dataField='historyDate' alignment={'left'} caption={'Date'}  width={'150'}  dataType={'string'} sortOrder={'desc'} />
            </DataGrid>
          </Box>
          <Box width={[1,'65%','65%']}>
          <Flex wrap='nowrap'>
          <Box width='50%'>
          <h3>Operating Detail</h3>
          </Box>
          <Box width='50%' textAlign={['right','bottom']} >
          <Button
              width={100}
              height={28}
              text="Charter Detail"
              icon="chart"
              onClick={this.showPopup}
              id={`charterDetail`}
            />
          </Box>          
          </Flex>
            <RecordFormBase
              recordFormItems={operatingFormItems}
              recordFormDataSource={fleetRecordDataSource}
              colCount={this.colCount}
              initialFilter={this.initialFilter}
              firebase={this.props.firebase}/>  
            <Flex wrap='nowrap'>
            <Box width='50%'>
            <h3>Service Detail</h3>
            </Box>
            <Box width='50%' textAlign='right'>
            <Button
                width={100}
                height={28}
                text="Service Detail"
                icon="globe"
                onClick={this.showSvcPopup}                
                id={`serviceDetail`}
              />
            </Box>
            </Flex>
            <RecordFormBase              
              recordFormItems={svcFormItems}
              recordFormDataSource={svcRecordDataSource}
              colCount={this.colCount}
              initialFilter={this.props.svcFilter}
              firebase={this.props.firebase}/>  
          </Box>
          </Flex>
          }

          {isDeletedShip &&
          <Flex wrap={['wrap', 'nowrap','nowrap']}>
          <Box width={[1,'35%','35%']}>
          <h3>Vessel History</h3>  
            <DataGrid
              dataSource={FleetHistoryDataSource}
              showBorders={true}
              selection={{mode: 'single'}}
              >
              <Grouping autoExpandAll={false} />
              <Column dataField='category' width={'120'} dataType={'string'} groupIndex={0} />
              <Column dataField='detail' width={'300'} dataType={'string'} />
              <Column dataField='historyDate' alignment={'left'} caption={'Date'}  width={'150'}  dataType={'string'} sortOrder={'desc'} />
            </DataGrid>
          </Box>
          <Box width={[1,'65%','65%']}>
            <h3>Deletion Detail</h3>
            <RecordFormBase
              recordFormItems={deletionFormItems}
              recordFormDataSource={fleetRecordDataSource}
              colCount={this.colCount}
              initialFilter={this.initialFilter}
              firebase={this.props.firebase}/>                      
          </Box>
          </Flex>}


        <Popup
          visible={this.state.popupVisible}
          onHiding={this.hidePopup}
          dragEnabled={false}
          ref={this.setFixturePopup}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Fixture Detail" 
          width={'calc(100vw * 0.8)'}
          height={'calc(100vh * 0.8)'}
        >
          {!isDeletedShip && 
          <DataGrid
            elementAttr={{
              class: "myClass"
            }}
            dataSource={fleetFixturesByShipDataSource}
            showBorders={true}
            selection={{mode: 'single'}}
            ref={this.setFixtureGrid}
            columnWidth={200}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={false}
            wordWrapEnabled={false}
            rowAlternationEnabled={false}
              >
            <Paging defaultPageSize={15} />
            <Pager
              showInfo={true} />     

            <Column dataField='dateReported'
              dataType={'date'}
              width={90}
              format={'MMM-yyyy'}
              defaultSortOrder={'desc'}
            />

            <Column dataField='vesselName'
              dataType={'string'}
              width={250}
            />

            <Column dataField='charterer'
              dataType={'string'}
              width={250}
            />

            <Column dataField='estOpenDateEarliest'
              dataType={'date'}
              format={'MMM-yyyy'}
              width={100}
            />

            <Column dataField='periodInMonth'
              caption={'Period'}
              dataType={'string'}
              width={90}
            />

            <Column dataField='currency'
              dataType={'string'}
              width={70}
            />

            <Column dataField='rate'
              dataType={'string'}
              caption={'rate'}
              width={80}
            />            
          </DataGrid>
          }
        </Popup>

{//RayIp 25-Jul (L) z-index of mouse-over on the Pie Chart
}
        <Popup
          visible={this.state.svcPopupVisible}
          onHiding={this.hideSvcPopup}
          dragEnabled={false}
          ref={this.setSvcPopup}
          closeOnOutsideClick={true}
          showTitle={false}          
          width={'calc(100vw * 0.85)'}

        >
          {!isDeletedShip && 
          <ScrollView width={'100%'} height={'100%'}>
          
            <ServiceDetail 
              firebase={this.props.firebase}
              initialFilter={this.props.svcFilter}
              showFleet={false}
              returnCallback={this.hideSvcPopup}
            />
          
          </ScrollView>
          }
        </Popup>
        </Box>
        </Flex>
      </React.Fragment>
    );
  }
}


class FleetParticularFormQuery extends React.Component {
  constructor(props) {

    super(props);

    this.state = {deletedShip: null, svcFilter: null};
    if (props.imoNo)
      this.initialFilter = ['imoNo','=',props.imoNo];   
    else
      this.initialFilter = props.initialFilter;   
  }

  componentDidMount() {
    var delStatus = null;
    var thisObj = this;
    var lsCodeFilter = null;
    fleetRecordDataSource.filter(this.initialFilter);
    fleetRecordDataSource.load().then(
      (data) => {        
          delStatus = data[0].shipStatus === 'X';
          if (data[0].lsCode){
            lsCodeFilter = ['lsCode','=',data[0].lsCode];
          } else {
            lsCodeFilter = ['lsCode','=','X'];
          }
          //console.log(data[0]);
          thisObj.setState({svcFilter: lsCodeFilter, deletedShip: delStatus});
        },
      (error) => {
          console.log(error);
        }
    );    
  }
  render() {
    if (this.props.imoNo) 
    return (
      <Box bg={colors.primary}>
        
        <Box
            width={[1, 1, 1]}
            m={['2.7rem 0 0 0', '2.7rem 0 0 0', '2.7rem auto 0 auto']}
            px={[0, 0, 0]}
            color={colors.secondary}
            minHeight={'500px'}
        >

            <Box m={'20px 40px 20px 40px'}>
            {this.state.deletedShip !== null && <FleetParticularForm deletedShip={this.state.deletedShip} svcFilter={this.state.svcFilter} {...this.props} />}
            </Box>       

        </Box>
      </Box>
    );
    else
      return (
      <>
      {this.state.deletedShip !== null && <FleetParticularForm deletedShip={this.state.deletedShip} svcFilter={this.state.svcFilter} {...this.props} />}
      </>
    );
  }
}

export default FleetParticularFormQuery;