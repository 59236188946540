import React from "react"
import { Box } from "../../components/Layout"
import { TextBox } from 'devextreme-react';
import { Button } from 'devextreme-react';
import { LoadPanel } from 'devextreme-react/load-panel';
import colors from '../../utils/colors';
import { useState} from "react"
import { isLoggedIn } from "../../utils/auth"
import { useFirebase } from "gatsby-plugin-firebase"
import { navigate } from '@reach/router';

const INITIAL_STATE = {
  email: '',
  error: null,
};

class ForgetPasswordBase extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {loadPanelVisible: false, ...INITIAL_STATE };
    this.onSendClick = this.onSendClick.bind(this);
  }
  componentDidMount() {
    this.emailField.focus();
  }
  onSendClick() 
  {
    const { email } = this.state;

    if (!email) {
      this.setState({error: {message: 'Please enter Login Email'}});
      return;
    }
    this.setState({loadPanelVisible: true});
    this.props.firebase.auth()
      .sendPasswordResetEmail(email)
      .then((result) => {
        this.setState({ loadPanelVisible: false, email: null, error: {message: 'Email has been sent to your mail box which include the instruction to recover the password.'} });
      })
      .catch(error => {
        this.setState({ loadPanelVisible: false, error });
      });
  }

  render() {
    return (

      <Box bg={colors.primary}>
      <Box
      width={[1, 1, 1 / 2]}
      m={['3.5rem 0 3rem 0', '3.5rem 0 3rem 0', '3.5rem auto 20rem auto']}
      px={[3, 3, 0]}
      color={colors.secondary}
      >
        <h2>Recover Password</h2>
        <Box
            width={[1, "400px", "400px"]} 
              >
          <p>Input your registered email to reset your password:</p>
          <div className="dx-field">
            <div className="dx-field-label">Email Address:</div>
            <div className="dx-field-value">
              <TextBox placeholder="Your Login Email" 
              value={this.state.email}
              onValueChanged={(data)=> {this.setState({email: data.value});}}
              ref={(ref)=> {if (ref) this.emailField = ref.instance;}}
              onEnterKey={this.onSendClick}/>
            </div>
          </div>
          <div style={{textAlign:'right'}}>
          <Button
          width={120}
          text="Recover"
          icon="pulldown"
          onClick={this.onSendClick}/>
          </div>
        </Box> 
        {this.state.error && <p>{this.state.error.message}</p>}  
        <LoadPanel visible={this.state.loadPanelVisible}/>  
      </Box>  
      </Box>
    );
  }
}

const ForgetPassword = () => {
  const [firebase, setFirebase] = useState();

  useFirebase(firebase => {
    setFirebase(firebase);
  }, [])

  if (isLoggedIn()) {
    navigate(`/`)
  }

  return (
    
    <ForgetPasswordBase firebase={firebase} />

  );

}

export default ForgetPassword
