import React, { Component } from 'react';
import { Button } from 'devextreme-react';
import { Box } from '../Layout';
import { setUser } from "../../utils/auth"
import Form from 'devextreme-react/form';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Popup } from 'devextreme-react/popup';
import colors from '../../utils/colors';
import { navigate } from "gatsby"

class Profile extends Component {
  constructor(props) {
    super(props);
    this.setForm = (ref) => {
      if (ref != null)
          this.changePasswordForm = ref.instance;
    };  
    this.state = {showChangePassword: false,
      loadPanelVisible: false,
      emailVerified: false,
      changePwErr: '',
      allowRenew: this.allowRenew(2),
      formData: {}
    };
    let thisObj = this;
    this.props.firebase.auth().onAuthStateChanged(function(user) {
      //console.log('onAuthStateChanged');
      if (user) {
//        console.log(user);
        thisObj.currentUser = user;
        user.getIdTokenResult(false).then(
          function(idTokenResult) {
//            console.log(idTokenResult);
            thisObj.setState({emailVerified: user.emailVerified,formData: {email: user.email, salutation:idTokenResult.claims.salutation, firstName: idTokenResult.claims.firstName, lastName: idTokenResult.claims.lastName, company: idTokenResult.claims.company, country: idTokenResult.claims.country, validDate: idTokenResult.claims.validDate}});
          }
        ).catch(function(error) {
          console.log(error); // Nothing
        });
        
      } else {
        thisObj.currentUser = null;
      }
    });

    this.formItems = [
      {                    
        dataField: 'salutation',
        label: {text: 'Salutation'},
      },
      {                    
        dataField: 'firstName',
        label: {text: 'First Name'},
      },
      {                    
        dataField: 'lastName',
        label: {text: 'Last Name'},
      },
      {                    
        dataField: 'company',
        label: {text: 'Company'},
      },
      {                    
        dataField: 'country',
        label: {text: 'Country'},
      },
      {                    
        dataField: 'email',
        label: {text: 'Email'},
      },
      {                    
        dataField: 'validDate',
        label: {text: 'Valid Until'},
        editorType: 'dxDateBox'
      }
    ];

    this.changePasswordFormItems = [
      {                    
        dataField: 'oldPassword',
        validationRules:[{type:"required", message:"It is required"}],
        editorOptions: {
          mode: "password"

        },
        label: {text: 'Old Password'},
      },
      {                    
        dataField: 'password',
        validationRules:[{type:"required", message:"It is required"}],
        editorOptions: {
          mode: "password"

        },
        label: {text: 'New Password'},
      },
      {                    
        dataField: 'passwordReenter',
        validationRules:[{type:"required", message:"It is required"}, {type:"compare", comparisonTarget: () => {return this.changePasswordForm.option('formData').password;}, message: "Password and Confirm Password do not match"}],
        editorOptions: {
          mode: "password"

        },
        label: {text: 'Confirm New Password'},
      }
    ];    

    this.onChangePasswordClick = this.onChangePasswordClick.bind(this);
    this.onOkClick = this.onOkClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.hideChangePassword = this.hideChangePassword.bind(this);
    this.shownChangePassword = this.shownChangePassword.bind(this);
    this.onResendVerifyEmailClick = this.onResendVerifyEmailClick.bind(this);
    this.onRenewSubscriptionClick = this.onRenewSubscriptionClick.bind(this);
  }


  allowRenew(noOfMonths) {
    let currRole = window.localStorage.getItem("role");
    if (currRole !== 'advance' && currRole !== 'general')
      return false;
    let validDate =  new Date(window.localStorage.getItem("validDate"));
    let today = new Date();
    validDate.setMonth(validDate.getMonth() - noOfMonths);
    return (validDate <= today);

  }

  onRenewSubscriptionClick() {
    let currRole = window.localStorage.getItem("role");
    if (currRole === 'advance')
      navigate('/app/payment/full');
    else if (currRole === 'general')
      navigate('/app/payment/sailingnote');
  }

  onResendVerifyEmailClick() {
    const thisObj = this;
    this.setState({loadPanelVisible: true});
    this.currentUser.sendEmailVerification()
    .then(function() {
      thisObj.setState({loadPanelVisible: false, errorMsg: "Verification Email is just resent."});
    })
    .catch(function(error) {
      thisObj.setState({ loadPanelVisible: false, errorMsg: error.message });
    });
  }

  shownChangePassword() {
    this.changePasswordForm.focus();
  }
  componentDidMount() {

  }
  onChangePasswordClick(){
    this.setState({showChangePassword: true});
  }
  hideChangePassword() {
    this.setState({showChangePassword: false});
  }
  onCancelClick() {
    this.setState({showChangePassword: false});
  }
  onOkClick() {
    if (!this.changePasswordForm.validate().isValid)
      return;

    this.setState({loadPanelVisible: true});
    var thisObj = this;
    this.props.firebase.auth()
    .signInWithEmailAndPassword(this.currentUser.email, this.changePasswordForm.option('formData').oldPassword)
    .then((result) => {
      setUser(result.user);
      result.user.updatePassword(thisObj.changePasswordForm.option('formData').password).then(function() {
        thisObj.setState({ loadPanelVisible: false, showChangePassword: false, errorMsg: 'Password is changed!' });
      }).catch(function(error) {
        thisObj.setState({ loadPanelVisible: false, changePwErr: error.message });
      });
    })
    .catch(error => {
      if (error.code === 'auth/wrong-password' ||  error.code === 'auth/user-not-found')
        thisObj.setState({loadPanelVisible: false, changePwErr: 'Invalid Old Password!'});
      else
        thisObj.setState({ loadPanelVisible: false, changePwErr: error.message });
    });
 
  }

  render() {
      return (
      <>
      <Box bg={colors.primary}>
      <Box
      width={[1, 1, 1 / 2]}
      m={['3.5rem 0 3rem 0', '3.5rem 0 3rem 0', '3.5rem auto 20rem auto']}
      px={[3, 3, 0]}
      color={colors.secondary}
      >
      <h1>User Profile</h1>
      <Box width={[1,'400px','400px']}>
      <Form
        colCount={1}
        items={this.formItems}
        readOnly={true}
        formData={this.state.formData}>
      </Form>      
      </Box>
      {this.state.emailVerified? !this.state.formData.validDate && <p>Note: Your account is NOT activated. You have no access privilege to our paid services.</p>
      :<p>Note: Your email is NOT verified yet.  Please verify it by following the instructions sent to your mail box.</p>}
      <Box m={'10px 0 10px 0'}>
      <Button
      height={40}
      text="Change Password"
      type="default"
      stylingMode="contained"
      onClick={this.onChangePasswordClick}
      />
      <Button
      height={40}
      text="Resend Verification Email"
      type="default"
      stylingMode="contained"
      visible = {!this.state.emailVerified}
      onClick={this.onResendVerifyEmailClick}
      style={{marginLeft:'20px'}}
      />    
      <Button
      height={40}
      text="Renew Subscription"
      type="default"
      stylingMode="contained"
      visible = {this.state.allowRenew}
      onClick={this.onRenewSubscriptionClick}
      style={{marginLeft:'20px'}}
      />   
      </Box>
      {this.state.errorMsg && <p>{this.state.errorMsg}</p>}
      

      </Box>
      </Box>

      <LoadPanel visible={this.state.loadPanelVisible}/>
      <Popup
          visible={this.state.showChangePassword}
          onHiding={this.hideChangePassword}
          onShown={this.shownChangePassword}
          dragEnabled={false}
          showTitle={true}
          title={"Change Password"}
          width={'400px'}
          height={'300px'}
        >
        <Box>
        <Form
          colCount={1}
          items={this.changePasswordFormItems}
          ref={this.setForm}>
        </Form>          
        <Box m={'10px 0 10px 0'}>
        <Button
          width={120}
          height={50}
          text="Ok"
          type="default"
          stylingMode="contained"
          onClick={this.onOkClick}
          />
        <Button
          width={120}
          height={50}
          text="Cancel"
          type="default"
          stylingMode="contained"
          onClick={this.onCancelClick}
          style={{marginLeft:'20px'}}
          />
        </Box>
        {this.state.changePwErr && <p>{this.state.changePwErr}</p>}
        </Box>
      </Popup>      
      </>
    );
  };
};



export default Profile;