export const serviceDetailItems = [
  {
    colSpan: 4,
    itemType: 'group',
    colCount: 1,
    items:[
      {
        dataField: 'serviceName',
        editorType: 'dxTextBox'
      },  
      {
        dataField: 'vesselProviders',
        label:{
          text: 'Operators'
        }, 
        editorType: 'dxTextBox'
      },  
      {
        dataField: 'slotters',
        editorType: 'dxTextBox'
      },              
    ]
  },
  {
    colSpan: 1,
    itemType: 'group',
    colCount: 1,
    items:[
      {
        dataField: 'teuWeekly',
        editorType: 'dxTextBox'
      },  
      {
        dataField: 'duration',
        editorType: 'dxNumberBox',
        editorOptions:{
          format: { type: 'fixedPoint', precision: 0}
        },
        label: {
          text: 'Length of Rotations'
        }
      },  
      {
        dataField: 'frequency',
        editorType: 'dxNumberBox',
        editorOptions:{
          format: { type: 'fixedPoint', precision: 0}
        }
      },              
    ]
  },


  {
    colSpan: 5,
    itemType: 'group',
    colCount: 1,
    items:[
      {
        dataField: 'ships',
        editorType: 'dxTextArea'
      },      
      {
        dataField: 'rotations',
        editorType: 'dxTextArea'
      },  
      {
        dataField: 'serviceNote',
        editorType: 'dxTextArea'
      }
    ]      
  }
];
  
