import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { isBrowser, isLoggedIn, logout, hasRole, logRouteUsage } from "../utils/auth"
import { useFirebase } from "gatsby-plugin-firebase"
import { useState} from "react"


const PrivateRoute = ({ component: Component, location, requiredRoles, checkRouteUsage, withMargin, ...rest }) => {
  const [firebase, setFirebase] = useState();
  useFirebase(firebase => {
    setFirebase(firebase);
  }, []);

  if (!firebase || typeof firebase === 'undefined')   
    return null;

  var returnUrl = isBrowser()?encodeURI(window.location.pathname + window.location.search):null;
  if (!isLoggedIn() && location.pathname !== `/app/login`) {
    // If we’re not logged in, redirect to the home page.
    
    navigate(`/app/login?returnUrl=${returnUrl}`)
    return null;
  }    

  if (requiredRoles && !hasRole(requiredRoles)) {
    logout(firebase, ()=> {navigate(`/app/login?returnUrl=${returnUrl}`);});
    
    return null;
  }
  
  //log route usage
  if (checkRouteUsage) {

    const route = isBrowser()?encodeURI(window.location.pathname): null;
    logRouteUsage(route);
  }
  if (withMargin)
    return (
      
        <Component key={location.pathname} firebase={firebase} {...rest} />
      
    );
  else
    return <Component key={location.pathname} firebase={firebase} {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute
