import React from 'react';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { getFirebaseIdToken } from "../../../../utils/auth";
import { Box, Flex } from '../../../Layout';
import { OrderbookDeliveryDataSource, OrderbookOrderDataSource } from '../commonDataSource';
import FleetParticular from '../Fleets/FleetParticularForm';

class OrderbookGrid extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {selectedRowsData: null, visibleDetail: null};
      this.setGrid = (ref) => {
        if (ref != null)
            this.resultGrid = ref.instance;
      };
      this.setOdaGrid = (ref) => {
        if (ref != null)
            this.odaGrid = ref.instance;
      };

      this.onSelectionChanged = this.onSelectionChanged.bind(this);
      this.showParticulars = this.showParticulars.bind(this);
      this.backFromSubScreen = this.backFromSubScreen.bind(this);
      this.load = this.load.bind(this);
      this.resetResult = this.resetResult.bind(this);
    }

    componentDidMount() {
      if (this.props.initialFilter)
        OrderbookDeliveryDataSource.filter();
        OrderbookOrderDataSource.filter();
      }
  
    resetResult() {
      getFirebaseIdToken(this.props.firebase,() => {
        OrderbookDeliveryDataSource.filter('vesselId','=', 0);
        OrderbookOrderDataSource.filter('vesselId','=', 0);
        this.resultGrid.refresh();
      });
    }
  
    load(filter) {            
      getFirebaseIdToken(this.props.firebase,() => {
        OrderbookDeliveryDataSource.filter(filter);
        OrderbookOrderDataSource.filter(filter);
        this.resultGrid.refresh();
        this.odaGrid.refresh();
      });
      this.resultGrid.clearSelection();
      this.odaGrid.clearSelection();
    }
  
    onSelectionChanged(e) {
      var data = e.selectedRowsData[0];
      if (data) {
          this.initialFilter = ['vesselId','=',data.vesselId];
          this.props.enquiryBase.setCriteriaVisible(false);
          this.setState({visibleDetail: true});
      }
      else {
      }
    }

    showParticulars() {

    }

    backFromSubScreen() {
      this.props.enquiryBase.setCriteriaVisible(true); 
      this.setState({visibleDetail: null})
    }

    render() {
      return (
        <React.Fragment>

        <Flex>
        <Box width={'100%'}>
        <div style={!this.state.visibleDetail?{}:{display: 'none'}}>
          <h3>Latest Deliveries</h3>
        </div>
          <DataGrid
            elementAttr={{
              class: "myClass"
            }}
            dataSource={OrderbookDeliveryDataSource}
            onSelectionChanged={this.onSelectionChanged}
            showBorders={true}
            selection={{mode: 'single'}}
            ref={this.setGrid}
            columnWidth={200}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={false}
            wordWrapEnabled={false}
            rowAlternationEnabled={false}
            visible={!this.state.visibleDetail}
            >
            <Paging defaultPageSize={8} />
            <Pager
              showInfo={true} />     

            <Column dataField='vesselName'
              dataType={'string'}
              width={200}
            />

            <Column dataField='typeOfShip'
              caption='Type'
              dataType={'string'}
              width={100}
            />

            <Column dataField='teu'
              dataType={'number'}
              format={{type:'fixedPoint',precision:0}}
              caption='TEU'
              width={80}
            />

            <Column dataField='dwt'
              dataType={'number'}
              format={{type:'fixedPoint',precision:0}}
              caption='DWT'
              width={90}
            />

            <Column dataField='shipyardName'
              dataType={'string'}
              width={200}
            />

            <Column dataField='price'
              dataType={'string'}
              width={80}
              caption={'Price $M'}
            />

            <Column dataField='deliveryDate'
              dataType={'date'}
              caption={'Dlvy Date'}
              format={'MMM-yyyy'}
              defaultSortOrder={'desc'}
              width={80}
            />

            <Column dataField='beneficialOwner'
              dataType={'string'}
              width={200}
            />

            <Column dataField='groupName'
              dataType={'string'}
              caption={'operator'}
              width={200}
            />
            
            <Column dataField='vesselId'
              dataType={'string'}
              width={90}
              visible={false}
            />

            <Column dataField='imoNo'
              dataType={'string'}
              width={90}
              visible={false}
            />
          </DataGrid>
        </Box>
        </Flex>
        <Flex>
        <Box width={'100%'}>
        <div style={!this.state.visibleDetail?{}:{display: 'none'}}>
          <h3>Latest Orders</h3>
        </div>
          <DataGrid
            elementAttr={{
              class: "myClass"
            }}
            dataSource={OrderbookOrderDataSource}
            showBorders={true}
            selection={{mode: 'single'}}
            ref={this.setOdaGrid}
            onSelectionChanged={this.onSelectionChanged}
            columnWidth={200}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={false}
            wordWrapEnabled={false}
            rowAlternationEnabled={false}
            visible={!this.state.visibleDetail}
            >
            <Paging defaultPageSize={5} />
            <Pager
              showInfo={true} />     

            <Column dataField='vesselName'
              dataType={'string'}
              width={200}
            />

            <Column dataField='typeOfShip'
              caption='Type'
              dataType={'string'}
              width={70}
            />

            <Column dataField='teu'
              dataType={'number'}
              format={{type:'fixedPoint',precision:0}}
              caption='TEU'
              width={80}
            />

            <Column dataField='dwt'
              dataType={'number'}
              format={{type:'fixedPoint',precision:0}}
              caption='DWT'
              width={90}
            />

            <Column dataField='shipyardName'
              dataType={'string'}
              width={200}
            />

            <Column dataField='price'
              dataType={'string'}
              width={80}
              caption={'Price $M'}
            />

            <Column dataField='deliveryDate'
              dataType='date'
              width={80}
              caption={'Dlvy Date'}
              format={'MMM-yyyy'}
              defaultSortOrder={'desc'}
            />

            <Column dataField='orderDate'
              dataType={'date'}
              width={250}
              defaultSortOrder={'desc'}
              format={'MMM-yyyy'}
              visible={false}
            />

            <Column dataField='beneficialOwner'
              dataType={'string'}
              width={200}
            />

            <Column dataField='groupName'
              dataType={'string'}
              caption={'operator'}
              width={200}
            />
            
            <Column dataField='vesselId'
              dataType={'string'}
              width={90}
              visible={false}
            />
          </DataGrid>
        </Box>
        </Flex>

        <Box>
        <div style={this.state.visibleDetail?{}:{display: 'none'}}>
            {this.state.visibleDetail?
            <>
            <FleetParticular
              firebase={this.props.firebase}
              initialFilter={this.initialFilter}
              returnCallback={this.backFromSubScreen}
              />
            </>:null            
            }
        </div>
        </Box>

        </React.Fragment>
      );
    }
  }

  export default OrderbookGrid;