import React from 'react';
import Form from 'devextreme-react/form';
import { getFirebaseIdToken } from "../../../utils/auth";


class RecordFormBase extends React.Component {
  constructor(props) {

    super(props);
    this.colCount = 2;
    if (props.colCount)
      this.colCount = props.colCount;
    this.setForm = (ref) => {
      if (ref != null)
          this.recordForm = ref.instance;
    };
  }
  componentDidMount() {
    if (this.props.initialFilter)
      this.load(this.props.initialFilter);
    else
      this.resetForm();
  }
  resetForm() {
    this.recordForm.option('formData', null);
  }


  load(filter) {
    this.props.recordFormDataSource.filter(filter);
    getFirebaseIdToken(this.props.firebase, (thisObj) =>{
      thisObj.props.recordFormDataSource.load().then(
          (data) => {
              this.recordForm.option('formData',data[0]);
            },
          (error) => {
              console.log(error);
            }
        );
    }, this);
  }

  render() {
    return (
      <React.Fragment>
          <Form
            colCount={this.colCount}
            items={this.props.recordFormItems}
            ref={this.setForm}
            readOnly={true}
            id={this.props.id}
            >
          </Form>
      </React.Fragment>
    );
  }
}

  export default RecordFormBase;