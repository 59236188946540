import React from "react"
import { navigate } from '@reach/router';
import { useState} from "react"
import { logout, isLoggedIn } from "../utils/auth"
import { useFirebase } from "gatsby-plugin-firebase"

const Logout = (props) => {
  const [firebase, setFirebase] = useState();

  useFirebase(firebase => {
    setFirebase(firebase);
  }, [])

  if (!isLoggedIn() && !props.redirectUrl) {
    navigate(`/`);
  }
  
  logout(firebase, () => {
    if (!props.redirectUrl)
      navigate(`/app/login`);
    else {
      if (props.redirectUrl !== 'NONE')
        navigate(props.redirectUrl);
    }
  });
  
  

  return (
    <div>
    </div>
  );

}

export default Logout
