import React from 'react';
import { getFirebaseIdToken } from "../../../../utils/auth";
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSetting} from '../../oDataStoreDefaultSetting';
import { Box } from '../../../Layout';
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Margin,
  Grid
} from 'devextreme-react/chart';

class SseChart extends React.Component {
  constructor(props) {
    super(props);
    

    this.sseDataSource = new DataSource({
      store: new ODataStore({
        url: `${process.env.GATSBY_ODATA_SERVER_URL}/Rates`,
        ...oDataStoreDefaultSetting
      })
    });      
    this.sseDataSource.filter(props.filter);
  }  
  render() {
    return (
      <Chart
        palette="Blue"
        dataSource={this.sseDataSource}>
        <CommonSeriesSettings
          argumentField="date"
        />

        <Series valueField={'idxValue'}  type="line" />

        <Margin bottom={10} />
        <ArgumentAxis
          valueMarginsEnabled={false}
          discreteAxisDivisionMode="crossLabels"
        >
        
        <Grid visible={true} />
        </ArgumentAxis>
        <Legend visible={false}/>
      </Chart>

    );  
  }
}

class sseDetail extends React.Component {
    constructor(props) {
      super(props);
      this.state = {sseResult: []} ;
      var thisObj = this;
      this.sseGroupDataSource = new DataSource({
        store: new ODataStore({
          url: `${process.env.GATSBY_ODATA_SERVER_URL}/SseGroups`,
          key: 'idx',
          keyType: 'string',
          onLoaded: (result) => {
            thisObj.setState({sseResult: result});
          },
          ...oDataStoreDefaultSetting
        })
      });      
      this.onSelectionChanged = this.onSelectionChanged.bind(this);
      this.createAllCharts = this.createAllCharts.bind(this);
    }

    createAllCharts(result) {
      
    }

    resetResult() {

    }
  
    load(filter) {
      getFirebaseIdToken(this.props.firebase, () => {
        this.sseGroupDataSource.sort({ selector: 'sseName', desc: false });
        this.sseGroupDataSource.filter(filter);
        this.sseGroupDataSource.reload();

      }
      );
    }
  
    onSelectionChanged(e) {
      var data = e.selectedRowsData[0];
      if (data) {
      }
      else {
      }
    }

    render() {
      return (
        <React.Fragment>
          <div className="flex-wrap">
          {this.state.sseResult.map((sse) => (

              <React.Fragment key={sse.idx}>
                
                <Box width={[1,1/3,1/5]} p={"0 25px 0 25px"}>
                  <h3>{sse.sseName}</h3>
                  <SseChart filter={[['date','>','2019-12-31'],'and',['sseName','=',sse.sseName],'and',['sseGroup','=',sse.sseGroup]]}></SseChart>
                </Box>
                
              </React.Fragment>
          ))}        
          </div>
        </React.Fragment>

      );
    }
  }

  export default sseDetail;