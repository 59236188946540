import React from 'react';
import OperatorFleetGrid from './operatorFleetGrid';
import {getFormItems} from './formItem';
import EnquiryBase from '../enquiryBase';

class OperatorsSummary extends React.Component {
    constructor(props) {
      super(props);
      this.formItemsSimple = getFormItems('simple');
      this.formItemsBasic = getFormItems('basic');
      this.formItemsAdvance = getFormItems('advance');

    }

    render() {
      return (
        <EnquiryBase 
        formItemsBasic={this.formItemsBasic}
        searchResultComponent={OperatorFleetGrid} 
        firebase={this.props.firebase} />
      );  
    }
  }

  export default OperatorsSummary;