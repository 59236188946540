import React from 'react';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Pager, Paging, Editing, Popup, Position, FilterRow, Lookup, RequiredRule, Export } from 'devextreme-react/data-grid';
import {oDataStoreDefaultSetting} from '../Enquiry/oDataStoreDefaultSetting';
import {Box} from "../Layout";
import colors from "../../utils/colors";
import { Button } from 'devextreme-react';
import { getFirebaseIdToken} from "../../utils/auth";
import { getUser } from "../../utils/auth"
import ProductSubscriber from './ProductSubscriber';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

const searchResultDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_ADMIN_SERVER_URL}/Users`,
      key: 'uid',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    })
});

const yesNoDS = ['Y','N'];
const salutationDS = ['Mr.', 'Mrs', 'Ms.'];
const roleDS = ['trial','general','advance','admin','expired'];

class User extends React.Component {
    constructor(props) {
      super(props);

      this.handleEditorPreparing = this.handleEditorPreparing.bind(this);
      this.onSyncDbRecClick = this.onSyncDbRecClick.bind(this);
      this.onApproveTrialClick = this.onApproveTrialClick.bind(this);
      this.onExporting = this.onExporting.bind(this);
      this.setGrid = (ref) => {
        if (ref != null)
            this.resultGrid = ref.instance;
      };
    }

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
  
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Users.xlsx');
        });
      });
      e.cancel = true;
    }

    componentDidMount() {
      this.resultGrid.refresh();
    }
  
    handleEditorPreparing(e) {
      e.editorOptions.readOnly = e.parentType === "dataRow" &&  
      e.dataField === "email" && !e.row.isNewRow; 
    }

    onApproveTrialClick(e) {
      var selectedRows = this.resultGrid.getSelectedRowsData();
      if (selectedRows.length <= 0) {
        alert('Please select at least one record!');
        return;
      }
      if (selectedRows.find((rec) => rec.disabled !== 'Y' || rec.role !== 'trial'))  {
        alert('some of the selected records are not Trial user pending approval!');
        return;
      }
      getFirebaseIdToken(this.props.firebase, ()=>{
        if(typeof XMLHttpRequest !== "undefined") {
          var request = new XMLHttpRequest();
          request.thisObj = this;
          let postApiUrl = `${process.env.GATSBY_USER_API_URL}/approveTrialUser`;    
          request.open('POST', postApiUrl,true);
          request.setRequestHeader('Content-Type','application/json; charset=utf-8');
          request.setRequestHeader('Authorization',`Bearer ${(typeof window !== 'undefined')?window.localStorage.getItem("userIdToken"):''}`);
          request.onreadystatechange = function (reposta) {
            if (this.readyState === 4) {
              this.thisObj.resultGrid.refresh();
              alert('Trail users are approved and emails are sent.');
            }
          };
          request.send(JSON.stringify(selectedRows));     
        }   
      });

    }
    onSyncDbRecClick(e) {
      getFirebaseIdToken(this.props.firebase, ()=>{


        let postApiUrl = `${process.env.GATSBY_USER_API_URL}/syncDbUserList`;    
        var request = new XMLHttpRequest();
        request.callObj = this;
        request.open('GET', postApiUrl,true);
        request.setRequestHeader('Content-Type','application/json; charset=utf-8');
        request.setRequestHeader('Authorization',`Bearer ${(typeof window !== 'undefined')?window.localStorage.getItem("userIdToken"):''}`);            
        request.onreadystatechange = function (reposta) {
            
            if (this.readyState === 4) {
              this.callObj.resultGrid.refresh();
              alert('Database records are sync with Firebase!');
            }
        };
        request.send(); 
      });    
    }

    render() {
      return (
        
      <Box bg={colors.primary}>
        
        <Box
            width={[1, 1, 1]}
            m={['3.5rem 0 1.5rem 0', '3.5rem 0 1.5rem 0', '3.5rem auto 1.5rem auto']}
            px={[0, 0, 0]}
            color={colors.secondary}
        >
          <h2>Linerlytica Users</h2>
        <DataGrid
          dataSource={searchResultDataSource}
          keyExpr="uid"
          showBorders={true}
          selection={{mode: 'multiple', allowSelectAll:false, showCheckBoxesMode:'always'}}
          ref={this.setGrid}
          onEditorPreparing={this.handleEditorPreparing}
          onExporting={this.onExporting}
        >
          <Paging defaultPageSize={15} />
          <FilterRow visible={true}/>
          <Pager
            showInfo={true} />        
          <Editing
            mode={"popup"}
            allowUpdating={true}
            allowAdding={true}>
            <Popup title="User Information" showTitle={true} width={700} height={525}>
              <Position my="center" at="center" of={window} />
            </Popup>

          </Editing>            
          <Column dataField='email'
          dataType={'string'}  sortIndex={0} sortOrder={"asc"}>
            <RequiredRule/>
          </Column>
          <Column dataField='emailVerified'
          dataType={'string'} caption={'Email Verified?'}>    
            <RequiredRule/>     
            <Lookup dataSource={yesNoDS} />  
          </Column>
          <Column dataField='salutation'
          dataType={'string'}>    
            <RequiredRule/>     
            <Lookup dataSource={salutationDS} />  
          </Column>          
          <Column dataField='firstName'
          dataType={'string'}>
            <RequiredRule/>
          </Column>
          <Column dataField='lastName'
          dataType={'string'}>
            <RequiredRule/>
          </Column>
          <Column dataField='company'
          dataType={'string'}>
            <RequiredRule/>
          </Column>
          <Column dataField='country'
          dataType={'string'}>
            <RequiredRule/>
          </Column>
          <Column dataField='emailSub'
          dataType={'string'} caption={'Subscribe Email?'}>
            <RequiredRule/>
            <Lookup dataSource={yesNoDS} /> 
          </Column>          
          <Column dataField='role'
          dataType={'string'}>
            <Lookup dataSource={roleDS} /> 
            <RequiredRule/>
          </Column>
          <Column dataField='disabled'
          dataType={'string'} caption={'Disabled?'}>
            <RequiredRule/>
            <Lookup dataSource={yesNoDS} /> 
          </Column>
          <Column dataField='validDate'
          dataType={'date'} caption={'Valid Until'} editorOptions={{dateSerializationFormat:'yyyy-MM-dd'}}
          >
          </Column>
          <Column dataField='creationTime'
          dataType={'date'} caption={'Created on'} formItem={{visible: false}}
          />
          <Column dataField='uid'
          dataType={'string'} visible={false} formItem={{visible: false}}
          />
          <Export enabled={true} />
        </DataGrid>
        <Button
        width={120}
        height={30}
        text="Sync DB Records"
        type="default"
        stylingMode="contained"
        onClick={this.onSyncDbRecClick}
        visible={getUser().email === 'rayhwip@gmail.com'}
        />
        <Button
        width={120}
        height={30}
        text="Approve Trial User and Send Email"
        type="default"
        stylingMode="contained"
        onClick={this.onApproveTrialClick}
        visible={getUser().email === 'rayhwip@gmail.com'}
        />
</Box>
<ProductSubscriber/>
</Box>

      );
    }
  }

  export default User;