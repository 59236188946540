import React from 'react';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, ColumnChooser, ColumnFixing, Pager, Paging, FilterRow } from 'devextreme-react/data-grid';
import {oDataStoreDefaultSetting} from '../../oDataStoreDefaultSetting';
import { getFirebaseIdToken } from "../../../../utils/auth";
import { Box } from '../../../Layout';


const vesselGridDataSource = new DataSource({
  store: new ODataStore({
    url: `${process.env.GATSBY_ODATA_SERVER_URL}/CapacityShipGrid`,
    ...oDataStoreDefaultSetting
  }),

});

class CapacityGrid extends React.Component {
    constructor(props) {
      super(props);
  
      
      var currDate = new Date();
      var currentWeekNos = [
        this.getWeekNoWithYear(this.addDays(currDate, -4 * 7)),
        this.getWeekNoWithYear(this.addDays(currDate, -3 * 7)),
        this.getWeekNoWithYear(this.addDays(currDate, -2 * 7)),
        this.getWeekNoWithYear(this.addDays(currDate, -1 * 7)),
        this.getWeekNoWithYear(currDate),
        this.getWeekNoWithYear(this.addDays(currDate, 1 * 7)),
        this.getWeekNoWithYear(this.addDays(currDate, 2 * 7)),
        this.getWeekNoWithYear(this.addDays(currDate, 3 * 7)),
        this.getWeekNoWithYear(this.addDays(currDate, 4 * 7))
      ] 

      this.state = {selectedRowsData: null, popupOption: null, currentWeekNos: currentWeekNos};

      this.setVesselGrid = (ref) => {
        if (ref !== null)
            this.resultVesselGrid = ref.instance;
      };
      this.setOperatorSummaryForm = (ref) => {
        if (ref !== null)
            this.operatorSummaryForm = ref;
      };
      this.setFleetSummaryForm = (ref) => {
        if (ref !== null)
            this.fleetSummaryForm = ref;
      };      
      //vesselGridDataSource.filter('batchLabel','=', 'Current');

      this.showContextMenu = this.showContextMenu.bind(this);
      this.contextMenuItemClick = this.contextMenuItemClick.bind(this);
      this.showPopup = this.showPopup.bind(this);
      this.hidePopup = this.hidePopup.bind(this);
    }

    showPopup(popupOption) {
      this.setState({popupOption: popupOption});
    }
    contextMenuItemClick(e) {
      if (!e.itemData.items) {
        this.showPopup(e.itemData.text);
      }
    }

    hidePopup() {
      this.setState({popupOption: null});
    }
    showContextMenu(e) {
      this.data1 = e.row.data.vesselName;
      this.data2 = e.row.data.imoNo;
    }
    resetResult() {
      //vesselGridDataSource.filter('batchLabel','=', 'Current');
      this.resultVesselGrid.refresh();
      if (this.operatorSummaryForm)
        this.operatorSummaryForm.resetForm();
      if (this.fleetSummaryForm)
        this.fleetSummaryForm.resetForm();
      this.setState({selectedOperator: null});
    }
  
    load(filter) {
      if (filter.length > 0 && filter[0].length === 3) {
        var currDate = new Date(filter[0][2].split(':')[1].trim());

        var currentWeekNos = [
          this.getWeekNoWithYear(this.addDays(currDate, -4 * 7)),
          this.getWeekNoWithYear(this.addDays(currDate, -3 * 7)),
          this.getWeekNoWithYear(this.addDays(currDate, -2 * 7)),
          this.getWeekNoWithYear(this.addDays(currDate, -1 * 7)),
          this.getWeekNoWithYear(currDate),
          this.getWeekNoWithYear(this.addDays(currDate, 1 * 7)),
          this.getWeekNoWithYear(this.addDays(currDate, 2 * 7)),
          this.getWeekNoWithYear(this.addDays(currDate, 3 * 7)),
          this.getWeekNoWithYear(this.addDays(currDate, 4 * 7))
        ] 
        this.setState({currentWeekNos: currentWeekNos});
      }

      getFirebaseIdToken(this.props.firebase, () => {
        vesselGridDataSource.filter(filter);
        this.resultVesselGrid.refresh();
        this.resultVesselGrid.clearSelection(); 
      });
      if (this.operatorSummaryForm)
        this.operatorSummaryForm.load(filter);
      if (this.fleetSummaryForm)
        this.fleetSummaryForm.load(filter);
      
    }
    addDays(baseDate, days) {
      var date = new Date(baseDate.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
    getWeekNoWithYear(dateToGetWeek) {
      var weekNo = this.getCurrentWeekNo(dateToGetWeek);
      var year = dateToGetWeek.getFullYear();
      if (dateToGetWeek.getMonth() === 0 && weekNo > 10)
        year = year - 1;
      else if  (dateToGetWeek.getMonth() === 12 && weekNo < 10)
        year = year + 1;
      return year.toString() + weekNo.toString().padStart(2,'0');
    }
    getCurrentWeekNo(dateToGetWeek) {

      var target  = new Date(dateToGetWeek.valueOf());
      var dayNr   = (dateToGetWeek.getDay() + 6) % 7;
      target.setDate(target.getDate() - dayNr + 3);
      var firstThursday = target.valueOf();
      target.setMonth(0, 1);
      if (target.getDay() !== 4) {
          target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
      }
      return 1 + Math.ceil((firstThursday - target) / 604800000);
    };

    /*
    onSelectionChanged(e) {
      var data = e.selectedRowsData[0];
      if (data) {
        this.operatorSummaryForm.load(['operator','=',data.operator]);
        this.operatorSummaryFormNoTab.load(['operator','=',data.operator]);
        this.fleetSummaryForm.load(['operator','=',data.operator]);
        this.setState({selectedOperator: data.operator});
      }
      else {
        this.operatorSummaryForm.resetForm();
        this.operatorSummaryFormNoTab.resetForm();
        this.fleetSummaryForm.resetForm();
      }
    }

    CellVenderVesselNameTemplate(props) {  
        const link = <a href={`javascript: alert('${props.data.vesselName}');`}>{props.data.vesselName}</a>  
        return link  
    } */
    render() {
      return (
        <React.Fragment>
          <Box m={[20]}>
          <DataGrid
              elementAttr={{
                class: "myClass"
              }}
              dataSource={vesselGridDataSource}
              showBorders={true}
              selection={{mode: 'single'}}
              ref={this.setVesselGrid}
              columnWidth={120}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
                >
              <Paging defaultPageSize={20} />
              <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[20, 40]}
              showInfo={true} />
              <ColumnChooser enabled={true} mode='select'/>
              <ColumnFixing enabled={true} />
              <FilterRow visible={true} />

              <Column dataField='alliance'  dataType={'string'} visible={true} fixed={true}  sortIndex={1} sortOrder={"asc"} />
              <Column dataField='trade'  dataType={'string'} visible={true}  fixed={true} />
              <Column dataField='service'  dataType={'string'} visible={true}  fixed={true}  sortIndex={2} sortOrder={"asc"} />
              <Column dataField='seq'  dataType={'number'} visible={false} sortIndex={0} sortOrder={"asc"} />
              <Column dataField='wk1' caption={this.state.currentWeekNos[0]} dataType={'string'} visible={true} />
              <Column dataField='wk2' caption={this.state.currentWeekNos[1]} dataType={'string'} visible={true} />
              <Column dataField='wk3' caption={this.state.currentWeekNos[2]} dataType={'string'} visible={true} />
              <Column dataField='wk4' caption={this.state.currentWeekNos[3]} dataType={'string'} visible={true} />
              <Column dataField='wk5' caption={this.state.currentWeekNos[4]} dataType={'string'} visible={true} />
              <Column dataField='wk6' caption={this.state.currentWeekNos[5]} dataType={'string'} visible={true} />
              <Column dataField='wk7' caption={this.state.currentWeekNos[6]} dataType={'string'} visible={true} />
              <Column dataField='wk8' caption={this.state.currentWeekNos[7]} dataType={'string'} visible={true} />
              <Column dataField='wk9' caption={this.state.currentWeekNos[8]} dataType={'string'} visible={true} />

            </DataGrid>

          </Box>          
   
        </React.Fragment>
      );
    }
  }

  export default CapacityGrid;