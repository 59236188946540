import React from 'react';
import { Drawer } from 'devextreme-react';
import List from 'devextreme-react/list.js';
import { Link } from 'gatsby';
import './mainMenu.css';

function siteLinks(item) {
    return (
        <Link style={{ color: "white" }} to={`/app/page/${item.slug}?showMenu=true`}>{item.title}</Link>
    );
  }

class NavigationList extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
          navigation: [{}],
        };      
      if (window.sessionStorage.getItem('page-root')) {
        let pages = JSON.parse(window.sessionStorage.getItem('page-root')).pages;
        this.state = {
          navigation: pages,
        };      
      } else {
        let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/browsePageByTag?tag=page-root`;    
        var request = new XMLHttpRequest();
        request.callObj = this;
        request.open('GET', postApiUrl,true);
        request.setRequestHeader('Content-Type','application/json; charset=utf-8');
        request.onreadystatechange = function (reposta) {
        if (this.readyState === 4) {
            //console.log(this.responseText);
            let pages = JSON.parse(this.responseText).pages;
            window.sessionStorage.setItem('page-root', this.responseText);
            this.callObj.setState({navigation: pages,});
        }
        };
        request.send();
      
      }    
    }    


  render() {
    return (
      <div style={{ width: '150px' }}>
        <List
          dataSource={this.state.navigation}
          hoverStateEnabled={false}
          activeStateEnabled={false}
          focusStateEnabled={false}
          itemRender={siteLinks}
          height={this.props.height}
          elementAttr={{ class: 'drawer-main-menu' }} 
          visible={this.state.navigation && this.state.navigation.length > 0}/>
      </div>
    );
  }
}

class MainMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: props.showMenu,
      menuHeight: 1080,
    };

    this.onOutsideClick = this.onOutsideClick.bind(this);
    this.showHide = this.showHide.bind(this);
    this.setHeight = this.setHeight.bind(this);
    this.NavigationListFunc = this.NavigationListFunc.bind(this);
    this.menuButtonClicked = false;


  }
  
  setHeight(element) {
    //var width = element.offsetWidth;
    var height = Math.max(element.offsetHeight, 500);

    this.setState({ menuHeight: height });
    //console.log("Size: " + width + "x" + height);
  }

  componentDidMount() {
    var elementResizeDetectorMaker = require("element-resize-detector");
    var erdUltraFast = elementResizeDetectorMaker({
      strategy: "scroll" //<- For ultra performance.
    });

    erdUltraFast.listenTo(document.getElementById("menudiv"), this.setHeight);
  }
  
  showHide() {
    this.setState({ opened: !this.state.opened });
    this.menuButtonClicked = true;
  }

  onOutsideClick() {
    if (this.menuButtonClicked)
      this.menuButtonClicked = false;
    else
      this.setState({ opened: false });
  }
  NavigationListFunc() {
    return (
      <NavigationList height={this.state.menuHeight}></NavigationList>
    );
  }
  render() {
    return (
      <React.Fragment>
        <Drawer
          opened={this.state.opened}
          openedStateMode={'shrink'}
          position={'left'}
          revealMode={'slide'}
          render={this.NavigationListFunc}
          closeOnOutsideClick={this.onOutsideClick}
          height={this.state.menuHeight}>
          <div id={"menudiv"} ref={ (divElement) => { this.divElement = divElement } }>
            {this.props.children}
          </div>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default MainMenu;
