import React from 'react';
import { Box, Flex } from '../Layout';
import colors from '../../utils/colors';
import TabNav from './Navigation/tabNav';
import MainMenu from '../Menu/mainMenu';
import { Button } from 'devextreme-react';
import PowerBIComponent from '../Dashboard/Components/powerBIComponent';
import PowerBIWithCmtComponent from '../Dashboard/Components/powerBIWithCmtComponent';
import { navigate } from 'gatsby';
import { isBrowser, logRouteUsage } from '../../utils/auth';
import { getUser } from "../../utils/auth";
import qs from 'query-string';


import FleetParticular from '../Enquiry/Components/Fleets';
import PortThruput from '../Enquiry/Components/PortThruput';
import Capacity from '../Enquiry/Components/Capacity'
import Port2Port from '../Enquiry/Components/Port2Port'
import SseIndices from '../Enquiry/Components/SseIndices'
import CapacityPivot from '../Enquiry/Components/CapacityPivot'
import Orderbook from '../Enquiry/Components/Orderbook'
import Fixtures from '../Enquiry/Components/Fixtures'
import Sales from '../Enquiry/Components/Sales'
import Service from '../Enquiry/Components/Service'
import SalesScrapped from '../Enquiry/Components/SalesScrapped'
import OwnersSummary from '../Enquiry/Components/OwnersSummary'
import OperatorsSummary from '../Enquiry/Components/OperatorsSummary'
import PortCongestion from '../Enquiry/Components/PortCongestion';
import VesselLocation from '../Enquiry/Components/VesselLocation';
import CongestionOverviewGrid from '../Enquiry/Components/PortCongestion/CongestionOverviewGrid';
import CongestionByRegionChart from '../Enquiry/Components/PortCongestion/CongestionByRegionChart';
import SinglePost from '../CMS/singlePost';


function resolveComponent(componentId, firebase) {
  if (componentId === 'Port2Port')
    return <Port2Port firebase={firebase}/>;
  else if (componentId === 'Fleets')
    return <FleetParticular firebase={firebase}/>;
  else if (componentId === 'cat1')
    if (getUser().email === 'rayhwip@gmail.com')
      return <VesselLocation firebase={firebase} />;
    else
      return <Sales firebase={firebase} />;
  else if (componentId === 'VesselLocation')
    return <VesselLocation firebase={firebase}/>;
  else if (componentId === 'capacityPivot')
    return <CapacityPivot firebase={firebase}/>;
  else if (componentId === 'capacity')
    return <Capacity firebase={firebase}/>;
  else if (componentId === 'Port')
    return <PortThruput firebase={firebase}/>;
  else if (componentId === 'SseIndices')
    return <SseIndices firebase={firebase}/>;
  else if (componentId === 'orderbook')
    return <Orderbook firebase={firebase} />;
  else if (componentId === 'fixture')
    return <Fixtures firebase={firebase} />;
  else if (componentId === 'sale')
    if (getUser().email === 'rayhwip@gmail.com' || getUser().email === 'christine@linerlytica.com')
      return <VesselLocation firebase={firebase} />;
    else
      return <Sales firebase={firebase} />;
  else if (componentId === 'salesScrapped')
    return <SalesScrapped firebase={firebase} />;
  else if (componentId === 'ownersSummary')
    return <OwnersSummary firebase={firebase} />;
  else if (componentId === 'operatorsSummary')
    return <OperatorsSummary firebase={firebase} />;
  else if (componentId === 'service')
    return <Service firebase={firebase} />;
  else if (componentId === 'portCongestion')
    return <PortCongestion firebase={firebase} />;
}

function renderComponent(component, noOfColumn, firebase) {
  const colWidthRatio = parseInt(component.colSpan) / noOfColumn
  if (component.componentType === 'powerbi') {
    return (
      <Box key={component.positionId} m={[1]} width={[1, 1, colWidthRatio]} height={['calc(100vw * ' + component.hieghtToWidthRatio + ')', 'calc(100vw * ' + component.hieghtToWidthRatio + ')', 'calc(100vw * ' + component.hieghtToWidthRatio + ' * ' + colWidthRatio.toString() + ')']}>
        <h2>{component.componentName}</h2>
        <PowerBIComponent
          embedType={component.embedType}
          tokenType={component.tokenType}
          embedUrl={component.embedUrl}
          embedId={component.embedId}
          pageName={component.pageName}
          permissions={component.permissions}
          navContentPaneEnabled={component.navContentPaneEnabled}
          filterPaneEnabled={component.filterPaneEnabled}
          firebase={firebase}
        ></PowerBIComponent>
      </Box>
    );
    
  } else if (component.componentType === 'powerbiWithCommentary') {
    return (
      <Box key={component.positionId} m={[1]} width={[1, 1, colWidthRatio]}>
        <h2>{component.componentName}</h2>
        <PowerBIWithCmtComponent
          embedType={component.embedType}
          tokenType={component.tokenType}
          embedUrl={component.embedUrl}
          embedId={component.embedId}
          pageName={component.pageName}
          permissions={component.permissions}
          navContentPaneEnabled={component.navContentPaneEnabled}
          filterPaneEnabled={component.filterPaneEnabled}
          commentaryId={component.commentaryId}
          hieghtToWidthRatio={component.hieghtToWidthRatio}
          noOfColumn={noOfColumn}
          colSpan={parseInt(component.colSpan)}
          firebase={firebase}
        ></PowerBIWithCmtComponent>
      </Box>
    );
    
  } else if (component.componentType === 'enquiry') {
    return (
    <Box key={component.positionId} m={[1]} width={[1, 1, colWidthRatio]}>
      { 
        resolveComponent(component.componentId, firebase)
      }
    </Box>);
  } else {
    return (
      <Box m={[1]} width={[1, 1 / 2, 1/2]} >
      <h2>{component.componentName}</h2>
      </Box>
    );    
  }
}

function BrowsePageByTag(tag, limit, callingObj, callbackFunc) {
  let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/browsePageByTag?tag=page-${tag}&limit=${limit}`;
  var request = new XMLHttpRequest();
  request.callObj = callingObj;
  request.open('GET', postApiUrl,true);
  request.setRequestHeader('Content-Type','application/json; charset=utf-8');
  request.onreadystatechange = function (reposta) {
  if (this.readyState === 4) {
    callbackFunc(this, this.callObj);
  }
  };
  request.send();

}

class AnalysisPage extends React.Component {

  constructor(props) {
    super(props);

    const parsed = qs.parse(window.location.search);
    this.showMenu = parsed.showMenu === 'true' || !props.page || !props.parentMenu;
    this.state = {
      pageInfo: null,
      rows:[],
      noOfColumn:0,
      showLimitReachedPage: false,
    }

    if (!props.parentMenu) {
      BrowsePageByTag('root',1,this, (httpRequest, thisObj) => {
        thisObj.parentMenu = JSON.parse(httpRequest.responseText).pages[0].slug;
        BrowsePageByTag(thisObj.parentMenu,1, thisObj, (httpRequest2, thisObj2) => {
          let pages = JSON.parse(httpRequest2.responseText).pages;
          navigate(`/app/page/${thisObj2.parentMenu}/${pages[0].slug}?showMenu=true`);          
        })
      });
      return;
    } else if (!props.page) {
      if (isBrowser() && window.sessionStorage.getItem(`firstNavMenuItem.${this.props.parentMenu}`))
        navigate(window.sessionStorage.getItem(`firstNavMenuItem.${this.props.parentMenu}`));
      else {
        BrowsePageByTag(this.props.parentMenu,1, this, (httpRequest, thisObj) => {
          let pages = JSON.parse(httpRequest.responseText).pages;
          if (pages && pages.length > 0) {
            const firstNavMenuItem = `/app/page/${thisObj.props.parentMenu}/${pages[0].slug}?showMenu=true`;
            if (isBrowser()) 
              window.sessionStorage.setItem(`firstNavMenuItem.${thisObj.props.parentMenu}`,firstNavMenuItem)
            navigate(firstNavMenuItem);     
          }            
        });
      }
      return;

    }

    this.setMainMenu = (ref) => {
      if (ref != null)
          this.mainMenu = ref;
    };    
    this.mainMenuShowHide = this.mainMenuShowHide.bind(this);
    this.getPageInfo = this.getPageInfo.bind(this);
    this.getPageInfo();
  }  

  componentDidMount() {
    //log route usage
    const route = isBrowser()?encodeURI(window.location.pathname): null;
    logRouteUsage(route, ()=>{
      this.setState({showLimitReachedPage: true});
    });
  }
  
  getPageInfo() {
    if (!this.props.page)
      return;
    let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/readPageBySlug?slug=${this.props.page}`;    
    var request = new XMLHttpRequest();
    request.callObj = this;
    request.open('GET', postApiUrl,true);
    request.setRequestHeader('Content-Type','application/json; charset=utf-8');
    request.onreadystatechange = function (reposta) {
    if (this.readyState === 4) {
        //console.log(this.responseText);
        let pagesInfo = JSON.parse(JSON.parse(this.responseText).codeinjection_head);
        

        var noOfColumn = parseInt(pagesInfo.noOfColumns);
        var rows = [];
        //var noOfRow = 0;
        var cols = [];
        var currColInRow = 0;
        //var currRow = 1;
        for (var componentIdx = 0; componentIdx < pagesInfo.components.length; componentIdx++) {
          if (!pagesInfo.components[componentIdx].colSpan)
            pagesInfo.components[componentIdx].colSpan = "1";
          if (currColInRow + parseInt(pagesInfo.components[componentIdx].colSpan) > noOfColumn)
          {
            //change row
            rows.push(cols);
            cols = [];
            currColInRow = parseInt(pagesInfo.components[componentIdx].colSpan);
            //currRow ++;
          } else {
            currColInRow = currColInRow + parseInt(pagesInfo.components[componentIdx].colSpan);
          }

          cols.push(pagesInfo.components[componentIdx]);
        }
        if (cols.length > 0) {
          rows.push(cols);
          //currRow ++;          
        }
        this.callObj.setState({
          pageInfo: pagesInfo,
          noOfColumn: noOfColumn,
          rows: rows
        });
    }
    };
    request.send();
  }

  mainMenuShowHide(){
    this.mainMenu.showHide();
  }
  render() {
//    if (!this.props.parentMenu || !this.props.page)
//      return null;

    return (
      
      <Box bg={colors.primary}>
        
        <Box
            width={[1, 1, 1]}
            m={['2.7rem 0 0 0', '2.7rem 0 0 0', '2.7rem auto 0 auto']}
            px={[0, 0, 0]}
            color={colors.secondary}
            minHeight={'500px'}
        >
          <MainMenu showMenu={this.showMenu} firebase={this.props.firebase} ref={this.setMainMenu}>
            <Box m={'20px 10px 20px 10px'}>
          {this.props.parentMenu &&
          <div className="no-printme">
          <Flex justifyContent="centre" wrap={'nowrap'}>
            <Box m={'0px 10px 0px 10px'}>
              <Button icon="menu" hint="Menu" onClick={this.mainMenuShowHide}/>
            </Box>                
            <TabNav parentMenu={this.props.parentMenu}/>
                 
          </Flex>
          </div>}
          {!this.state.showLimitReachedPage && this.state.rows
                .map((row, index) => {
                    return (
                    <Flex key={index} justifyContent="centre" wrap={['wrap', 'wrap', 'nowrap']}>
                        {row.map((column, index) => {
                        return renderComponent(column, this.state.noOfColumn, this.props.firebase)})
                        }
                    </Flex>
                    );
                })
            }    
            {
              this.state.showLimitReachedPage && 
              <SinglePost firebase={this.props.firebase} tagSlug={'hash-trialuserwarning'} displayAsPage={true} />
            }
            </Box>       
          </MainMenu>
        </Box>
      </Box>
      );
  }
}

export default AnalysisPage;