import React from 'react';
import { Box } from '../Layout';
import Helmet from 'react-helmet';
//import '../../styles/cms2.css';
import '../../styles/screen.css';
import '../../styles/addedon.css';
import { Button } from 'devextreme-react';
import { getFirebaseIdToken } from "../../utils/auth";

class SinglePost extends React.Component {
    constructor(props) {
        super(props);
        if (props.post)
            this.state = {post: props.post};
        else
            this.state = {post: {primary_author: ""}};
        this.displayAsPage = false;
        this.refresh = this.refresh.bind(this);

    }
    componentDidMount()
    {
        if (!this.props.post)
            this.refresh();
    }

    refresh() {
        let postApiUrl;
        
        if (this.props.tagSlug)
            postApiUrl = `${process.env.GATSBY_CMS_API_URL}/readPostByTagSlug?tagSlug=${this.props.tagSlug}`;
        else
            postApiUrl = `${process.env.GATSBY_CMS_API_URL}/readPostBySlug?slug=${this.props.slug}`;
        

        getFirebaseIdToken(this.props.firebase, ()=>{
            
            //let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/browseAllPost?type=${this.props.type}&parentId=${this.props.parentId}&limit=${this.props.limit}&page=${this.currentPage}`;

            var request = new XMLHttpRequest();
            request.callObj = this;
            request.open('GET', postApiUrl,true);
            request.setRequestHeader('Content-Type','application/json; charset=utf-8');
            request.setRequestHeader('Authorization',`Bearer ${(typeof window !== 'undefined')?window.localStorage.getItem("userIdToken"):''}`);
            request.onreadystatechange = function (reposta) {
            if (this.readyState === 4) {
                //console.log(this.responseText);
                this.callObj.setState({post: JSON.parse(this.responseText)});
            }
            };
            request.send();
        });
    }
    
    render() {

        return (
            <>
            <Helmet>
                <style type="text/css">{`${this.state.post.codeinjection_styles}`}</style>

            </Helmet>

                <Box
                    width={[1, 1, 3/4]}
                    m={['2.7rem 0 0 0', '2.7rem 0 0 0', '2.7rem auto 0 auto']}
                >
                    <article className="post-full post">
                        <header className="post-full-header">
                            <section className="post-full-tags">
                                
                            </section>
                            <h1 className="post-full-title">{this.state.post.title}</h1>
                            {this.props.displayAsPage?null:
                            (<>

                            <Button icon="back" hint="Back" onClick={()=>{window.history.back();}}/>
                            <p className="post-full-custom-excerpt">{this.state.post.excerpt}</p>
                            
                            <div className="post-full-byline">
                                <section className="post-full-byline-content">
                                    <div className="author-card">
                                        {this.state.post.primary_author.profile_image?
                                            <img className="author-profile-image" src={this.state.post.primary_author.profile_image?this.state.post.primary_author.profile_image:"/llassets/icons/avatar-80.png"} alt={this.state.post.primary_author.name}/> :
                                            <img className="author-profile-image" src="/llassets/icons/avatar-80.png" alt={""}/>
                                        }
                                    </div>
                                    <section className="post-full-byline-meta">
                                        <h4 className="author-name">{this.state.post.primary_author.name}</h4>
                                        <span className="post-card-byline-date">
                                        {(new Date(this.state.post.published_at)).toLocaleString(typeof window !== 'undefined'?window.navigator.userLanguage || window.navigator.language:'en-US',{year: 'numeric',month: 'short',day: 'numeric'})}
                                        </span>                                
                                    </section>
                                </section>
                            </div>
                            </>)
                            }   
                        </header>
                        { this.state.post.feature_image ?
                        <figure className="post-full-image">
                        <img sizes="(max-width: 800px) 400px,
                                (max-width: 1170px) 1170px,
                                    2000px" src={this.state.post.feature_image} alt={this.state.post.title}/>
                        </figure>:
                        null}
                        <section className="post-full-content">
                            <div className="post-content" dangerouslySetInnerHTML={{ __html: this.state.post.html }}>

                            </div>
                            {this.props.displayAsPage?null:<Button icon="back" hint="Back" onClick={()=>{window.history.back();}}/>}
                        </section>
                        
                    </article>
                    
                </Box>

            </>

        );
    }
}
    
export default SinglePost;