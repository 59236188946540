import {
  dDown_Port,
  dDown_ServiceName,
  dDown_PortCountry,
  DDown_Operator,
  DDown_Alliance,
  DDown_RegionGroup,
  dDown_serviceTrade
  } from '../commonDataSource';
import {processCriteriaFormItems} from '../helper';

export function getFormItems(mode) 
{
    //  dataType: for FilterBuilder only
    //  editorTemplate: for FilterBuilder only when custom editor is used
    // caption: for FilterBuilder only
    // defaultCriteria: for form criteria only if default criteria will be set
    // searchOperator: for form criteria only if custom operator is used
    
    return processCriteriaFormItems([  
      {
        dataField: 'trade',
        editorType: 'dxSelectBox',
        dataType: 'string',
        editorOptions: {
            dataSource: DDown_RegionGroup(),
            displayExpr: function(item){
              return item && item.regionGroupName + ' (' + item.regionGroup +')';
            },
            valueExpr:'regionGroup',
            searchEnabled:'true',
            searchMode:'contains',
            searchExpr:['regionGroup','regionGroupName'],
            searchTimeout:'200',
            minSearchLength:2,
            showDataBeforeSearch:'true',
            showClearButton:'true'
        },
        label: {text: 'From Region'},
        searchOperator: 'contains',
        visible: true
      },
      {
        dataField: 'trade_SAME',
        editorType: 'dxSelectBox',
        dataType: 'string',
        editorOptions: {
            dataSource: DDown_RegionGroup(),
            displayExpr: function(item){
              return item && item.regionGroupName + ' (' + item.regionGroup +')';
            },
            valueExpr:'regionGroup',
            searchEnabled:'true',
            searchMode:'contains',
            searchExpr:['regionGroup','regionGroupName'],
            searchTimeout:'200',
            minSearchLength:2,
            showDataBeforeSearch:'true',
            showClearButton:'true'
        },
        label: {text: 'To Region'},
        searchOperator: 'contains',
        visible: true
      },
      {
        dataField: 'countries',
        editorType: 'dxSelectBox',
        dataType: 'string',
        editorOptions: {
            dataSource: dDown_PortCountry(),
            displayExpr: 'country',
            valueExpr:'country',
            searchEnabled:'true',
            searchMode:'contains',
            searchExpr:'country',
            searchTimeout:'200',
            minSearchLength:2,
            showDataBeforeSearch:'true',
            showClearButton:'true'
        },
        label: {text: 'From Country'},
        searchOperator: 'contains',
        visible: true
      },
      {
        dataField: 'countries_SAME',
        editorType: 'dxSelectBox',
        dataType: 'string',
        editorOptions: {
            dataSource: dDown_PortCountry(),
            displayExpr: 'country',
            valueExpr:'country',
            searchEnabled:'true',
            searchMode:'contains',
            searchExpr:'country',
            searchTimeout:'200',
            minSearchLength:2,
            showDataBeforeSearch:'true',
            showClearButton:'true'
        },
        label: {text: 'To Country'},
        searchOperator: 'contains',
        visible: true
      },

      {
        dataField: 'portCodes',
        editorType: 'dxSelectBox',
        dataType: 'string',
        editorOptions: {
            dataSource: dDown_Port(),
            displayExpr: 'fullPort',
            valueExpr:'portCode',
            searchEnabled:'true',
            searchMode:'contains',
            searchExpr:'fullPort',
            searchTimeout:'200',
            minSearchLength:2,
            showDataBeforeSearch:'true',
            showClearButton:'true'
        },
        label: {text: 'From Port'},
        searchOperator: 'contains',
        visible: true
    },
    {
      dataField: 'portCodes_SAME',
      editorType: 'dxSelectBox',
      dataType: 'string',
      editorOptions: {
          dataSource: dDown_Port(),
          displayExpr: 'fullPort',
          valueExpr:'portCode',
          searchEnabled:'true',
          searchMode:'contains',
          searchExpr:'fullPort',
          searchTimeout:'200',
          minSearchLength:2,
          showDataBeforeSearch:'true',
          showClearButton:'true'
      },
      label: {text: 'To Port'},
      searchOperator: 'contains',
      visible: true
    },

    {
      dataField: 'groupCodes',
      editorType: 'dxSelectBox',
      editorOptions: {
          dataSource: DDown_Operator(),
          displayExpr: 'fullOperator',
          valueExpr: 'operator',
          searchEnabled:'true',
          searchMode:'contains',
          searchExpr:'fullOperator',
          searchTimeout:200,
          minSearchLength: 2,
          showClearButton:true,
          showDataBeforeSearch:true,
          placeholder: 'Type to search by entity, group name or country ...',
      },
      label: {text: 'Operator'},
      visible: true,
      searchOperator: 'contains',
      colSpan: 2
    },

    {
      dataField: 'serviceName',
      editorType: 'dxSelectBox',
      editorOptions: {
          dataSource: dDown_ServiceName(),
          displayExpr: 'serviceName',
          valueExpr: 'serviceName',
          searchEnabled:'true',
          searchMode:'contains',
          searchExpr:'serviceNameForSrh',
          searchTimeout:200,
          minSearchLength: 2,
          showClearButton:true,
          showDataBeforeSearch:true,
          placeholder: 'Type to search by service name...',
      },
      label: {text: 'Service Name'},
      visible: true,
      searchOperator: 'contains',
      colSpan: 2
    },
    {
      dataField: 'trade',
      editorType: 'dxSelectBox',
      dataType: 'string',
      editorOptions: {
          dataSource: dDown_serviceTrade(),
          displayExpr: 'trade',
          valueExpr:'trade',
          searchEnabled:'true',
          searchMode:'contains',
          searchExpr:'trade',
          searchTimeout:'200',
          minSearchLength:2,
          showDataBeforeSearch:'true',
          showClearButton:'true'
      },
      searchOperator: 'contains',
      visible: true
    },
    {
      dataField: 'alliance',
      editorType: 'dxSelectBox',
      dataType: 'string',
      editorOptions: {
          dataSource: DDown_Alliance(),
          displayExpr: 'alliance',
          valueExpr:'alliance',
          searchEnabled:'true',
          searchMode:'contains',
          searchExpr:'alliance',
          searchTimeout:'200',
          minSearchLength:2,
          showDataBeforeSearch:'true',
          showClearButton:'true'
      },
      searchOperator: 'contains',
      visible: true
    }

  ], mode);
}