import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Box } from "../../components/Layout"
import colors from '../../utils/colors';
import Logout from  '../../components/Logout';
import Link from 'gatsby-link';

class EmailVerify extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {loadPanelVisible: true};

    this.verifyEmail = this.verifyEmail.bind(this);

    const qs = require('query-string');
    const parsed = qs.parse(window.location.search);
    this.verifyEmail(parsed.oobCode);

  }

  verifyEmail(oobCode) {
    let postApiUrl = `${process.env.GATSBY_USER_API_URL}/verifyEmail?oobCode=${oobCode}`;    
    var request = new XMLHttpRequest();
    request.callObj = this;
    request.open('GET', postApiUrl,true);
    request.setRequestHeader('Content-Type','application/json; charset=utf-8');
    request.onreadystatechange = function (reposta) {
        
        if (this.readyState === 4) {
            this.callObj.setState({loadPanelVisible:false});
            if (this.responseText) {
                var ret = JSON.parse(this.responseText);
                if (ret.message)
                    this.callObj.setState({errorMsg:ret.message});
            }
            
        }
    };
    request.send();
  }

  render() {
      return (
        <>
        <Box bg={colors.primary}>
        <Box
        width={[1, 1, 1 / 2]}
        m={['3.5rem 0 0 0', '3.5rem 0 0 0', '3.5rem auto 0 auto']}
        px={[3, 3, 0]}
        color={colors.secondary}
        >
        {!this.state.loadPanelVisible?
        (this.state.errorMsg?
        <>
            <h2>Verification Fail!</h2>
            <p>{this.state.errorMsg}</p>
        </>
        :
        <>
            <h2>Your email is verified</h2>
            <p>Please <Link style={{color: colors.secondary}} to="/app/login">login</Link> again to have access to the trial services.  A welcome email is just sent to your mail box. Please follow the instructions in the email to make payment and activate our services</p>
            <Logout redirectUrl={'NONE'} />
        </>):null}
        <LoadPanel visible={this.state.loadPanelVisible}/>
        </Box>
        </Box>
        </>
      );

  };
};

export default EmailVerify;