import { DDown_SseGroup } from '../commonDataSource';
import {processCriteriaFormItems} from '../helper';

export function getFormItems(mode) 
{
    //  dataType: for FilterBuilder only
    //  editorTemplate: for FilterBuilder only when custom editor is used
    // caption: for FilterBuilder only
    // defaultCriteria: for form criteria only if default criteria will be set
    // searchOperator: for form criteria only if custom operator is used
    return processCriteriaFormItems([
        
        {                    
            dataField: 'groupName',
            editorType: 'dxSelectBox',
            dataType: 'string',
            validationRules:[{type:"required", message:"It is required"}],
            editorOptions: {
                dataSource: DDown_SseGroup(),
                displayExpr: 'groupName',
                valueExpr:'groupName',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'groupName',
                searchTimeout:'200',
                minSearchLength:'0',
                showDataBeforeSearch:'true',
                showClearButton:'true'
            },
            label: {text: 'Select'},
            colSpan: 2,
            visible: true,
            searchOperator: '='
        },

    
        {
            dataField:'date',
            caption: 'Date',
            editorType:'dxDateBox',
            dataType: 'date',
            searchOperator: 'between',
            visible: mode !== 'simple',
        }

        ], mode);
}