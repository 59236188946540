import React from "react";
import { Router } from "@reach/router";
import PageWrapper from '../components/PageWrapper';
import SignIn from "../components/User/SignIn";
import ForgetPassword from "../components/User/ForgetPassword";
import Logout from "../components/Logout";
import PrivateRoute from "../components/PrivateRoute";
import SinglePostNew from "../components/CMS/singlePostNew";
import AnalysisPage from "../components/AnalysisPage";
import FleetParticularForm from '../components/Enquiry/Components/Fleets/FleetParticularForm';
import ServiceDetail from '../components/Enquiry/Components/Service/ServiceDetail';
import operatorDetail from '../components/Enquiry/Components/OperatorsSummary/operatorDetail';
import User from "../components/User";
import Profile from "../components/User/Profile";
import ActionHandler from "../components/User/ActionHandler";
import SearchPost from "../components/CMS/searchPost";
import SinglePost from "../components/CMS/singlePost";
import Payment from '../components/Payment';
import PortCongestion from '../components/Enquiry/Components/PortCongestion';

const App = () => (
  <PageWrapper>
    <Router>
      <SignIn path="/app/login"  />
      <ForgetPassword path="/app/forgetPassword" />
      <ActionHandler path="/app/actionhandler" />
      <Logout path="/app/logout" />
      <PortCongestion path="/app/portCongestion" />
      <SearchPost path="/app/searchPost/:keywords/:page" />
      <PrivateRoute path="/app/post/:slug" checkRouteUsage={true} requiredRole={['trial','general','advance']} component={SinglePostNew} />
      <PrivateRoute path="/app/page/:parentMenu/:page" requiredRole={['trial','advance']} component={AnalysisPage} />
      <PrivateRoute path="/app/page/:parentMenu" requiredRole={['trial','advance']} component={AnalysisPage} />
      <PrivateRoute path="/app/page" requiredRole={['trial','advance']} component={AnalysisPage} />
      <PrivateRoute path="/app/user"  requiredRole={[]} component={User} />
      <PrivateRoute path="/app/profile"  component={Profile} />
      <PrivateRoute path="/app/fleetParticular/:imoNo" checkRouteUsage={true} requiredRole={['trial','advance']} component={FleetParticularForm} />
      <PrivateRoute path="/app/service/:lsCode" checkRouteUsage={true} requiredRole={['trial','advance']} showFleet={true} component={ServiceDetail} />
      <PrivateRoute path="/app/operator/:groupCode" checkRouteUsage={true} requiredRole={['trial','advance']} component={operatorDetail} />
      <PrivateRoute path="/app/newsletter" checkRouteUsage={true} requiredRole={['trial','general','advance']} tagSlug={'hash-newsletter'} displayAsPage={true} component={SinglePost} />
      <PrivateRoute path="/app/extra" checkRouteUsage={true} requiredRole={['trial','general','advance']} tagSlug={'hash-extra'} displayAsPage={true} component={SinglePost} />
      <PrivateRoute path="/app/payment/:option" component={Payment} />
    </Router>
  </PageWrapper>
)

export default App
