import React from 'react';
import Report from './lib/powerbi-report';
import {getPowerBIAccessToken, getAccessToken} from './lib/powerbiApi';
import { getFirebaseIdToken } from "../../../utils/auth";

class PowerBIComponent extends React.Component {
    constructor(props) {
      super(props);


      getFirebaseIdToken(props.firebase, () => {
        getAccessToken(this, this.afterGetAccessToken);
      });
      
      //getPowerBIAccessToken(this.props.embedType, this.props.embedId, this, this.setAccessToken);


    }

    afterGetAccessToken(accessToken, thisObj) {
      getPowerBIAccessToken(accessToken, thisObj.props.embedType, thisObj.props.embedId, thisObj, thisObj.setAccessToken);
    }

    setAccessToken(accessTokenPowerBI, thisObj) {
      thisObj.setState({accessToken: accessTokenPowerBI});
    }

    render() {
        const reportStyle = {
          height: '90%',
          border: '0px',
          borderWidth: '0px',
          frameborder:'0',
          padding: '20px',
          background: '#fff'
      };
      const extraSettings = {
              filterPaneEnabled: this.props.filterPaneEnabled === 'Y', //true
              navContentPaneEnabled: this.props.navContentPaneEnabled === 'Y', //true
              // ... more custom settings
      };
        return (
          <Report
              embedType={this.props.embedType}
              tokenType={this.props.tokenType}
              accessToken={this.state === null? "": this.state.accessToken}
              embedUrl={this.props.embedUrl}
              embedId={this.props.embedId}
              pageName={this.props.pageName}
              extraSettings={extraSettings}
              permissions={this.props.permissions}
              style={reportStyle}
  
          />
        );
    }
}
    
export default PowerBIComponent;