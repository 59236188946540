import React from 'react';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { getFirebaseIdToken } from "../../../../utils/auth";
import Chart, { CommonSeriesSettings, Size, Series,  ArgumentAxis, Legend } from 'devextreme-react/chart';
import { Box, Flex } from '../../../Layout';
import {
  operatorSummaryDataSource 
  } from '../commonDataSource'
import OperatorDetail from './operatorDetail'
// import chartCell from './chartCell'

/*
const groupedItems={
  profile:[
    'affiliates','profile'
  ],
  Basic:[
    'groupName','country','ownership','groupWebSite','groupAbbr',
    'totalTeu','totalShip','ownedTeu','ownShip','charteredTeu','charteredShip',
    'orderedTeu','orderedShip'
  ]
};
*/

class OperatorFleetGrid extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { showSummary: true, showDetail: null, initialFilter: null };
      this.setSummaryGrid = (ref) => {
        if (ref != null)
            this.summaryGrid = ref.instance;
      };
      this.setSummaryChart = (ref) => { 
        if (ref != null)
          this._chart = ref.instance;
      }; 
      this.setOprDetail = (ref) => { 
        if (ref != null)
          this.OprDetailForm = ref;
      };     


      this.onSelectionChanged=this.onSelectionChanged.bind(this);
      this.backFromSubScreen = this.backFromSubScreen.bind(this);
//      this.resetResult = this.resetResult.bind(this);
    }

    componentDidMount() {
      getFirebaseIdToken(this.props.firebase, () => {
        operatorSummaryDataSource.filter();
      });  
    }

    backFromSubScreen() {
      this.summaryGrid.clearSelection();
      this.setState({showSummary: true, showDetail: null});
    }

    resetResult() {
//      alert('reest');
      this.setState({ showDetail: null });
      this.setState({ initialFilter: ['operatorGroup','=','X'], showDetail: true, showSummary: false });
    }
  
    load(filter) {
      if (filter) {
        this.setState({ showDetail: null });
        this.setState({ initialFilter: filter, showDetail: true, showSummary: false });
      }
      else {
      }
    }
    
    onSelectionChanged(e) {
      var data = e.selectedRowsData[0];
      var selectedOpr = ['operatorGroup','contains','X'];
      if (data) {
        selectedOpr = ['operatorGroup','contains',data.operatorGroup];
      }
      this.load([selectedOpr]);
    }


    render() {
      return (
        <React.Fragment>
        <div style={{display: (this.state.showSummary)?'inline':'none'}} >        
        <Flex wrap={['wrap', 'nowrap','nowrap']}>
        <Box width={['100%','485px','485px']}>
        <DataGrid
          elementAttr={{
            class: "myClass"
          }}
          dataSource={operatorSummaryDataSource}
          onSelectionChanged={this.onSelectionChanged}
          showBorders={true}
          selection={{mode: 'single'}}
          ref={this.setSummaryGrid}
          allowColumnReordering={true}
          allowColumnResizing={true}
            >
          <Paging defaultPageSize={20} />
          <Pager
          showInfo={true} />

          <Column 
            dataField={'ranking'}
            caption='#'
            width={30}
          />

          <Column dataField='groupName'
            width={169}
            dataType={'string'}
          />

          <Column dataField='groupAbbr'
            width={220}
            dataType={'string'}
            visible={false}
          />

          <Column caption='Current' alignment='center'>

            <Column dataField='totalTeu'
              width={60}
              dataType={'number'}
              caption={'TEU'}
              format={{type:'fixedPoint',precision:0}}
              defaultSortOrder={'desc'}
            />

            <Column 
              width={40}
              dataField='totalShip'
              dataType={'number'}
              caption='Ships'
              format={{type:'fixedPoint',precision:0}}
            />
          </Column>

          <Column caption='Orderbook' alignment='center'>
            <Column dataField='orderedTeu'
              width={60}
              dataType={'number'}
              caption='TEU'
              format={{type:'fixedPoint',precision:0}}
            />

            <Column dataField='orderedShip'
              width={40}
              dataType={'number'}
              caption='Ships'
              format={{type:'fixedPoint',precision:0}}
            />
          </Column>

        </DataGrid>
        </Box>
        
        <Box width={['100%','calc(100vw - 490px)','calc(100vw - 490px)']}>
          <h2>Operating and Orderbook Capcity in TEU</h2>
          <Chart 
            ref={this.setSummaryChart}
            dataSource={operatorSummaryDataSource}       
            rotated={true}            
            
            >              
            <Size height={609} />
            <CommonSeriesSettings type ="stackedBar" argumentField='groupAbbr'/>
            <Legend position={'outside'} verticalAlignment={'bottom'} horizontalAlignment={'center'} itemTextPosition='left'/>
            <Series
              name='TEU'
              valueField='totalTeu'           
              color='#BE1E2D'                                                   
            >
            </Series>
            <Series
              name='Ordered'
              valueField='orderedTeu'
              color='#636466'
            />
            <ArgumentAxis
              inverted={true}
            />            
          </Chart>
          </Box>
        </Flex>
        </div>

        {this.state.showDetail &&
          <OperatorDetail 
            firebase={this.props.firebase}
            ref={this.setOprDetail}
            initialFilter={this.state.initialFilter}
            returnCallback={this.backFromSubScreen}
            enquiryBase={this.props.enquiryBase}              
          />
        }

        </React.Fragment>
      );
    }
  }

  export default OperatorFleetGrid;