import {dDown_BenOwner} from '../commonDataSource';
import {processCriteriaFormItems} from '../helper';

        export function getFormItems(mode) 
        {
            //  dataType: for FilterBuilder only
            //  editorTemplate: for FilterBuilder only when custom editor is used
            // caption: for FilterBuilder only
            // defaultCriteria: for form criteria only if default criteria will be set
            // searchOperator: for form criteria only if custom operator is used
            
            return processCriteriaFormItems([
        
                {
                    dataField: 'beneficialOwner',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                        dataSource: dDown_BenOwner(),
                        displayExpr: 'benOwner',
                        valueExpr:'benOwner',
                        searchEnabled:'true',
                        searchMode:'contains',
                        searchExpr:'benOwner',
                        searchTimeout:200,
                        minSearchLength: 2,
                        showClearButton:true,
                        showDataBeforeSearch:'true',
                        placeholder: 'Type to search ...',
                    },
                    label: {text: 'Owner'},
                    visible: mode !== 'simple',
                    colSpan: 2
                }
        
            ], mode);
        }