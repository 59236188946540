import React from 'react';
import Form from 'devextreme-react/form';
import {getFilterArrayFromForm, getDefaultCriteria} from './helper';

class CriteriaFormBase extends React.Component {
    constructor(props) {
      super(props);
      
      this.setForm = (ref) => {
        if (ref != null)
            this.criteriaForm = ref.instance;
      };

      if (props.colCount)
        this.colCount = props.colCount;
      else
        this.colCount = 6;

    }
    componentDidMount() {
      this.resetCriteria();
    }
    resetCriteria() {
      this.criteriaForm.option('formData', null);
      this.criteriaForm.option('formData', getDefaultCriteria(this.props.formItems));
    }
  
    getCriteriaFilter() {
      if (this.criteriaForm.validate().isValid) {
        let ret = getFilterArrayFromForm(this.criteriaForm.option('formData'), this.props.formItems);
        if (ret) {
          ret.forEach(element => {
            if (Array.isArray(element) && element.length === 3 && element[2].replace) {
              element[2] = element[2].replace(/\(/g,'@8@');
              element[2] = element[2].replace(/\)/g,'@9@');
            }

          });
        }
        return ret;
      }
      return null;
    }

    render() {
      return (
        <React.Fragment>
            <Form
              colCount={this.colCount}
              items={this.props.formItems}
              ref={this.setForm}
              labelLocation={'top'}
              >
            </Form>
        </React.Fragment>
      );
    }
  }
  
  export default CriteriaFormBase;