import React from 'react';
import CapacityGrid from './CapacityGrid';
import {getFormItems} from './formItem';
import EnquiryBase from '../enquiryBase';

class Capacity extends React.Component {
    constructor(props) {
      super(props);
      this.formItemsSimple = getFormItems('simple');
      this.formItemsBasic = getFormItems('basic');
      //this.formItemsAdvance = getFormItems('advance');

    }

    render() {
      return (
        <EnquiryBase 
        searchResultComponent={CapacityGrid} 
        firebase={this.props.firebase} />
      );  
    }
  }

  export default Capacity;