import React from 'react';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Pager, Paging, Editing, Popup, Position, FilterRow, Lookup, Export, RequiredRule } from 'devextreme-react/data-grid';
import {oDataStoreDefaultSetting} from '../Enquiry/oDataStoreDefaultSetting';
import {Box} from "../Layout";
import colors from "../../utils/colors";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Button } from 'devextreme-react';
import { getFirebaseIdToken} from "../../utils/auth";
import { getUser } from "../../utils/auth"
const searchResultDataSource = new DataSource({
    store: new ODataStore({
      url: `${process.env.GATSBY_ODATA_ADMIN_SERVER_URL}/ProductSubscribers`,
      key: 'uid',
      keyType: 'String',
      ...oDataStoreDefaultSetting
    })
});

class ProductSubscriber extends React.Component {
    constructor(props) {
      super(props);

      this.handleEditorPreparing = this.handleEditorPreparing.bind(this);
      this.onExporting = this.onExporting.bind(this);
      this.setGrid = (ref) => {
        if (ref != null)
            this.resultGrid = ref.instance;
      };
    }
    componentDidMount() {
      this.resultGrid.refresh();
    }
  
    handleEditorPreparing(e) {
      e.editorOptions.readOnly = e.parentType === "dataRow" &&  
      e.dataField === "email" && !e.row.isNewRow; 
    }
    
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
  
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'OtherServices.xlsx');
        });
      });
      e.cancel = true;
    }

    render() {
      return (
        
      <Box bg={colors.primary}>
        
        <Box
            width={[1, 1, 1]}
            m={['3.5rem 0 1.5rem 0', '3.5rem 0 1.5rem 0', '3.5rem auto 1.5rem auto']}
            px={[0, 0, 0]}
            color={colors.secondary}
        >
          <h2>Other Services Subscriptions</h2>
        <DataGrid
          dataSource={searchResultDataSource}
          keyExpr="uid"
          showBorders={true}
          selection={{mode: 'single'}}
          ref={this.setGrid}
          onEditorPreparing={this.handleEditorPreparing}
          onExporting={this.onExporting}
        >
          <Paging defaultPageSize={15} />
          <FilterRow visible={true}/>
          <Pager
            showInfo={true} />        
          <Column dataField='email'
          dataType={'string'}  sortIndex={0} sortOrder={"asc"}>
            <RequiredRule/>
          </Column>
          <Column dataField='firstName'
          dataType={'string'}>
            <RequiredRule/>
          </Column>
          <Column dataField='lastName'
          dataType={'string'}>
            <RequiredRule/>
          </Column>
          <Column dataField='company'
          dataType={'string'}>
            <RequiredRule/>
          </Column>
          <Column dataField='productName'
          dataType={'string'}
          caption={'Service'}>
            <RequiredRule/>
          </Column>
          <Column dataField='subDate'
          dataType={'date'} caption={'Subcribed on'} editorOptions={{dateSerializationFormat:'yyyy-MM-dd'}}
          >
          </Column>
          <Export enabled={true} />
        </DataGrid>
</Box>

</Box>

      );
    }
  }

  export default ProductSubscriber;