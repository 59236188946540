import { dDown_ThruputPort } from '../commonDataSource';
import {processCriteriaFormItems} from '../helper';

export function getFormItems(mode) 
{
    //  dataType: for FilterBuilder only
    //  editorTemplate: for FilterBuilder only when custom editor is used
    // caption: for FilterBuilder only
    // defaultCriteria: for form criteria only if default criteria will be set
    // searchOperator: for form criteria only if custom operator is used
    return processCriteriaFormItems([
        
        {                    
            dataField: 'portGroup',
            editorType: 'dxSelectBox',
            dataType: 'string',
            validationRules:[{type:"required", message:"It is required"}],
            editorOptions: {
                dataSource: dDown_ThruputPort(),
                displayExpr: 'fullPort',
                valueExpr:'portGroup',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'fullPort',
                searchTimeout:'200',
                minSearchLength:2,
                showDataBeforeSearch:'true',
                showClearButton:'true',
                placeholder:'Type to search by port name, country, region or city...'
            },
            searchOperator: 'contains',
            label: {text: 'Port'},
            colSpan: 2,
            visible: true
        }
        ], mode);
}