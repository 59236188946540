import React from 'react';
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid';
import VectorMap, {
  Label,
  Layer,
  Font,
  Legend,
  Source,
  Tooltip,
  ControlBar
} from 'devextreme-react/vector-map';
import * as mapsData from 'devextreme/dist/js/vectormap-data/world.js';
//import {CongestionBubbleMapByPortGroupSource} from '../commonDataSource';
import { Box, Flex } from '../../../Layout';
const bounds = [-180, 77, 180, -60];
class VesselLocationMap extends React.Component {
    constructor(props) {
      super(props);
      //this.state = {lngCtr: 0, latCtr: 0, zoom: 2};
      this.mapData = this.props.mapData;
      var thisObj = this;
      this.mapData.forEach ((element,index) => {
        //if (index === 0)
          //thisObj.state = {lngCtr: element.lngCtr, latCtr: element.latCtr, zoom: element.zoom};
        element.coordinates = [element.lng, element.lat];
        element.attributes = {
          text: element.vesselSeq,
          url: `/llassets/${element.icon}`,
          tooltip: `<div style='font-size:20px; margin-bottom: 25px'><b>${element.vesselSeq}. ${element.vesselName}</b></div>\n \n<div style='font-size:15px'><b>TEU:</b> ${element.teu.toLocaleString(undefined)}</div>`
        }        ;
      });
      if (this.props.useDef)
        this.state = {lngCtr: this.props.defLngctr, latCtr: this.props.defLatctr, zoom: this.props.defZoomFactor};
      else
        this.state = {lngCtr: this.mapData[0].lngCtr, latCtr: this.mapData[0].latCtr, zoom: this.mapData[0].zoom};
    }

    customizeTooltip(arg) {
      if (arg.layer.type === 'marker') {
        return { text: arg.attribute('tooltip') };
      }
      return null;
    }
    render() {
      if (this.mapData)
      return (
      <>
        <h1>{this.props.service}</h1>
        <Flex wrap={['wrap', 'nowrap','nowrap']}>
          <Box width={[1,0.7,0.7]} height={[400,400,620]} mr={[0,0,0]} pb={[0,0,'20px']}>

            <VectorMap style={{height:"100%", paddingBottom:"50px"}} 
              id="vector-map" bounds={[-180 / this.state.zoom, 77, 180 / this.state.zoom, -60]} zoomingEnabled={true} panningEnabled={true} 
              center={[this.state.lngCtr, this.state.latCtr]} 
              //center={[57, 16]} zoomFactor={2.3} 
              >
              <ControlBar enabled={false}></ControlBar>
              <Layer
                dataSource={mapsData.world}
                hoverEnabled={false}

                >
              </Layer>
              <Layer
                dataSource={this.mapData}
                type="marker"
                elementType="image"
                dataField="url"
                size={20}>
                <Label dataField="text">
                  <Font size={14} />
                </Label>
              </Layer>
              <Tooltip enabled={true}
                customizeTooltip={this.customizeTooltip}
              ></Tooltip>
            </VectorMap>
          </Box>
          <Box width={[1,0.3,0.3]} height={[350,350,550]}>
            <DataGrid
            elementAttr={{
              class: "myClass"
            }}
              height={"100%"}
              dataSource={this.mapData}
              showBorders={true}
              allowColumnResizing={true}
              columnAutoWidth={false}
              wordWrapEnabled={false}
              rowAlternationEnabled={false}
              >
              <Scrolling mode="virtual" />
              <Column dataField='vesselSeq'
                caption="ID"
                dataType={'number'}
                width={30}
                sortIndex={0}
                sortOrder={"asc"}
                cssClass={"dx-cell-cust"}
              />
              <Column dataField='vesselName'
                caption='Vessel Name'
                dataType={'string'}
                cssClass={"dx-cell-cust"}
              />
              
              <Column dataField='teu'
                caption='TEU'
                dataType={'number'}
                format={{type:'fixedPoint',precision:0}}
                width={"50"}
                cssClass={"dx-cell-cust"}
              />
            </DataGrid>      
          </Box>
        </Flex>
      </>
      );  
    }
  }

  export default VesselLocationMap;