import React from 'react';
import {Button} from 'devextreme-react';
import DataGrid, { Column,  Grouping } from 'devextreme-react/data-grid';
import Chart, { CommonSeriesSettings, Size, Series, ValueAxis, ArgumentAxis, Legend, AxisLabel, Tooltip } from 'devextreme-react/chart';
import { Box, Flex } from '../../../Layout';
import { getFirebaseIdToken } from "../../../../utils/auth";
import {
  servicesDataSource,
  ThruputAnnualDataSource,
  ThruputAnnualDataSource1
  } from './../commonDataSource'
import ServiceDetail from '../Service/ServiceDetail';

const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits:0
}).format;

class PortDetail extends React.Component {
    constructor(props) {
      super(props); 

      this.state = { visibleSvcDetail: null, collapsed: false, portName: '', svcFilter: ['lsCode','=','X'] };

      this.setSvcGrid = (ref) => {
        if (ref != null)
            this.resultSvcGrid = ref.instance;
      };
      this.setSvcDetail = (ref) => {
        if (ref != null)
            this.svcDetail = ref;
      };
      this.setTpAnnualChart = (ref) => {
        if (ref != null)
            this.TpAnnualChart = ref.instance;
      };
      this.setTpChartGrid = (ref) => {
        if (ref != null)
            this.tpChartGrid = ref.instance;
      };
      
      servicesDataSource.filter(['portGroup','=','X']);
      ThruputAnnualDataSource.filter(['portGroup','=','X']);
      ThruputAnnualDataSource1.filter(['portGroup','=','X']);

      this.onSelectionChanged = this.onSelectionChanged.bind(this);
      this.onSelectionChangedSvcGrid = this.onSelectionChangedSvcGrid.bind(this);
      this.customizeTooltip = this.customizeTooltip.bind(this);
      this.load = this.load.bind(this);
      this.backFromSubScreen = this.backFromSubScreen.bind(this);
      this.onContentReady = this.onContentReady.bind(this);
    }

    componentDidMount(){
    }

    load(filter) {
      var thisObj = this;

      getFirebaseIdToken(this.props.firebase, () => {

        servicesDataSource.filter(filter);
        
        thisObj.setState({
              portName: (filter && filter.length > 0 && filter[0].length >=3?filter[0][2]:'')
            })

        thisObj.resultSvcGrid.refresh();


        var newFilter = filter.slice();
        if (newFilter && newFilter.length > 0 && newFilter[0].length >=3) {
          newFilter[0] = newFilter[0].slice();
          newFilter[0][1] = "=";
        }

        ThruputAnnualDataSource.filter(newFilter);
        thisObj.TpAnnualChart.refresh();

        ThruputAnnualDataSource1.filter(newFilter);
        ThruputAnnualDataSource1.load();
        thisObj.tpChartGrid.refresh();
      });
    }

    backFromSubScreen() {
      this.resultSvcGrid.clearSelection();
      this.props.enquiryBase.setCriteriaVisible(true);
      this.resultSvcGrid.collapseAll();
     this.setState({visibleSvcDetail: null})
    }

    onSelectionChanged(e) {

    }

    onSelectionChangedSvcGrid(e) {
      var data = e.selectedRowsData[0];
      
        if (data) {
          this.currLsCode = data.lsCode;
          this.setState({initialFilter: ['lsCode','=',data.lsCode],  visibleSvcDetail: true})
        }
        else {
        }
      
      this.props.enquiryBase.setCriteriaVisible(false);

      this.resultSvcGrid.collapseAll();
      
    }

    onContentReady(e) {
      if (!this.state.collapsed) {
        e.component.expandRow(['']);
        this.setState({
          collapsed: true
        });
      }
    }

    customizeTooltip(pointInfo){
    
      if(pointInfo){
          return{
              text: `${pointInfo.argumentText}: ${formatNumber(pointInfo.valueText)} TEU` 
          };
      } else {
          return{
              text: ``
          };
      }   
  }
  
    render() {
      var portFullName = '';
      portFullName = this.state.portName;

      return (
        <React.Fragment>
        <Flex wrap={['wrap', 'nowrap','nowrap']}>
        <Box>

        <div style={{display: (!this.state.visibleSvcDetail)?'inline':'none'}} >
        
          <Flex wrap={['nowrap']}>
            <Button icon="back" hint="Back" onClick={this.props.returnCallback} height='30'/>
            <h3>Port Name: {portFullName} </h3>
          </Flex>          

        <Flex wrap={['wrap', 'nowrap','nowrap']}>
        <Box width={[1,'50%','50%']}>
          <h3>Serivces Operating in {portFullName}</h3>

            <DataGrid
              elementAttr={{
                class: "myClass"
              }}
              dataSource={servicesDataSource}
              showBorders={true}
              selection={{mode: 'single'}}
              onSelectionChanged={this.onSelectionChangedSvcGrid} 
              ref={this.setSvcGrid}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={false}
              onContentReady={this.onContentReady}
              >
              <Grouping autoExpandAll={false} />

              <Column 
                dataField='trade' 
                caption='Tradelane'
                width={5}
                groupIndex={0} />

              <Column dataField='serviceName'
                width={'300'}
                caption={'Service'}
                dataType={'string'}
              />

              <Column dataField='operators'
                minWidth={'300'}
                dataType={'string'}
              />

              <Column dataField='lsCode'
                width={'120'}
                visible={false}
              />

            </DataGrid>

          </Box>
        <Box width={[1,'1%','1%']}>
        </Box>
        <Box width={[1,'14%','14%']} >
          <h3>Throughput by Year of {portFullName}</h3>
          <DataGrid
              elementAttr={{
                class: "myClass"
              }}
              dataSource={ThruputAnnualDataSource}
              showBorders={true}
              selection={{mode: 'single'}}
              ref={this.setTpChartGrid}
              allowColumnReordering={false}
              allowColumnResizing={false}
              columnAutoWidth={true}
              >
              <Column 
                dataField='thruputYear' 
                caption='Year'
                width={50}
                sortOrder='desc'
                />

              <Column dataField='teuAnnual'
                width={'75'}
                caption={'TEU'}
                format={{type:'fixedPoint',precision:0}}
                />

              <Column dataField='yoyChange'
                width={'60'}
                caption={'% Chg'}
                format={'#.##%'}
                />
            </DataGrid>
        </Box>
        <Box width={[1,'35%','35%']}>
          <h3>Throughput Chart by Year of {portFullName}</h3>
          <Chart 
            ref={this.setTpAnnualChart}
            dataSource={ThruputAnnualDataSource1}            
            >
            <Size height={350} />
            <CommonSeriesSettings type ="bar" argumentField='thruputYear' />
            <Series
              name='Annual TEU Thruput'
              valueField='teuAnnual'
              color="#BE1E2D"
            />
            <ValueAxis autoBreaksEnabled={true} maxAutoBreakCount={1}>
              
            </ValueAxis>
            <ArgumentAxis>
              <AxisLabel format="0000"/>
            </ArgumentAxis>
            <Tooltip enabled={true} location="edge" customizeTooltip={this.customizeTooltip} />
            <Legend horizontalAlignment='center' verticalAlignment='bottom' itemTextPosition='left' />
          </Chart>
        </Box>
        </Flex>
        </div>
        </Box>
        </Flex>


        {this.state.visibleSvcDetail &&
        <Flex>
          <Box>
        <ServiceDetail 
            firebase={this.props.firebase}
            ref={this.setSvcDetail}
            showFleet={true}
            enquiryBase={this.props.enquiryBase}
            initialFilter={this.state.initialFilter}
            returnCallback={this.backFromSubScreen}
          />
          </Box>
        </Flex>
        }

      </React.Fragment>

      );  
    }
  }

  export default PortDetail;