import React from 'react'
import { Link } from 'gatsby';
import { Box } from '../../Layout';
import { isBrowser, checkCanAccessByTag } from '../../../utils/auth';
import Tabs from 'devextreme-react/tabs';

class TabNav extends React.Component {
  constructor(props) {
    super(props);
    this.tabRender = this.tabRender.bind(this);

    this.state = {navigation: [],};
    if (window.sessionStorage.getItem(`page-${props.parentMenu}`))  {
      let pages = JSON.parse(window.sessionStorage.getItem(`page-${props.parentMenu}`)).pages;
      let selectedIndex = pages.findIndex((page) => {
        return (isBrowser()?window.location.pathname:'').endsWith('/'+page.slug);
      });
      this.state = {navigation: pages, selectedIndex: selectedIndex};
    } else {
      let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/browsePageByTag?tag=page-${this.props.parentMenu}`;    
      var request = new XMLHttpRequest();
      request.callObj = this;
      request.open('GET', postApiUrl,true);
      request.setRequestHeader('Content-Type','application/json; charset=utf-8');
      request.onreadystatechange = function (reposta) {
      if (this.readyState === 4) {
          //console.log(this.responseText);
          let pages = JSON.parse(this.responseText).pages;
          pages = pages.filter(
            (page) => 
            {
                return checkCanAccessByTag(page.tags) ;
            }
          );
          let selectedIndex = pages.findIndex((page) => {
            return (isBrowser()?window.location.pathname:'').endsWith('/'+page.slug);
          });
          window.sessionStorage.setItem(`page-${props.parentMenu}`, this.responseText);
          this.callObj.setState({navigation: pages, selectedIndex: selectedIndex});
      }
      };
      request.send();
    }
  }

  tabRender(item) {
    return <div>{item.title}<Link to={`/app/page/${this.props.parentMenu}/${item.slug}?showMenu=true`}></Link></div>;
  }
  render() {
    return (
      <Box width="100%" >
        <Tabs 
        dataSource={this.state.navigation} 
        itemRender={this.tabRender}
        selectedIndex={this.state.selectedIndex}
        scrollByContent={true}
        visible={this.state.navigation && this.state.navigation.length > 0}/>

      </Box>
    );
  }
}

export default TabNav;